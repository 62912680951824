import React, { Component } from 'react';
import '../css/newsEdit.css';

class NewsEdit extends Component {
  state = { sticky: 0 };

  componentDidMount() {
    window.addEventListener('scroll', this.throttle);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttle);
  }

  time = Date.now();

  throttle = () => {
    if (this.time + 250 - Date.now() < 0) {
      const height = this.middleContainer.clientHeight;
      const windowHeight = window.innerHeight;
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, 'middleHeight');
      this.time = Date.now();
    }
  };

  render() {
    const {
      newsInput,
      infoList,
      noticeList,
      meetupList,
      onNewsSubmit
    } = this.props;

    return (
      <div
        className='newsEdit-container'
        ref={middleContainer => {
          this.middleContainer = middleContainer;
        }}
        style={{ position: 'sticky', top: this.state.sticky }}
      >
        <div className='accountSnippetTag'>管理消息</div>
        <div className='newsEdit-news'>
          <div>
            <div className='newsEdit-news-bar-container'>
              <div className='newsEdit-news-bar-left'>
                消息 &nbsp;
                <input
                  name='info'
                  type='text'
                  className='newsEdit-news-bar-left-input'
                  value={newsInput.info}
                  onChange={e => {
                    this.props.onNewsChange(e, 'new');
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                      e.preventDefault();
                      onNewsSubmit('info', 'infoLink');
                    }
                  }}
                />
              </div>
              <div
                className='newsEdit-news-bar-right'
                onClick={() => onNewsSubmit('info', 'infoLink')}
              >
                新增新聞
              </div>
            </div>
            <div className='newsEdit-news-bar-container link'>
              <div className='newsEdit-news-bar-left'>
                連結 &nbsp;
                <input
                  name='infoLink'
                  type='text'
                  className='newsEdit-news-bar-left-input'
                  value={newsInput.infoLink}
                  onChange={e => this.props.onNewsChange(e, 'new')}
                  onKeyDown={e => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                      e.preventDefault();
                      onNewsSubmit('info', 'infoLink');
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <br />
          <div className='newsEdit-news-list-container'>
            {infoList.length === 0
              ? null
              : infoList.map((info, index) => (
                  <div key={index} className='newsEdit-news-row'>
                    <div className='newsEdit-news-row-first'>
                      <div className='date'>{info.date}</div>
                      <div className='messageLabel'>
                        消息 &nbsp;
                        <input
                          className='message'
                          name='message'
                          onChange={e =>
                            this.props.onNewsUpdateChange(e, index, 'info')
                          }
                          value={info.message}
                        />
                      </div>
                      <div>
                        <label className='newsEdit-radio'>
                          <input
                            className='newsEdit-radio'
                            type='radio'
                            checked={!info.publish ? 'checked' : ''}
                            onChange={() =>
                              this.props.onNewsUpdate(
                                'publish',
                                info._id,
                                'info'
                              )
                            }
                          />
                          收起
                        </label>
                      </div>
                      <div>
                        <label className='newsEdit-radio'>
                          <input
                            className='newsEdit-radio'
                            type='radio'
                            checked={info.publish ? 'checked' : ''}
                            onChange={() =>
                              this.props.onNewsUpdate(
                                'publish',
                                info._id,
                                'info'
                              )
                            }
                          />
                          發佈
                        </label>
                      </div>
                    </div>
                    <div className='newsEdit-news-row-second'>
                      <div>
                        *連結 &nbsp;
                        <input
                          className='link'
                          name='link'
                          onChange={e =>
                            this.props.onNewsUpdateChange(e, index, 'info')
                          }
                          value={info.link}
                        />
                      </div>
                      <div
                        className='saveBtn'
                        onClick={() =>
                          this.props.onNewsUpdate('save', info._id, 'info')
                        }
                      >
                        {' '}
                        <i className='fa fa-save'></i> 儲存
                      </div>
                      <div
                        className='deleteBtn'
                        onClick={() =>
                          this.props.onNewsUpdate('remove', info._id)
                        }
                      >
                        <i className='fa fa-times'></i>
                        删除
                      </div>
                    </div>
                  </div>
                ))}
            <div className='newsEdit-ps'>*連結可不填</div>
          </div>
        </div>

        <br />

        <div className='accountSnippetTag'>管理公告</div>
        <div className='newsEdit-news'>
          <div>
            <div className='newsEdit-news-bar-container'>
              <div className='newsEdit-news-bar-left'>
                公告 &nbsp;
                <input
                  name='notice'
                  type='text'
                  className='newsEdit-news-bar-left-input'
                  value={newsInput.notice}
                  onChange={e => {
                    this.props.onNewsChange(e, 'new');
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                      e.preventDefault();
                      onNewsSubmit('notice', 'noticeLink');
                    }
                  }}
                />
              </div>
              <div
                className='newsEdit-news-bar-right'
                onClick={() => onNewsSubmit('notice', 'noticeLink')}
              >
                新增公告
              </div>
            </div>
            <div className='newsEdit-news-bar-container link'>
              <div className='newsEdit-news-bar-left'>
                連結 &nbsp;
                <input
                  name='noticeLink'
                  type='text'
                  className='newsEdit-news-bar-left-input'
                  value={newsInput.noticeLink}
                  onChange={e => this.props.onNewsChange(e, 'new')}
                  onKeyDown={e => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                      e.preventDefault();
                      onNewsSubmit('notice', 'noticeLink');
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <br />
          <div className='newsEdit-news-list-container'>
            {noticeList.length === 0
              ? null
              : noticeList.map((notice, index) => (
                  <div key={index} className='newsEdit-news-row'>
                    <div className='newsEdit-news-row-first'>
                      <div className='date'>{notice.date}</div>
                      <div className='messageLabel'>
                        消息 &nbsp;
                        <input
                          className='message'
                          name='message'
                          onChange={e =>
                            this.props.onNewsUpdateChange(e, index, 'notice')
                          }
                          value={notice.message}
                        />
                      </div>
                      <div>
                        <label className='newsEdit-radio'>
                          <input
                            className='newsEdit-radio'
                            type='radio'
                            checked={!notice.publish ? 'checked' : ''}
                            onChange={() =>
                              this.props.onNewsUpdate(
                                'publish',
                                notice._id,
                                'notice'
                              )
                            }
                          />
                          收起
                        </label>
                      </div>
                      <div>
                        <label className='newsEdit-radio'>
                          <input
                            className='newsEdit-radio'
                            type='radio'
                            checked={notice.publish ? 'checked' : ''}
                            onChange={() =>
                              this.props.onNewsUpdate(
                                'publish',
                                notice._id,
                                'notice'
                              )
                            }
                          />
                          發佈
                        </label>
                      </div>
                    </div>
                    <div className='newsEdit-news-row-second'>
                      <div>
                        *連結 &nbsp;
                        <input
                          className='link'
                          name='link'
                          onChange={e =>
                            this.props.onNewsUpdateChange(e, index, 'notice')
                          }
                          value={notice.link}
                        />
                      </div>
                      <div
                        className='saveBtn'
                        onClick={() =>
                          this.props.onNewsUpdate('save', notice._id, 'notice')
                        }
                      >
                        {' '}
                        <i className='fa fa-save'></i> 儲存
                      </div>
                      <div
                        className='deleteBtn'
                        onClick={() =>
                          this.props.onNewsUpdate('remove', notice._id)
                        }
                      >
                        <i className='fa fa-times'></i>
                        删除
                      </div>
                    </div>
                  </div>
                ))}
            <div className='newsEdit-ps'>*連結可不填</div>
          </div>
        </div>

        <br />

        <div className='accountSnippetTag'>管理 Meet Up</div>

        <div className='newsEdit-news'>
          <div>
            <div className='newsEdit-news-bar-container'>
              <div className='newsEdit-news-bar-left'>
                公告 &nbsp;
                <input
                  name='meetup'
                  type='text'
                  className='newsEdit-news-bar-left-input'
                  value={newsInput.meetup}
                  onChange={e => {
                    this.props.onNewsChange(e, 'new');
                  }}
                  onKeyDown={e => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                      e.preventDefault();
                      onNewsSubmit('meetup', 'meetupLink');
                    }
                  }}
                />
              </div>
              <div
                className='newsEdit-news-bar-right'
                onClick={() => onNewsSubmit('meetup', 'meetupLink')}
              >
                新增公告
              </div>
            </div>
            <div className='newsEdit-news-bar-container link'>
              <div className='newsEdit-news-bar-left'>
                連結 &nbsp;
                <input
                  name='meetupLink'
                  type='text'
                  className='newsEdit-news-bar-left-input'
                  value={newsInput.meetupLink}
                  onChange={e => this.props.onNewsChange(e, 'new')}
                  onKeyDown={e => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                      e.preventDefault();
                      onNewsSubmit('meetup', 'meetupLink');
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <br />
          <div className='newsEdit-news-list-container'>
            {meetupList.length === 0
              ? null
              : meetupList.map((meetup, index) => (
                  <div key={index} className='newsEdit-news-row'>
                    <div className='newsEdit-news-row-first'>
                      <div className='date'>{meetup.date}</div>
                      <div className='messageLabel'>
                        消息 &nbsp;
                        <input
                          className='message'
                          name='message'
                          onChange={e =>
                            this.props.onNewsUpdateChange(e, index, 'meetup')
                          }
                          value={meetup.message}
                        />
                      </div>
                      <div>
                        <label className='newsEdit-radio'>
                          <input
                            className='newsEdit-radio'
                            type='radio'
                            checked={!meetup.publish ? 'checked' : ''}
                            onChange={() =>
                              this.props.onNewsUpdate(
                                'publish',
                                meetup._id,
                                'meetup'
                              )
                            }
                          />
                          收起
                        </label>
                      </div>
                      <div>
                        <label className='newsEdit-radio'>
                          <input
                            className='newsEdit-radio'
                            type='radio'
                            checked={meetup.publish ? 'checked' : ''}
                            onChange={() =>
                              this.props.onNewsUpdate(
                                'publish',
                                meetup._id,
                                'meetup'
                              )
                            }
                          />
                          發佈
                        </label>
                      </div>
                    </div>
                    <div className='newsEdit-news-row-second'>
                      <div>
                        *連結 &nbsp;
                        <input
                          className='link'
                          name='link'
                          onChange={e =>
                            this.props.onNewsUpdateChange(e, index, 'meetup')
                          }
                          value={meetup.link}
                        />
                      </div>
                      <div
                        className='saveBtn'
                        onClick={() =>
                          this.props.onNewsUpdate('save', meetup._id, 'meetup')
                        }
                      >
                        {' '}
                        <i className='fa fa-save'></i> 儲存
                      </div>
                      <div
                        className='deleteBtn'
                        onClick={() =>
                          this.props.onNewsUpdate('remove', meetup._id)
                        }
                      >
                        <i className='fa fa-times'></i>
                        删除
                      </div>
                    </div>
                  </div>
                ))}
            <div className='newsEdit-ps'>*連結可不填</div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsEdit;
