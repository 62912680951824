import React, { Component } from 'react';
import '../../css/emailConfirmation.css';

class EmailConfirmation extends Component {
  componentDidMount() {
    const accountToken = localStorage.getItem('account');
    const account = JSON.parse(accountToken);
    if (account && account.validated) {
      this.props.history.push('/');
    } else if (!this.props.account.userId) {
      this.props.history.push('/');
    }
  }

  render() {
    const { name, userId, email } = this.props.account;

    return (
      <div className='emailConfirmation-container'>
        <div className='emailConfirmation-header'>登記成為柚子市會員</div>
        <br />
        <br />
        <div className='emailConfirmation-title-container'>
          <div className='emailConfirmation-avatar-container'>
            <img
              className='emailConfirmation-avatar'
              src={`/img/accounts/avatars/custom/${userId}.png`}
              alt={name}
            />
          </div>
          <div className='emailConfirmation-p-container title'>
            您好! <span style={{ color: '#cc0066' }}>{name}</span> ,
            您已成功登記成為柚子市會員，但您還未可以使用柚子市的服務，您須要確認您登記的電郵{' '}
            <span style={{ color: '#cc0066' }}> {email} </span>。
          </div>
        </div>

        <br />
        <br />

        <div className='emailConfirmation-p-container'>
          首先，系統會在 15
          分鐘內寄出確認電郵通知，您須要開啟這封電郵通知，然後點擊提供給您的連結，即可完成整個確認電郵的程序。
          <br />
          <br />
          如果 15
          分鐘後收不到確認電郵通知，您須要查看在垃圾郵件中有沒有該確認通知，如果沒有，請登入到{' '}
          <span style={{ color: '#0f3676' }}>https://eoscity.io</span>&nbsp;
          登入您自己的柚子市帳號，在控制面版您會看到一個叫{' '}
          <span style={{ color: '#0f3676' }}>"發出確認電郵"</span>
          的按鈕，請點擊此按鈕讓系統再發確認電郵通知給您。
          <br />
          <br />
          請馬上前往 <span style={{ color: '#cc0066' }}> {email} </span>{' '}
          進行確認
        </div>
        <br />
        <br />
        <div style={{ textAlign: 'center' }}>
          <span
            className='emailConfirmation-btn'
            onClick={() => {
              this.props.history.push('/');
            }}
          >
            確定
          </span>
        </div>
      </div>
    );
  }
}

export default EmailConfirmation;
