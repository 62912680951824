import React, { Component } from 'react';

class Subtitle extends Component {
  render() {
    const { content } = this.props.block;
    return <div className='middle-navigator-subtitle-container'>{content}</div>;
  }
}

export default Subtitle;
