import React, { Component } from "react";
import "../css/market.css";

class PriceList extends Component {
  state = {
    type: "最大成交",
    search: "",
    searchList: [],
    prevSearchList: []
  };

  handleChange = type => {
    this.setState({ type });
  };

  handleSearch = e => {
    const search = e.target.value;

    let searchList = this.props.market;
    searchList = searchList.filter(coin =>
      coin.currency.toLowerCase().includes(search.toLowerCase())
    );
    let prevSearchList = this.props.prevMarket;
    prevSearchList = prevSearchList.filter(coin =>
      coin.currency.toLowerCase().includes(search.toLowerCase())
    );

    this.setState({ type: "搜尋", search, searchList, prevSearchList });

    if (e.target.value === "") {
      this.setState({ type: "最大成交" });
    }
  };

  render() {
    let {
      rightPanelActive,
      market,
      prevMarket,
      eosMarket,
      prevEosMarket,
      forkMarket,
      prevForkMarket
    } = this.props;

    const { type, search, searchList, prevSearchList } = this.state;

    return (
      <div
        className={
          rightPanelActive === "行情"
            ? "rightContainer content active"
            : "rightContainer content"
        }
      >
        <div className="priceTableContainer">
          <div className="coinConfigContainer">
            <div className="coinTypeContainer">
              <div
                className={
                  type === "最大成交"
                    ? "coinTypeBtnContainer active"
                    : "coinTypeBtnContainer"
                }
                onClick={() => {
                  this.handleChange("最大成交");
                }}
              >
                <img src="/img/navButtons/market/volume.svg" alt="最大成交" />
                最大成交
              </div>
              <div
                className={
                  type === "分叉幣"
                    ? "coinTypeBtnContainer active"
                    : "coinTypeBtnContainer"
                }
                onClick={() => {
                  this.handleChange("分叉幣");
                }}
              >
                <img src="/img/navButtons/market/forkCoin.svg" alt="分叉幣" />
                分叉幣
              </div>
              <div
                className={
                  type === "合約幣"
                    ? "coinTypeBtnContainer active"
                    : "coinTypeBtnContainer"
                }
                onClick={() => {
                  this.handleChange("合約幣");
                }}
              >
                <img
                  src="/img/navButtons/market/contractCoin.svg"
                  alt="合約幣"
                />
                合約幣
              </div>
              <div className="coinQuickSearch">
                <div>快速搜尋</div>
                <div>
                  <input
                    type="text"
                    onChange={e => {
                      this.handleSearch(e);
                    }}
                    value={search}
                  />
                </div>
              </div>
            </div>
          </div>
          {type === "最大成交"
            ? market.map((coin, index) => (
                <div key={index} className="coinQuotationContainer">
                  <div className="firstRow">
                    <div className="title">
                      <span>
                        {coin.contract === "eosio.token"
                          ? "EOS"
                          : coin.currency}
                      </span>{" "}
                      兌 {coin.symbol.split("-")[2].toUpperCase()}
                    </div>
                    <div
                      className={
                        coin.last > prevMarket[index].last
                          ? "price up"
                          : coin.last < prevMarket[index].last
                          ? "price down"
                          : "price"
                      }
                    >
                      <span>
                        {coin.last > prevMarket[index].last ? (
                          <span>&#9650;</span>
                        ) : coin.last < prevMarket[index].last ? (
                          <span>&#9660;</span>
                        ) : (
                          ""
                        )}
                        {coin.last}
                      </span>{" "}
                      {coin.symbol.split("-")[2].toUpperCase()}
                    </div>
                  </div>
                  <div className="secondRow">
                    <div className="highlow">最高 {coin.high}</div>
                    <div className="highlow">最低 {coin.low}</div>
                    <div
                      className={
                        coin.change > 0
                          ? "price up"
                          : coin.change < 0
                          ? "price down"
                          : ""
                      }
                    >
                      {coin.change > 0 ? (
                        <span>&#9650;</span>
                      ) : coin.change < 0 ? (
                        <span>&#9660;</span>
                      ) : (
                        ""
                      )}
                      {coin.change}%
                    </div>
                  </div>
                  <div className="thirdRow">
                    <div className="vol">成交 {coin.volume.toFixed(1)} EOS</div>
                    <div className="vol">
                      {coin.amount.toFixed(1)}{" "}
                      {coin.contract === "eosio.token"
                        ? coin.currency
                        : coin.symbol.split("-")[1].toUpperCase()}
                    </div>
                    <a className="trade" href={coin.url}>
                      去交易
                    </a>
                  </div>
                </div>
              ))
            : null}

          {type === "分叉幣"
            ? forkMarket.map((coin, index) => (
                <div key={index} className="coinQuotationContainer">
                  <div className="firstRow">
                    <div className="title">
                      <span>{coin.currency}</span> 兌{" "}
                      {coin.symbol.split("-")[2].toUpperCase()}
                    </div>
                    <div
                      className={
                        coin.last > prevForkMarket[index].last
                          ? "price up"
                          : coin.last < prevForkMarket[index].last
                          ? "price down"
                          : "price"
                      }
                    >
                      <span>
                        {coin.last > prevForkMarket[index].last ? (
                          <span>&#9650;</span>
                        ) : coin.last < prevForkMarket[index].last ? (
                          <span>&#9660;</span>
                        ) : (
                          ""
                        )}
                        {coin.last}
                      </span>{" "}
                      {coin.symbol.split("-")[2].toUpperCase()}
                    </div>
                  </div>
                  <div className="secondRow">
                    <div className="highlow">最高 {coin.high}</div>
                    <div className="highlow">最低 {coin.low}</div>
                    <div
                      className={
                        coin.change > 0
                          ? "price up"
                          : coin.change < 0
                          ? "price down"
                          : ""
                      }
                    >
                      {coin.change > 0 ? (
                        <span>&#9650;</span>
                      ) : coin.change < 0 ? (
                        <span>&#9660;</span>
                      ) : (
                        ""
                      )}
                      {coin.change}%
                    </div>
                  </div>
                  <div className="thirdRow">
                    <div className="vol">成交 {coin.volume.toFixed(1)} EOS</div>
                    <div className="vol">
                      {coin.amount.toFixed(1)}{" "}
                      {coin.symbol.split("-")[1].toUpperCase()}
                    </div>
                    <a className="trade" href={coin.url}>
                      去交易
                    </a>
                  </div>
                </div>
              ))
            : null}

          {type === "合約幣"
            ? eosMarket.map((coin, index) => (
                <div key={index} className="coinQuotationContainer">
                  <div className="firstRow">
                    <div className="title">
                      <span>{coin.currency}</span> 兌{" "}
                      {coin.symbol.split("-")[2].toUpperCase()}
                    </div>
                    <div
                      className={
                        coin.last > prevEosMarket[index].last
                          ? "price up"
                          : coin.last < prevEosMarket[index].last
                          ? "price down"
                          : "price"
                      }
                    >
                      <span>
                        {coin.last > prevEosMarket[index].last ? (
                          <span>&#9650;</span>
                        ) : coin.last < prevEosMarket[index].last ? (
                          <span>&#9660;</span>
                        ) : (
                          ""
                        )}
                        {coin.last}
                      </span>{" "}
                      {coin.symbol.split("-")[2].toUpperCase()}
                    </div>
                  </div>
                  <div className="secondRow">
                    <div className="highlow">最高 {coin.high}</div>
                    <div className="highlow">最低 {coin.low}</div>
                    <div
                      className={
                        coin.change > 0
                          ? "price up"
                          : coin.change < 0
                          ? "price down"
                          : ""
                      }
                    >
                      {coin.change > 0 ? (
                        <span>&#9650;</span>
                      ) : coin.change < 0 ? (
                        <span>&#9660;</span>
                      ) : (
                        ""
                      )}
                      {coin.change}%
                    </div>
                  </div>
                  <div className="thirdRow">
                    <div className="vol">成交 {coin.volume.toFixed(1)} EOS</div>
                    <div className="vol">
                      {coin.amount.toFixed(1)}
                      {coin.symbol.split("-")[1].toUpperCase()}
                    </div>
                    <a className="trade" href={coin.url}>
                      去交易
                    </a>
                  </div>
                </div>
              ))
            : null}

          {type === "搜尋"
            ? searchList.map((coin, index) => (
                <div key={index} className="coinQuotationContainer">
                  <div className="firstRow">
                    <div className="title">
                      <span>{coin.currency}</span> 兌{" "}
                      {coin.symbol.split("-")[2].toUpperCase()}
                    </div>
                    <div
                      className={
                        coin.last > prevSearchList[index].last
                          ? "price up"
                          : coin.last < prevSearchList[index].last
                          ? "price down"
                          : "price"
                      }
                    >
                      <span>
                        {coin.last > prevSearchList[index].last ? (
                          <span>&#9650;</span>
                        ) : coin.last &&
                          coin.last < prevSearchList[index].last ? (
                          <span>&#9660;</span>
                        ) : (
                          ""
                        )}
                        {coin.last}
                      </span>{" "}
                      {coin.symbol.split("-")[1].toUpperCase()}
                    </div>
                  </div>
                  <div className="secondRow">
                    <div className="highlow">最高 {coin.high}</div>
                    <div className="highlow">最低 {coin.low}</div>
                    <div
                      className={
                        coin.change > 0
                          ? "price up"
                          : coin.change < 0
                          ? "price down"
                          : ""
                      }
                    >
                      {coin.change > 0 ? (
                        <span>&#9650;</span>
                      ) : coin.change < 0 ? (
                        <span>&#9660;</span>
                      ) : (
                        ""
                      )}
                      {coin.change}%
                    </div>
                  </div>
                  <div className="thirdRow">
                    <div className="vol">成交 {coin.volume.toFixed(1)} EOS</div>
                    <div className="vol">
                      {coin.amount.toFixed(1)}{" "}
                      {coin.symbol.split("-")[1].toUpperCase()}
                    </div>
                    <a className="trade" href={coin.url}>
                      去交易
                    </a>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}

export default PriceList;
