import React, { Component } from 'react';

class AccountLogin extends Component {
  render() {
    const {
      currentLogMode,
      login,
      onLogin,
      onInputChange,
      keepMeLoggedIn,
      onKeepMeLoggedIn,
      errors
    } = this.props;

    return (
      <div>
        <div className='left-title'>登入</div>
        <div className='left-community-login-container'>
          <div className='left-community-login-tag'>電郵地址</div>
          <div>
            <input
              type='text'
              name='email'
              className='left-community-login-input'
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  onLogin();
                }
              }}
              onChange={e => onInputChange(e, currentLogMode)}
              value={login.email}
            />
            <div className='error'>{errors.email ? errors.email : null}</div>
          </div>

          <div className='left-community-login-tag'>登入密碼</div>
          <div>
            <input
              type='password'
              name='password'
              className='left-community-login-input'
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  onLogin();
                }
              }}
              onChange={e => onInputChange(e, currentLogMode)}
              value={login.password}
            />
            <div className='error'>
              {' '}
              {errors.password ? errors.password : null}{' '}
            </div>
          </div>

          <div className='left-community-login-last-row login'>
            <label className='left-community-login-btn rememberMe'>
              保持登入
              <input
                type='checkbox'
                checked={keepMeLoggedIn ? 'checked' : ''}
                value={keepMeLoggedIn}
                onChange={onKeepMeLoggedIn}
              />
              <span className='checkmark'></span>
            </label>
            <div
              className='left-community-login-submit-btn'
              onClick={() => {
                onLogin();
              }}
            >
              登入
            </div>
          </div>
          <div className='left-community-login-last-row'>
            <div className='left-community-login-btn'>忘記密碼?</div>
            <div className='left-community-login-btn'>立即註册</div>
          </div>
          <br />
          <div className='left-control-panel-slogan'>
            沒有帳號? 立即註冊登記成為柚子市一份子!
          </div>
        </div>
      </div>
    );
  }
}

export default AccountLogin;
