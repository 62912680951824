import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import LeftZone from './leftZone/leftZone';
import MiddleZone from './middleZone/middleZone';
import RightZone from './rightZone/rightZone';
import Community from './middleZone/mode/homeMode/community';
import Article from './middleZone/mode/articleMode/article';
import Navigator from './middleZone/mode/navigatorMode/navigator';
import AccountEdit from './middleZone/mode/accountMode/accountEdit';
import AccountUpdated from './middleZone/mode/accountMode/accountUpdated';
import ArticleManager from './middleZone/mode/articleMode/articleManager';
import AccountManager from './middleZone/mode/accountMode/accountManager';
import EmailConfirmation from './middleZone/mode/accountMode/emailConfirmation';
import EmailConfirmed from './middleZone/mode/accountMode/emailConfirmed';
import ConfirmationResend from './middleZone/mode/accountMode/confirmationResend';
import NewsEdit from './middleZone/mode/newsEdit';

class Home extends Component {
  render() {
    const {
      panel,
      account,
      officialSnippetList,
      communitySnippetList,
      market,
      prevMarket,
      eosMarket,
      prevEosMarket,
      forkMarket,
      prevForkMarket,
      fiatMarket,
      prevFiatMarket,
      navIcons,
      officialCoveredBanners,
      communityCoveredBanners,
      currentBanner,
      currentLogMode,
      login,
      register,
      message,
      chatCollection,
      keepMeLoggedIn,
      myArticles,
      articleBlock,
      errors,
      articleTypeLabel,
      navPanel,
      navContent,
      endpoint,
      apiEndpoint,
      eoscityStatistic,
      newsInput,
      infoList,
      noticeList,
      meetupList,
      openEmbeddedReplyInput,
      onUpdateSnippetList,
      onArticleSelected,
      onUpdateSuccess,
      onLove,
      onReply,
      onReplySubmit,
      onEmbeddedReply,
      onEmbeddedReplySubmit,
      openEmbeddedReplyMessages,
      onArticleDelete,
      onThumbHover,
      onLoggedOut,
      onChangeLogMode,
      onLogin,
      onInputChange,
      onLoginPanelChange,
      onRegistrationSubmit,
      onMessageChange,
      onSendMessage,
      onKeepMeLoggedIn,
      onCalcComponentHeight,
      onNavButtonSubclassChange,
      onNavIconChange,
      onNavPanelInputChange,
      onButtonListSubmit,
      onBlockInputChange,
      onBlockTypeChange,
      onSubBlockTypeChange,
      onContactUrlChange,
      onNavIconSelected,
      onNavContentSubmit,
      onFetchNavContent,
      onCoveredBannerInputChange,
      onCoveredBannerSubmit,
      wrapperHeight
    } = this.props;

    return (
      <div id='wrapper' className='wrapper' style={{ height: wrapperHeight }}>
        <LeftZone
          panel={panel.left}
          account={account}
          register={register}
          login={login}
          message={message}
          myArticles={myArticles}
          chatCollection={chatCollection}
          currentLogMode={currentLogMode}
          market={market}
          prevMarket={prevMarket}
          eosMarket={eosMarket}
          prevEosMarket={prevEosMarket}
          fiatMarket={fiatMarket}
          prevFiatMarket={prevFiatMarket}
          forkMarket={forkMarket}
          prevForkMarket={prevForkMarket}
          navIcons={navIcons}
          navPanel={navPanel}
          infoList={infoList}
          noticeList={noticeList}
          meetupList={meetupList}
          eoscityStatistic={eoscityStatistic}
          keepMeLoggedIn={keepMeLoggedIn}
          onKeepMeLoggedIn={onKeepMeLoggedIn}
          onCalcComponentHeight={onCalcComponentHeight}
          onNavIconSelected={onNavIconSelected}
          onArticleSelected={onArticleSelected}
          onArticleDelete={onArticleDelete}
          onSendMessage={onSendMessage}
          onMessageChange={onMessageChange}
          onLogin={onLogin}
          onInputChange={onInputChange}
          onLoggedOut={onLoggedOut}
          onResetArticleEditorBlock={this.props.onResetArticleEditorBlock}
          onLoginPanelChange={onLoginPanelChange}
          onRegistrationSubmit={onRegistrationSubmit}
          onPanelSelect={this.props.onPanelSelect}
          onPanelExpand={this.props.onPanelExpand}
          onConfirmationResend={this.props.onConfirmationResend}
          errors={errors}
        />

        <div>
          <Switch>
            <Route
              path='/news/edit'
              render={props => (
                <NewsEdit
                  account={account}
                  newsInput={newsInput}
                  infoList={infoList}
                  noticeList={noticeList}
                  meetupList={meetupList}
                  onNewsChange={this.props.onNewsChange}
                  onNewsSubmit={this.props.onNewsSubmit}
                  onNewsUpdate={this.props.onNewsUpdate}
                  onNewsUpdateChange={this.props.onNewsUpdateChange}
                  onCalcComponentHeight={onCalcComponentHeight}
                  {...props}
                />
              )}
            />
            <Route
              path='/community'
              render={props => (
                <Community
                  account={account}
                  coveredBanners={communityCoveredBanners}
                  currentBanner={currentBanner}
                  communitySnippetList={communitySnippetList}
                  onUpdateSnippetList={onUpdateSnippetList}
                  articleTypeLabel={articleTypeLabel}
                  onThumbHover={onThumbHover}
                  onCoveredBannerInputChange={onCoveredBannerInputChange}
                  onCoveredBannerSubmit={onCoveredBannerSubmit}
                  onCalcComponentHeight={onCalcComponentHeight}
                  onFetchMoreSnippets={this.props.onFetchMoreSnippets}
                  {...props}
                />
              )}
            />
            <Route
              path='/account/email-confirmation-request'
              render={props => (
                <EmailConfirmation account={account} {...props} />
              )}
            />
            <Route
              path='/account/email-confirmed'
              render={props => <EmailConfirmed {...props} />}
            />
            <Route
              path='/account/email-confirmation-resend'
              render={props => (
                <ConfirmationResend account={account} {...props} />
              )}
            />
            <Route
              path='/account/updated'
              render={props => (
                <AccountUpdated
                  account={account}
                  onCalcComponentHeight={onCalcComponentHeight}
                  {...props}
                />
              )}
            />
            <Route
              path='/account/edit'
              render={props => (
                <AccountEdit
                  apiEndpoint={apiEndpoint}
                  currentLogMode={currentLogMode}
                  onUpdateSuccess={onUpdateSuccess}
                  onCalcComponentHeight={onCalcComponentHeight}
                  {...props}
                />
              )}
            />
            <Route
              path='/accounts/manager'
              render={props => (
                <AccountManager
                  apiEndpoint={apiEndpoint}
                  onCalcComponentHeight={onCalcComponentHeight}
                  {...props}
                />
              )}
            />
            <Route
              path='/article/:id'
              render={props => (
                <Article
                  exact
                  endpoint={endpoint}
                  account={account}
                  articleBlock={articleBlock}
                  onArticleSelected={onArticleSelected}
                  onLove={onLove}
                  onReply={onReply}
                  onReplySubmit={onReplySubmit}
                  onEmbeddedReply={onEmbeddedReply}
                  openEmbeddedReplyInput={openEmbeddedReplyInput}
                  onEmbeddedReplySubmit={onEmbeddedReplySubmit}
                  openEmbeddedReplyMessages={openEmbeddedReplyMessages}
                  onArticleDelete={onArticleDelete}
                  onCalcComponentHeight={onCalcComponentHeight}
                  onResetArticleBlock={this.props.onResetArticleBlock}
                  {...props}
                />
              )}
            />
            <Route
              path='/articles/edit'
              render={props => (
                <ArticleManager
                  account={account}
                  endpoint={endpoint}
                  apiEndpoint={apiEndpoint}
                  onCalcComponentHeight={onCalcComponentHeight}
                  {...props}
                />
              )}
            />
            <Route
              path='/nav/create'
              exact
              render={props => (
                <Navigator
                  account={account}
                  navContent={navContent}
                  onBlockInputChange={onBlockInputChange}
                  onBlockTypeChange={onBlockTypeChange}
                  onSubBlockTypeChange={onSubBlockTypeChange}
                  onContactUrlChange={onContactUrlChange}
                  onNavContentSubmit={onNavContentSubmit}
                  onCalcComponentHeight={onCalcComponentHeight}
                  {...props}
                />
              )}
            />
            <Route
              path='/nav/:id'
              render={props => (
                <Navigator
                  account={account}
                  navContent={navContent}
                  onBlockInputChange={onBlockInputChange}
                  onBlockTypeChange={onBlockTypeChange}
                  onSubBlockTypeChange={onSubBlockTypeChange}
                  onContactUrlChange={onContactUrlChange}
                  onNavContentSubmit={onNavContentSubmit}
                  onFetchNavContent={onFetchNavContent}
                  onCalcComponentHeight={onCalcComponentHeight}
                  {...props}
                />
              )}
            />
            <Route
              path='/'
              exact
              render={props => (
                <MiddleZone
                  account={account}
                  coveredBanners={officialCoveredBanners}
                  currentBanner={currentBanner}
                  onThumbHover={onThumbHover}
                  articleTypeLabel={articleTypeLabel}
                  onUpdateSnippetList={onUpdateSnippetList}
                  officialSnippetList={officialSnippetList}
                  onCalcComponentHeight={onCalcComponentHeight}
                  onCoveredBannerInputChange={onCoveredBannerInputChange}
                  onCoveredBannerSubmit={onCoveredBannerSubmit}
                  onFetchMoreSnippets={this.props.onFetchMoreSnippets}
                  {...props}
                />
              )}
            />
          </Switch>
        </div>

        <RightZone
          panel={panel.right}
          account={account}
          market={market}
          prevMarket={prevMarket}
          eosMarket={eosMarket}
          prevEosMarket={prevEosMarket}
          forkMarket={forkMarket}
          prevForkMarket={prevForkMarket}
          navIcons={navIcons}
          noticeList={noticeList}
          infoList={infoList}
          meetupList={meetupList}
          currentLogMode={currentLogMode}
          eoscityStatistic={eoscityStatistic}
          onLoggedOut={onLoggedOut}
          onChangeLogMode={onChangeLogMode}
          login={login}
          register={register}
          navPanel={navPanel}
          onLogin={onLogin}
          onInputChange={onInputChange}
          onLoginPanelChange={onLoginPanelChange}
          onRegistrationSubmit={onRegistrationSubmit}
          onMessageChange={onMessageChange}
          onSendMessage={onSendMessage}
          message={message}
          chatCollection={chatCollection}
          onKeepMeLoggedIn={onKeepMeLoggedIn}
          keepMeLoggedIn={keepMeLoggedIn}
          myArticles={myArticles}
          onArticleSelected={onArticleSelected}
          onArticleDelete={onArticleDelete}
          onCalcComponentHeight={onCalcComponentHeight}
          onNavButtonSubclassChange={onNavButtonSubclassChange}
          onNavIconChange={onNavIconChange}
          onNavPanelInputChange={onNavPanelInputChange}
          onButtonListSubmit={onButtonListSubmit}
          onResetArticleEditorBlock={this.props.onResetArticleEditorBlock}
          onNavIconSelected={onNavIconSelected}
          onPanelSelect={this.props.onPanelSelect}
          onPanelExpand={this.props.onPanelExpand}
          onConfirmationResend={this.props.onConfirmationResend}
          onFetchArticleForEdit={this.props.onFetchArticleForEdit}
          errors={errors}
        />
      </div>
    );
  }
}

export default withRouter(Home);
