import React from 'react';

const QuickLogin = ({
  onLogin,
  onInputChange,
  login,
  onKeepMeLoggedIn,
  keepMeLoggedIn,
  errors
}) => {
  return (
    <div className='nav2-subZone'>
      <div className='subTag clearfix'>
        <div className='subIcon'>
          <img src='/img/boards/eosCity2.svg' alt='快速登入' />
        </div>
        <div className='title'>快速登入</div>
      </div>
      <div className='subContainer'>
        <div className='rightLoginInput'>
          <div className='description'>
            <label>電郵地址</label>
          </div>
          <input
            name='email'
            onKeyDown={e => {
              if (e.keyCode === 13 && e.shiftKey === false) {
                e.preventDefault();
                onLogin();
              }
            }}
            onChange={e => {
              onInputChange(e, 'login');
            }}
            value={login.email}
            style={{ backgroundColor: errors.email ? '#ffe6e6' : '' }}
          />
          {errors.email ? <div className='error'>{errors.email}</div> : null}
        </div>

        <div className='rightLoginInput'>
          <div className='description'>
            <label>登入密碼</label>
          </div>
          <input
            type='password'
            name='password'
            onKeyDown={e => {
              if (e.keyCode === 13 && e.shiftKey === false) {
                e.preventDefault();
                onLogin();
              }
            }}
            onChange={e => onInputChange(e, 'login')}
            value={login.password}
            style={{ backgroundColor: errors.password ? '#ffe6e6' : '' }}
          />
          {errors.password ? (
            <div className='error'>{errors.password}</div>
          ) : null}
        </div>

        <div className='rightLoginAssist'>
          <label className='rememberMeContainer'>
            保持登入
            <input
              type='checkbox'
              checked={keepMeLoggedIn ? 'checked' : ''}
              value={keepMeLoggedIn}
              onChange={onKeepMeLoggedIn}
            />
            <span className='checkmark'></span>
          </label>
          <div className='link'>忘記密碼?</div>
          <div className='link'>立即註冊</div>

          <div
            className='quickLogin-btn'
            onClick={() => {
              onLogin();
            }}
          >
            登入
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickLogin;
