import React, { Component } from "react";
import Input from "./components/Input";
import Inputbox from "./components/Inputbox";
import Insert from "./components/Insert";
import SnippetTemplate from "./components/snippetTemplate";
import QuestionMark from "../../../common/icons/questionMark";

class Editor extends Component {
  render() {
    const {
      _id,
      version,
      title,
      label,
      outline,
      contentBlocks,
      coverImgPath,
      errors
    } = this.props.article;

    const { coverImgSrc } = this.props.temp;

    const {
      update,
      onChange,
      onSnippetChange,
      onSnippetDelete,
      onCoverImgUpload,
      onCoverImgDelete,
      onSelectedLang,
      onArticleSubmit,
      onLabelSelected
    } = this.props;

    const info =
      "可以不上傳封面圖片，如果要上傳圖片最低闊度為 710px，圖片闊度與高度比例要求為 '2:1' 至 '1:1'，高度不能大於闊度，檔案大少不能多於 1.5MB";
    let currentLabel = "";
    if (version === "official" && !update) {
      currentLabel = "主網";
    } else if (version === "community" && !update) {
      currentLabel = "綜合";
    } else {
      currentLabel = label;
    }

    return (
      <div>
        <h3 className="articleEdit-editor-header">
          {update ? "編輯及更新文章" : "文章編輯器"}
        </h3>
        <div className="articleEdit-editor-container">
          <div className="articleEdit-labelContainer">
            文章類型
            {version === "official" ? (
              <select
                className="articleEdit-label"
                onChange={e => onLabelSelected(e)}
              >
                <option
                  className="articleEdit-label-option"
                  value={currentLabel}
                >
                  {currentLabel}
                </option>
                <option value="主網">主網</option>
                <option value="遊戲">遊戲</option>
                <option value="幣市">幣市</option>
                <option value="DAPPS">DAPPS</option>
                <option value="社區">社區</option>
                <option value="BP">BP</option>
                <option value="教程">教程</option>
              </select>
            ) : version === "community" ? (
              <select
                className="articleEdit-label"
                onChange={e => onLabelSelected(e)}
              >
                <option
                  className="articleEdit-label-option"
                  value={currentLabel}
                >
                  {currentLabel}
                </option>
                <option value="綜合">綜合</option>
                <option value="政治">政治</option>
                <option value="經濟">經濟</option>
                <option value="工業">工業</option>
                <option value="農業">農業</option>
                <option value="商業">商業</option>
                <option value="科技">科技</option>
                <option value="生活">生活</option>
                <option value="消閑">消閑</option>
              </select>
            ) : null}
          </div>

          <Input
            elementId="articleEdit-editor-title"
            name="title"
            label="封面標題"
            value={title}
            type="text"
            onChange={onChange}
            errors={errors}
          />
          <Inputbox
            elementId="articleEdit-editor-outline"
            name="outline"
            label="文章概要"
            value={outline}
            onChange={onChange}
            errors={errors}
          />

          <div className="articleEdit-editor-imgUpload-container">
            <div className="articleEdit-editor-imgUpload-preview">
              <img
                className="img"
                src={
                  coverImgSrc
                    ? coverImgSrc
                    : update && coverImgPath
                    ? `/img/articles/${_id}-cover.jpg`
                    : "/img/articleEditor/imgUpload/preview.svg"
                }
                alt="preview"
              />
            </div>

            <div className="articleEdit-editor-imgUpload-controlContainer">
              <div className="articleEdit-editor-imgUpload-control-titleContainer">
                <h2>封面圖片</h2>
                <div className="right">
                  <QuestionMark title={info} />
                  <i
                    className="fa fa-trash icon"
                    onClick={() => onCoverImgDelete()}
                  />
                </div>
              </div>
              <div className="uploadPath">
                {coverImgSrc ? "圖檔: " : "未選擇任何檔案"}
                <br />
                {coverImgSrc ? coverImgPath : ""}
              </div>
              <label className="uploadBtn" htmlFor="upload-photo">
                選擇圖片
              </label>
              <input
                type="file"
                name="photo"
                id="upload-photo"
                onChange={e => onCoverImgUpload(e)}
              />
            </div>
          </div>
          <div className="articleEdit-editor-contentContainer">
            <div className="articleEdit-editor-contentContainer-title">
              文章內容
            </div>
            <div className="articleEdit-editor-contentEditor">
              <Insert
                position={0}
                onInsertTypeSelected={this.props.onInsertTypeSelected}
              />
              {contentBlocks.length === 0
                ? null
                : contentBlocks.map((block, index) => (
                    <div key={index}>
                      <div>
                        <SnippetTemplate
                          index={index}
                          tag={block.componentType}
                          update={update}
                          onSnippetChange={onSnippetChange}
                          value={contentBlocks[index].content.text}
                          onSnippetDelete={onSnippetDelete}
                          content={contentBlocks[index].content}
                          onSelectedLang={onSelectedLang}
                        />
                      </div>
                      <Insert
                        position={index + 1}
                        onInsertTypeSelected={this.props.onInsertTypeSelected}
                      />
                    </div>
                  ))}
              {errors.contentBlocks ? (
                <div className="warning"> {errors.contentBlocks} </div>
              ) : null}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <div
              className="articleSubmitBtn"
              onClick={() => {
                onArticleSubmit();
              }}
            >
              {update ? "更新文章" : "建立文章"}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Editor;
