import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../css/header.css';

class Header extends Component {
  componentDidMount() {
    if (
      window.location.pathname !== '/' ||
      window.location.pathname !== '/article/editor/new/community' ||
      window.location.pathname !== '/article/editor/new/official'
    ) {
      if (window.location.pathname.includes('articles')) console.log('true');
    }
  }
  render() {
    const {
      onArticleEditVersionChange,
      articleEditorBlock,
      articleBlock
    } = this.props;

    return (
      <header id='topZone'>
        <div className='header'>
          <Link
            to={
              window.location.pathname === '/' ||
              window.location.pathname === '/article/editor/new/community' ||
              (window.location.pathname.includes(articleBlock.article._id) &&
                articleBlock.article.version === 'community') ||
              (articleEditorBlock.article._id &&
                articleEditorBlock.article.version === 'community')
                ? '/community'
                : window.location.pathname === '/official' ||
                  (window.location.pathname ===
                    '/article/editor/new/official' &&
                    articleEditorBlock.article.version === 'official') ||
                  (window.location.pathname.includes(
                    articleBlock.article._id
                  ) &&
                    articleBlock.article.version === 'official') ||
                  (articleEditorBlock.article._id &&
                    articleEditorBlock.article.version === 'official')
                ? '/'
                : '/'
            }
            className='header-logo'
          >
            <img
              className={
                window.location.pathname === '/' ||
                window.location.pathname === '/article/editor/new/official' ||
                (window.location.pathname === '/article/editor/new/official' &&
                  articleEditorBlock.article.version === 'official') ||
                (window.location.pathname.includes(articleBlock.article._id) &&
                  articleBlock.article.version === 'official') ||
                (articleEditorBlock.article._id &&
                  articleEditorBlock.article.version === 'official')
                  ? 'official active'
                  : 'official'
              }
              src='/img/header/eosCity_official_logo.svg'
              alt='official'
            />
            <img
              className={
                window.location.pathname === '/community' ||
                window.location.pathname === '/article/editor/new/community' ||
                (window.location.pathname === '/article/editor/new/official' &&
                  articleEditorBlock.article.version === 'community') ||
                (window.location.pathname.includes(articleBlock.article._id) &&
                  articleBlock.article.version === 'community') ||
                (articleEditorBlock.article._id &&
                  articleEditorBlock.article.version === 'community')
                  ? 'community active'
                  : 'community'
              }
              src='/img/header/eosCity_community_logo.svg'
              alt='community'
            />
          </Link>

          {window.location.pathname === '/article/editor/new/community' ? (
            <div className='version-btn-container'>
              <div className='header-communityEditor'>社區版發文</div>
            </div>
          ) : window.location.pathname === '/article/editor/new/official' ? (
            <div className='version-btn-container'>
              <div
                className={
                  articleEditorBlock.article.version === 'official'
                    ? 'header-official-officialEditor active'
                    : 'header-official-officialEditor'
                }
                onClick={() => onArticleEditVersionChange('official')}
              >
                官方版發文
              </div>
              <div
                className={
                  articleEditorBlock.article.version === 'community'
                    ? 'header-official-communityEditor active'
                    : 'header-official-communityEditor'
                }
                onClick={() => onArticleEditVersionChange('community')}
              >
                社區版發文
              </div>
            </div>
          ) : null}

          {this.props.account.name && localStorage.getItem('token') ? (
            <div className='accountPanel'>
              <div className='accountPanel-left'>
                <div className='accountPanel-left-avatar-container'>
                  <img
                    className='accountPanel-left-avatar'
                    src={`/img/accounts/avatars/custom/${this.props.account.userId}.png`}
                    alt={this.props.account.name}
                  />
                </div>
                <Link className='accountPanel-left-account' to='/account/edit'>
                  {this.props.account.name}
                </Link>
              </div>

              <div className='accountPanel-right'>
                <div
                  className='accountPanel-right-logout-img-container'
                  onClick={() => {
                    this.props.onLoggedOut();
                    this.props.onLoginPanelChange('login');
                  }}
                >
                  <img
                    className='accountPanel-right-logout-img'
                    src='/img/buttons/controlPanel/logout.svg'
                    alt='登出'
                  />
                  登出
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
