import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, Switch, withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import Joi from 'joi-browser';
import jwtDecode from 'jwt-decode';
import socketioClient from 'socket.io-client';
import Eyebrow from './components/eyebrow';
import Header from './components/header';
import Home from './components/mode/homeMode/home';
import ArticleEditor from './components/mode/articleEditMode/articleEditor';
import { chineseTimeFormat } from './utils/chineseTimeFormat';
import customToast from './utils/customToast';

import 'react-toastify/dist/ReactToastify.css';
import './css/toastify.css';

let currentTime = moment().format('YYYY年MM月DD號(ddd) Ah點mm分');
currentTime = chineseTimeFormat(currentTime);
let currentTimeS = moment().format('YYYY年MM月DD號(ddd) Ah點mm分ss秒');
currentTimeS = chineseTimeFormat(currentTimeS);

class App extends Component {
  state = {
    officialSnippetList: [],
    communitySnippetList: [],
    articleTypeLabel: '最新',
    officialArticleTypeLabel: '最新',
    communityArticleTypeLabel: '最新',
    currentLeftTab: 3,
    currentBanner: 0,
    currentArticleTypeBtn: 0,
    currentLogMode: 'register',
    account: {
      name: '',
      userId: '',
      avatar: '',
      intro: '',
      eosAccount: '',
      telosAccount: '',
      bosAccount: '',
      worbliAccount: '',
      waxAccount: '',
      meetOneAccount: '',
      lynxAccount: '',
      admin: '',
      moderator: ''
    },
    login: {
      email: '',
      password: ''
    },
    register: {
      name: '',
      email: '',
      password: '',
      confirmedPassword: ''
    },
    updatedAccount: {},
    market: [],
    prevMarket: [],
    eosMarket: [],
    prevEosMarket: [],
    forkMarket: [],
    prevForkMarket: [],
    message: '',
    chatCollection: [],
    // endpoint: 'http://localhost:3001',
    // apiEndpoint: 'http://localhost:3001/api',
    endpoint: 'https://eoscity.io',
    apiEndpoint: 'https://eoscity.io/api',
    keepMeLoggedIn: true,
    myArticles: [],
    articleBlock: {
      article: {
        _id: '',
        title: '',
        label: '',
        loves: null,
        publishTime: null,
        updatedTime: null,
        contentBlocks: [],
        author: {
          name: '',
          userId: '',
          avatar: '',
          powerLevel: ''
        },
        replies: []
      },
      love: false,
      reply: '',
      embeddedReply: {},
      errors: {
        reply: ''
      }
    },
    articleEditType: 'community',
    articleEditorBlock: {
      article: {
        _id: '',
        version: '',
        title: '',
        label: '',
        coverImg: null,
        coverImgPath: '',
        contentBlocks: [],
        outline: '',
        loves: 0,
        errors: { title: '', outline: '', contentBlocks: '' }
      },
      temp: {
        coverImgSrc: null
      },
      coverImgFile: null,
      currentTime: currentTime,
      update: false
    },
    navPanel: { subclasses: [] },
    buttonCollections: [{ class: 'navPanel', subclasses: [] }],
    height: {},
    navContent: {
      title: '',
      iconRef: '',
      blocks: []
    },
    officialCoveredBanners: [],
    communityCoveredBanners: [],
    eoscityStatistic: {},
    accountSearch: {
      userIdSearch: '',
      nameSearch: '',
      emailSearch: '',
      timeSearch: ''
    },
    infoList: [],
    noticeList: [],
    meetupList: [],
    newsInput: {
      info: '',
      infoLink: '',
      notice: '',
      noticeLink: '',
      meetup: '',
      meetupLink: ''
    },
    leftHeight: 0,
    middleHeight: 0,
    rightHeight: 0,
    wrapperHeight: 1100,
    snippetLists: {
      official: {
        最新: 20,
        主網: 20,
        遊戲: 20,
        幣市: 20,
        DAPPS: 20,
        社區: 20,
        BP: 20,
        教程: 20
      },
      community: {
        最新: 20,
        綜合: 20,
        政治: 20,
        經濟: 20,
        工業: 20,
        農業: 20,
        商業: 20,
        科技: 20,
        生活: 20,
        消閑: 20
      }
    },
    panel: {
      left: {
        active: '行情',
        myAccount: true,
        myArticles: true
      },
      right: {
        active: '社區',
        myAccount: true,
        myArticles: true
      }
    },
    errors: {}
  };

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const panelJson = localStorage.getItem('panel');
    if (panelJson) {
      const panel = JSON.parse(panelJson);
      this.setState({ panel });
    }

    const jwt = localStorage.getItem('token');
    const accountToken = localStorage.getItem('account');
    const account = JSON.parse(accountToken);
    const { endpoint, apiEndpoint } = this.state;

    const { data: mainData } = await axios.get(`${apiEndpoint}`);
    let {
      buttonLists,
      snippetList,
      coveredBanners,
      eoscityStatistic,
      newsList
    } = mainData;

    this.classifyAndUpdateNewsList(newsList);

    const navPanel = buttonLists.find(list => list.class === 'navPanel');

    const officialSnippetList = snippetList.filter(
      snippet => snippet.version === 'official'
    );

    const communitySnippetList = snippetList.filter(
      snippet => snippet.version === 'community'
    );

    let officialCoveredBanners = coveredBanners.filter(
      banner => banner.version === 'official'
    );
    if (officialCoveredBanners.length < 6) {
      for (let i = 0; i < 6; i++) {
        if (!officialCoveredBanners[i]) {
          officialCoveredBanners[i] = {
            version: 'official',
            content: '',
            linkType: '',
            link: ''
          };
        }
      }
    } else if (officialCoveredBanners.length > 6) {
      officialCoveredBanners = _.take(officialCoveredBanners, 6);
    }
    let communityCoveredBanners = coveredBanners.filter(
      banner => banner.version === 'community'
    );
    if (communityCoveredBanners.length < 6) {
      for (let i = 0; i < 6; i++) {
        if (!communityCoveredBanners[i]) {
          communityCoveredBanners[i] = {
            version: 'community',
            content: '',
            linkType: '',
            link: ''
          };
        }
      }
    } else if (communityCoveredBanners.length > 6) {
      communityCoveredBanners = _.take(communityCoveredBanners, 6);
    }

    this.setState({
      navPanel,
      officialSnippetList,
      communitySnippetList,
      officialCoveredBanners,
      communityCoveredBanners,
      eoscityStatistic
    });

    if (window.location.pathname === '/') {
      this.setState({
        articleTypeLabel: '最新'
      });
    } else if (window.location.pathname === '/article/editor') {
      if (jwt) {
        let articleEditorBlock = { ...this.state.articleEditorBlock };
        if (account.admin || account.moderator) {
          articleEditorBlock.article.version = 'official';
          articleEditorBlock.article.label = '主網';
          this.setState({
            articleEditorBlock,
            articleEditType: 'official'
          });
        } else {
          articleEditorBlock.article.version = 'community';
          articleEditorBlock.article.label = '幣市';
          this.setState({
            articleEditType: 'community'
          });
        }
      }
    }

    const resPriceList = await axios.get(`${apiEndpoint}/market`);
    const market = resPriceList.data;

    const eosMarket = market.filter(
      coin =>
        coin.symbol.split('-')[2] === 'eos' && coin.contract !== 'eosio.token'
    );

    const forkMarket = market.filter(
      coin =>
        coin.contract === 'eosio.token' && coin.symbol.split('-')[1] !== 'eos'
    );

    const fiatMarket = market.filter(coin => coin.exchange === 'binance');

    this.setState({
      market,
      prevMarket: market,
      eosMarket,
      prevEosMarket: eosMarket,
      forkMarket,
      prevForkMarket: forkMarket,
      fiatMarket,
      prevFiatMarket: fiatMarket
    });

    this.timer = setInterval(async () => {
      const resPriceList = await axios.get(`${apiEndpoint}/market`);
      let market = resPriceList.data;

      let eosMarket, forkMarket, fiatMarket;
      if (market) {
        eosMarket = market.filter(
          coin =>
            coin.symbol.split('-')[2] === 'eos' &&
            coin.contract !== 'eosio.token'
        );
        if (!eosMarket.length) return;

        forkMarket = market.filter(
          coin =>
            coin.contract === 'eosio.token' &&
            coin.symbol.split('-')[1] !== 'eos'
        );

        fiatMarket = market.filter(coin => coin.exchange === 'binance');
        if (!fiatMarket.length) return;
      }

      this.setState(prevState => {
        if (!market) market = prevState.market;
        if (market.length === 0) market = prevState.market;

        if (!eosMarket) eosMarket = prevState.eosMarket;
        if (eosMarket.length === 0) eosMarket = prevState.eosMarket;

        if (!forkMarket) forkMarket = prevState.forkMarket;
        if (forkMarket.length === 0) forkMarket = prevState.forkMarket;

        if (!fiatMarket) fiatMarket = prevState.fiatMarket;
        if (fiatMarket.length === 0) fiatMarket = prevState.fiatMarket;

        return {
          market: market,
          prevMarket: prevState.market,
          eosMarket: eosMarket,
          prevEosMarket: prevState.eosMarket,
          forkMarket: forkMarket,
          prevForkMarket: prevState.forkMarket,
          fiatMarket: fiatMarket,
          prevFiatMarket: prevState.fiatMarket
        };
      });
    }, 2000);

    this.timer = setInterval(() => {
      if (this.state.currentBanner < 5) {
        this.setState({ currentBanner: this.state.currentBanner + 1 });
      } else {
        this.setState({ currentBanner: 0 });
      }
    }, 4000);

    setTimeout(async () => {
      try {
        const current = moment().unix();

        if (jwt && accountToken) {
          const decoded = jwtDecode(jwt);
          this.handleChangeLogMode('loggedIn');
          if (decoded.exp < current) {
            this.handleChangeLogMode('register');
            localStorage.removeItem('token');
            localStorage.removeItem('account');
            axios.defaults.headers.common['x-auth-token'] = '';
            return;
          } else {
            axios.defaults.headers.common['x-auth-token'] = jwt;
            const resMyArticles = await axios.get(`${apiEndpoint}/articles/me`);
            const myArticles = resMyArticles.data;

            this.setState({ account, myArticles });
          }
        } else {
          this.handleChangeLogMode('register');
          localStorage.removeItem('token');
          localStorage.removeItem('account');
        }
      } catch (ex) {
        console.log(ex);
      }
    }, 200);

    const socket = socketioClient(endpoint);
    socket.on('chat', chatCollection => {
      this.setState({ chatCollection });
    });
  }

  time = Date.now();

  calcComponentHeight = (componentHeight, component) => {
    if (this.time + 1000 - Date.now() < 0) {
      this.setState({ [component]: componentHeight });
    }
  };

  handleScroll = () => {
    if (this.time + 1000 - Date.now() < 0) {
      let wrapperHeight = 0;
      const { leftHeight, middleHeight, rightHeight } = this.state;
      if (middleHeight > rightHeight && middleHeight > leftHeight) {
        wrapperHeight = middleHeight + 50;
      } else if (rightHeight > middleHeight && rightHeight > leftHeight) {
        wrapperHeight = rightHeight + 50;
      } else if (leftHeight > middleHeight && leftHeight > rightHeight) {
        wrapperHeight = leftHeight + 50;
      }
      this.setState({ wrapperHeight });
      this.time = Date.now();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  articleEditVersionChange = version => {
    let articleEditorBlock = { ...this.state.articleEditorBlock };
    articleEditorBlock.article.version = version;
    this.setState({ articleEditorBlock });
  };

  handleThumbHover = thumbIndex => {
    clearInterval(this.timer);
    this.setState({
      currentBanner: thumbIndex
    });

    this.timer = setInterval(() => {
      if (this.state.currentBanner < 5) {
        this.setState({ currentBanner: this.state.currentBanner + 1 });
      } else {
        this.setState({ currentBanner: 0 });
      }
    }, 4000);
  };

  updateSnippetList = async (mode, type) => {
    const { apiEndpoint, snippetLists } = this.state;
    const number = snippetLists[mode][type];

    const url = `${apiEndpoint}/articles/snippets/${type}/${mode}/${number}`;
    const { data: snippetList } = await axios.get(url);

    if (mode === 'community') {
      this.setState({ communitySnippetList: snippetList });
    } else if (mode === 'official') {
      this.setState({ officialSnippetList: snippetList });
    }

    this.setState({ articleTypeLabel: type });
  };

  handleChangeLogMode = mode => {
    this.setState({ currentLogMode: mode });
  };

  handleInputChange = (e, currentMode) => {
    let state = { ...this.state };
    state[currentMode][e.target.name] = e.target.value;
    let errors = { ...this.state.errors };
    errors[e.target.name] = null;
    this.setState({ errors });
  };

  handleLogin = async () => {
    const { login, apiEndpoint } = this.state;
    const errors = this.validate('login');
    this.setState({ errors });
    for (let err in errors) {
      if (errors[err] !== '') return;
    }
    try {
      const res = await axios.post(`${apiEndpoint}/account/login`, login);
      localStorage.setItem('token', res.headers['x-auth-token']);
      const jwt = localStorage.getItem('token');
      axios.defaults.headers.common['x-auth-token'] = jwt;
      const token = { token: jwt };
      const resAuth = await axios.post(`${apiEndpoint}/account/auth`, token);
      if (resAuth.status === 200) {
        let decoded = jwtDecode(jwt);
        localStorage.setItem('decoded', JSON.stringify(decoded));
        const account = resAuth.data;
        localStorage.setItem('account', JSON.stringify(account));
        this.handleChangeLogMode('loggedIn');
        const resMyArticles = await axios.get(`${apiEndpoint}/articles/me`);
        const myArticles = resMyArticles.data;
        this.setState({ account, myArticles });
        console.log(
          'Login Successful',
          JSON.parse(localStorage.getItem('account'))
        );
      }
    } catch (ex) {
      let errors = { ...this.state.errors };
      const errorMessages = ex.response.data;
      errors.email = ex.response.data;
      errors.password = ex.response.data;
      if (errorMessages.includes('password')) {
        errors.email = '*你不是輸入正確的電郵或密碼，請重新輸入';
        errors.password = '*你不是輸入正確的電郵或密碼，請重新輸入';
      }
      this.setState({ errors });
    }
  };

  handleLoggedOut = () => {
    this.props.history.push('/');
    this.setState({
      currentLogMode: 'login',
      account: {
        name: '',
        userId: '',
        avatar: '',
        intro: '',
        eosAccount: '',
        telosAccount: '',
        bosAccount: '',
        worbliAccount: '',
        waxAccount: '',
        meetOneAccount: '',
        lynxAccount: ''
      },
      myArticles: []
    });
    localStorage.removeItem('token');
    localStorage.removeItem('account');
    axios.defaults.headers.common['x-auth-token'] = '';
  };

  handleLoginPanelChange = currentLogMode => {
    this.setState({
      login: {
        email: '',
        password: ''
      },
      register: {
        name: '',
        email: '',
        password: '',
        confirmedPassword: ''
      },
      errors: {
        name: '',
        email: '',
        password: '',
        confirmedPassword: ''
      }
    });
    this.setState({ currentLogMode });
  };

  handleRegistrationSubmit = async () => {
    const { register, apiEndpoint } = this.state;
    let errors = this.validate('register');

    if (register.password !== register.confirmedPassword) {
      errors.confirmedPassword =
        '*您輸入的密碼不正確，請核對與登入密碼是否想相同';
      return this.setState({ errors });
    }

    this.setState({ errors });

    for (let err in errors) {
      if (errors[err] !== '') return;
    }

    try {
      const res = await axios.post(`${apiEndpoint}/account/create`, register);
      if (res.status === 200) {
        const register = {
          name: '',
          email: '',
          password: '',
          confirmedPassword: ''
        };
        this.setState({ register });
        const { data: account } = res;
        this.setState({ account });
        console.log('registration successful: ', account);
        return this.props.history.push('/account/email-confirmation-request');
      }
    } catch (ex) {
      let errors = { ...this.state.errors };
      const errorMessages = ex.response.data;
      console.log(errorMessages);

      errorMessages.forEach(message => {
        if (message.includes('password')) {
          errors = { ...this.state.errors };
          errors.password =
            '*您輸入的密碼必須包括數字、英文大小階，最少8位但不多於30位的字串';
          errors.confirmedPassword =
            '*您輸入的密碼必須包括數字、英文大小階，最少8位但不多於30位的字串';
          this.setState({ errors });
        } else if (message.includes('電郵')) {
          errors = { ...this.state.errors };
          errors.email = message;
          this.setState({ errors });
        } else if (message.includes('暱稱')) {
          errors = { ...this.state.errors };
          errors.name = message;
          this.setState({ errors });
        }
      });
      console.log(errorMessages);
    }
  };

  handleUpdateSuccess = account => {
    this.setState({ account });
  };

  handleArticleSelected = async articleId => {
    const { apiEndpoint } = this.state;
    window.scrollTo(0, 0);
    const jwt = localStorage.getItem('token');
    let accountToken = localStorage.getItem('account');
    let account = {};
    let love = false;

    if (accountToken && jwt) {
      account = JSON.parse(accountToken);
      axios.defaults.headers.common['x-auth-token'] = jwt;
      let resLoved = await axios.get(
        `${apiEndpoint}/articles/${articleId}/love`
      );
      love = resLoved.data.loved;
    }

    const resArticle = await axios.get(`${apiEndpoint}/articles/${articleId}`);
    let article = resArticle.data;
    const articleBlock = { ...this.state.articleBlock };
    articleBlock.article = article;
    articleBlock.love = love;
    articleBlock.reply = '';
    this.setState({ account, articleBlock });
  };

  onMessageChange = e => {
    let message = e.target.value;
    this.setState({ message });
  };

  onSendMessage = () => {
    const { message, endpoint } = this.state;
    const socket = socketioClient(endpoint);
    const token = localStorage.getItem('token');

    const messagePack = { message, token };
    this.setState({ message: '' });
    socket.emit('chat', messagePack);
  };

  onKeepMeLoggedIn = e => {
    let keepMeLoggedIn = !this.state.keepMeLoggedIn;
    this.setState({ keepMeLoggedIn }, () => {
      if (this.state.keepMeLoggedIn === false) {
        window.onbeforeunload = () => {
          localStorage.removeItem('token');
          localStorage.removeItem('account');
          localStorage.removeItem('decoded');
        };
      }
    });
  };

  handleLove = async id => {
    const { apiEndpoint } = this.state;
    const jwt = localStorage.getItem('token');
    if (this.state.articleBlock.love)
      return customToast.success('您已經送了 Love 到這篇文章了 ');
    if (jwt) {
      axios.defaults.headers.common['x-auth-token'] = jwt;

      try {
        const returnDoc = await axios.post(
          `${apiEndpoint}/articles/${id}/love`
        );
        if (!returnDoc.data.loved) {
          return customToast.success('不能送給自己發表的文章 Love');
        }
        if (returnDoc.status === 200) {
          let articleBlock = { ...this.state.articleBlock };
          const { article, loved } = returnDoc.data;
          if (article) {
            articleBlock.article = article;
            articleBlock.love = loved;
            this.setState({ articleBlock });
          }
        }
      } catch (ex) {
        console.log(ex);
      }
    } else if (!jwt) {
      return customToast.success('您未登入您的帳號，所以不能送給 Love');
    }
  };

  handleReply = e => {
    const reply = e.target.value;
    let articleBlock = { ...this.state.articleBlock };
    articleBlock.reply = reply;
    this.setState({ articleBlock });
  };

  handleReplySubmit = async articleId => {
    let articleBlock = { ...this.state.articleBlock };
    const { apiEndpoint } = this.state;
    const jwt = localStorage.getItem('token');
    if (jwt) {
      const reply = { reply: this.state.articleBlock.reply };
      if (reply.reply === '') {
        articleBlock.errors.reply = '*您沒有輸入任何內容';
        return this.setState({ articleBlock });
      }

      axios.defaults.headers.common['x-auth-token'] = jwt;
      const resReply = await axios.post(
        `${apiEndpoint}/articles/${articleId}/reply`,
        reply
      );

      if (resReply.status === 200) {
        const replies = resReply.data.replies;
        articleBlock.article.replies = replies;
        articleBlock.reply = '';
      }

      this.setState({ articleBlock });
    }
  };

  openEmbeddedReplyInput = replyId => {
    const jwt = localStorage.getItem('token');
    if (!jwt) return;
    let articleBlock = { ...this.state.articleBlock };
    const index = articleBlock.article.replies.findIndex(
      reply => reply._id.toString() === replyId.toString()
    );
    articleBlock.article.replies[index].open = !articleBlock.article.replies[
      index
    ].open;
    this.setState({ articleBlock });
  };

  handleEmbeddedReply = e => {
    const snippetId = e.target.name;
    let articleBlock = { ...this.state.articleBlock };
    articleBlock.embeddedReply[snippetId] = e.target.value;
    this.setState({ articleBlock });
  };

  openEmbeddedReplyMessages = id => {
    let articleBlock = { ...this.state.articleBlock };
    const index = articleBlock.article.replies.findIndex(
      reply => reply._id.toString() === id.toString()
    );
    articleBlock.article.replies[index].openReplyMessages = !articleBlock
      .article.replies[index].openReplyMessages;
    this.setState({ articleBlock });
  };

  handleEmbeddedReplySubmit = async (replyId, articleId) => {
    const { apiEndpoint } = this.state;
    let articleBlock = { ...this.state.articleBlock };
    let embeddedReply = articleBlock.embeddedReply;
    if (!embeddedReply[replyId]) return console.log('您沒有輸入任何內容');
    const sendReply = {
      snippetId: replyId,
      message: embeddedReply[replyId]
    };
    const jwt = localStorage.getItem('token');
    if (jwt) {
      try {
        axios.defaults.headers.common['x-auth-token'] = jwt;
        const resEmbeddedReply = await axios.post(
          `${apiEndpoint}/articles/${articleId}/embeddedreply`,
          sendReply
        );
        const index = articleBlock.article.replies.findIndex(
          reply => reply._id === replyId
        );
        let updatedReplySnippet = resEmbeddedReply.data;
        articleBlock.article.replies[index] = updatedReplySnippet;
        updatedReplySnippet.openReplyMessages = true;
        embeddedReply[replyId] = '';
        this.setState({ articleBlock });
      } catch (ex) {
        console.log(ex);
      }
    }
  };

  handleResetArticleBlock = () => {
    const articleBlock = {
      article: {
        _id: '',
        title: '',
        label: '',
        loves: null,
        publishTime: null,
        updatedTime: null,
        contentBlocks: [],
        author: {
          name: '',
          userId: '',
          avatar: '',
          powerLevel: ''
        },
        replies: []
      },
      love: false,
      reply: '',
      embeddedReply: {},
      errors: {
        reply: ''
      }
    };

    this.setState({ articleBlock });
  };

  updateMyArticles = async () => {
    const { apiEndpoint } = this.state;
    const { data: myArticles } = await axios.get(`${apiEndpoint}/articles/me`);
    this.setState({ myArticles });
  };

  handleArticleDelete = async article => {
    const { apiEndpoint, articleTypeLabel } = this.state;
    const jwt = localStorage.getItem('token');
    if (!jwt) return;
    axios.defaults.headers.common['x-auth-token'] = jwt;
    try {
      const res = await axios.delete(
        `${apiEndpoint}/articles/remove/${article._id}`
      );
      if (res.status === 200) {
        const { data: updatedPackage } = res;

        let { myArticles, snippets } = updatedPackage;
        this.setState({ myArticles });

        if (articleTypeLabel !== '最新') {
          snippets = snippets.filter(
            snippet => snippet.label === articleTypeLabel
          );
        }
        if (article.version === 'official') {
          this.setState({ officialSnippetList: snippets });
          this.props.history.push('/');
        } else if (article.version === 'community') {
          this.setState({ communitySnippetList: snippets });
          this.props.history.push('/community');
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  articleSchema = {
    _id: Joi.string().allow(''),
    version: Joi.string().allow(''),
    title: Joi.string()
      .min(1)
      .max(50)
      .required()
      .error(() => {
        return {
          message: '*您沒有輸入標題或您輸入的標題超過 50 字'
        };
      }),
    label: Joi.string().allow(''),
    coverImg: Joi.allow(''),
    coverImgPath: Joi.string().allow(''),
    contentBlocks: Joi.array()
      .min(1)
      .error(() => {
        return {
          message: '*您最少要插入一個區塊內容'
        };
      }),
    outline: Joi.string()
      .min(1)
      .max(115)
      .required()
      .error(() => {
        return {
          message: '*您沒有輸入摘錄內容或您輸入的摘錄內容超過 115 字'
        };
      }),
    loves: Joi.allow(''),
    publish: Joi.allow(''),
    publishTime: Joi.allow(''),
    publishTimeFormattedS: Joi.allow(''),
    publishTimeFormattedM: Joi.allow(''),
    updatedTime: Joi.allow(''),
    updatedTimeFormattedS: Joi.allow(''),
    updatedTimeFormattedM: Joi.allow(''),
    author: Joi.allow(''),
    replies: Joi.allow(''),
    __v: Joi.allow(''),
    errors: Joi.allow('')
  };

  validateArticle = () => {
    const options = { abortEarly: false };
    let result = Joi.validate(
      this.state.articleEditorBlock.article,
      this.articleSchema,
      options
    );
    if (!result.error) return { title: '', outline: '' };

    let errors = { title: '', outline: '' };
    for (let item of result.error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  loginSchema = {
    email: Joi.string()
      .email()
      .required()
      .error(() => {
        return {
          message: '*您沒有輸入正確的電郵地址，請重新輸入'
        };
      }),
    password: Joi.string()
      .regex(/^[a-zA-Z0-9!@#$%&*]{8,30}$/)
      .required()
      .error(() => {
        return {
          message: '*您沒有輸入正確的密碼'
        };
      })
  };

  registerSchema = {
    name: Joi.string()
      .min(1)
      .max(16)
      .required()
      .error(() => {
        return {
          message: '*您的暱稱最少要有1個字，或最有16個字'
        };
      }),
    email: Joi.string()
      .email()
      .min(5)
      .max(50)
      .required()
      .error(() => {
        return {
          message: '*您沒有輸入正確的電郵地址，請重新輸入'
        };
      }),

    password: Joi.string()
      .regex(/^[a-zA-Z0-9!@#$%&*]{8,30}$/)
      .required()
      .error(() => {
        return {
          message: '*密碼最少8位，必須包括大小階及數字'
        };
      }),
    confirmedPassword: Joi.string()
      .regex(/^[a-zA-Z0-9!@#$%&*]{8,30}$/)
      .required()
      .error(() => {
        return {
          message: '*您輸入的密碼不正確，請核對與登入密碼是否想相同'
        };
      })
  };

  validate = currentLogMode => {
    const options = { abortEarly: false };
    let result;
    if (currentLogMode === 'login') {
      result = Joi.validate(this.state.login, this.loginSchema, options);
    } else if (currentLogMode === 'register') {
      result = Joi.validate(this.state.register, this.registerSchema, options);
    }
    if (!result.error)
      return { name: '', email: '', password: '', confirmedPassword: '' };

    let errors = {
      name: '',
      email: '',
      password: '',
      confirmedPassword: ''
    };
    for (let item of result.error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  handleChange = ({ currentTarget: input }) => {
    let articleEditorBlock = { ...this.state.articleEditorBlock };
    articleEditorBlock.article[input.name] = input.value;
    this.setState({ articleEditorBlock });
  };

  handleInsertTypeSelected = (type, position) => {
    let articleEditorBlock = { ...this.state.articleEditorBlock };

    let articleBlock = {
      componentType: type,
      content: {
        path: '',
        text: '',
        src: null,
        file: null
      }
    };

    articleEditorBlock.article.contentBlocks.splice(position, 0, articleBlock);

    this.setState({ articleEditorBlock });
  };

  handleSnippetChange = (
    { currentTarget: input },
    index,
    inputType,
    subType
  ) => {
    let articleEditorBlock = { ...this.state.articleEditorBlock };

    let block = articleEditorBlock.article.contentBlocks[index];

    if (inputType === 'text') {
      block.content.text = input.value;
    } else if (inputType === 'img') {
      if (!input.value) {
        block.content.src = null;
        return;
      }
      block.content.text = input.value.slice(12);
      block.content.src = URL.createObjectURL(input.files[0]);
      block.content.file = input.files[0];
    } else if (inputType === 'link') {
      if (subType === 'label') {
        block.content.text = input.value;
      } else if (subType === 'href') {
        block.content.path = input.value;
      }
    }

    this.setState({ articleEditorBlock });
  };

  handleSnippetDelete = index => {
    let articleEditorBlock = { ...this.state.articleEditorBlock };

    articleEditorBlock.article.contentBlocks.splice(index, 1);

    this.setState({ articleEditorBlock });
  };

  handleCoverImgUpload = e => {
    let articleEditorBlock = { ...this.state.articleEditorBlock };
    if (!e.target.value) {
      articleEditorBlock.temp.coverImgSrc = null;
      return;
    }
    articleEditorBlock.article.coverImgPath = e.target.value.slice(12);
    articleEditorBlock.temp.coverImgSrc = URL.createObjectURL(
      e.target.files[0]
    );
    articleEditorBlock.coverImgFile = e.target.files[0];

    this.setState({ articleEditorBlock });
  };

  handleCoverImgDelete = () => {
    let articleEditorBlock = { ...this.state.articleEditorBlock };

    articleEditorBlock.temp.coverImgSrc = '';
    articleEditorBlock.article.coverImgPath = '';
    articleEditorBlock.coverImgFile = null;

    this.setState({ articleEditorBlock });
  };

  handleLabelSelected = e => {
    let articleEditorBlock = { ...this.state.articleEditorBlock };
    articleEditorBlock.article.label = e.target.value;
    this.setState({ articleEditorBlock });
  };

  handleSelectedLang = (e, index) => {
    let articleEditorBlock = { ...this.state.articleEditorBlock };
    articleEditorBlock.article.contentBlocks[index].content.path =
      e.target.value;
    this.setState({ articleEditorBlock });
  };

  fetchArticleforEdit = async articleId => {
    const { apiEndpoint, account } = this.state;
    let articleEditorBlock = { ...this.state.articleEditorBlock };
    const { data: article } = await axios.get(
      `${apiEndpoint}/articles/${articleId}`
    );
    if (article.author.userId !== account.userId && !account.admin)
      this.props.history.push('/');

    articleEditorBlock.article = article;

    articleEditorBlock.article.contentBlocks.forEach(async block => {
      if (block.componentType === 'picture') {
        let file = await fetch(`/img/articles/${block.content.path}`)
          .then(r => r.blob())
          .then(blobFile => new File([blobFile], 'tempImage'), {
            type: 'image/jpg'
          });
        block.content.src = URL.createObjectURL(file);
        block.content.file = file;
      }
    });

    articleEditorBlock.update = true;
    articleEditorBlock.article.errors = {
      title: '',
      outline: '',
      contentBlocks: ''
    };

    console.log(articleEditorBlock);

    this.setState({ articleEditorBlock }, () =>
      console.log(this.state.articleEditorBlock)
    );
  };

  handleArticleSubmit = async () => {
    let articleEditorBlock = { ...this.state.articleEditorBlock };
    console.log(this.state.articleEditorBlock.article);
    const errors = this.validateArticle();
    articleEditorBlock.article.errors = errors;
    this.setState(articleEditorBlock, () =>
      console.log(this.state.articleEditorBlock.article.errors)
    );
    for (let err in errors) {
      if (errors[err] !== '') return customToast.success('文章遞交失敗');
    }

    const { apiEndpoint } = this.state;
    const version = articleEditorBlock.article.version;
    const jwt = localStorage.getItem('token');
    const accountToken = localStorage.getItem('account');
    const account = JSON.parse(accountToken);

    if (!jwt || !accountToken) {
      console.log('您未登入帳號');
    } else {
      try {
        const current = moment().unix();
        let decoded = jwtDecode(jwt);
        if (decoded.exp < current) {
          localStorage.removeItem('token');
          localStorage.removeItem('account');
          console.log('您未登入帳號');
        }
      } catch (ex) {
        customToast.success('您未登入帳號');
        console.log('您未登入帳號');
        console.log(ex);
      }
    }

    axios.defaults.headers.common['x-auth-token'] = jwt;

    if (!account.admin && !account.moderator)
      articleEditorBlock.article.version = 'community';

    if (!articleEditorBlock.update) {
      try {
        if (!articleEditorBlock.article.label) {
          if (articleEditorBlock.article.version === 'community') {
            articleEditorBlock.article.label = '綜合';
          } else if (articleEditorBlock.article.version === 'official') {
            articleEditorBlock.article.label = '主網';
          }
        }
        console.log('article before send', articleEditorBlock.article);
        const res = await axios.post(
          `${apiEndpoint}/articles/create`,
          articleEditorBlock.article
        );
        const articleId = res.data._id;

        if (articleEditorBlock.article.coverImgPath) {
          let coverImgFile = new FormData();
          coverImgFile.append('coverImgFile', articleEditorBlock.coverImgFile);
          const resCoverImg = await axios.post(
            `${apiEndpoint}/articles/create/img/cover/${articleId}`,
            coverImgFile
          );
          console.log('Cover Image Response:', resCoverImg);
        }

        const pictureBlocks = articleEditorBlock.article.contentBlocks.filter(
          block => block.componentType === 'picture'
        );

        if (pictureBlocks.length) {
          const pictures = pictureBlocks.map(picture => {
            return picture.content.file;
          });

          const pictureFiles = new FormData();
          for (let x = 0; x < pictures.length; x++) {
            pictureFiles.append('picture', pictures[x]);
          }

          const resPictures = await axios.post(
            `${apiEndpoint}/articles/create/img/${articleId}`,
            pictureFiles
          );

          if (res.status === 200 && resPictures.status === 200) {
            console.log('Pictures Uploaded');
            console.log('Article Uploaded:', res.data);
            const article = res.data;
            window.scrollTo(0, 0);
            this.props.history.push(`/article/${article._id}`);
            this.updateMyArticles();
            this.updateSnippetList(version, '最新');
            this.resetArticleEditorBlock();
          }
        } else if (res.status === 200) {
          const article = res.data;
          console.log('Article Uploaded:', article);
          window.scrollTo(0, 0);
          this.props.history.push(`/article/${article._id}`);
          this.updateMyArticles();
          this.updateSnippetList(version, '最新');
          this.resetArticleEditorBlock();
        }
      } catch (ex) {
        customToast.success('文章遞交失敗');
        console.log(ex);
      }
    } else if (articleEditorBlock.update) {
      const articleId = articleEditorBlock.article._id;

      try {
        const res = await axios.put(
          `${apiEndpoint}/articles/update/${articleId}`,
          articleEditorBlock.article
        );

        if (articleEditorBlock.temp.coverImgSrc) {
          const coverImgFile = new FormData();
          coverImgFile.append('coverImgFile', articleEditorBlock.coverImgFile);
          const resCoverImg = axios.post(
            `${apiEndpoint}/articles/create/img/cover/${articleId}`,
            coverImgFile
          );
          console.log('Cover Image Response:', resCoverImg);
        }

        const pictureBlocks = articleEditorBlock.article.contentBlocks.filter(
          block => block.componentType === 'picture'
        );

        if (pictureBlocks.length) {
          const pictures = pictureBlocks.map(picture => {
            return picture.content.file;
          });

          const pictureFiles = new FormData();
          for (let x = 0; x < pictures.length; x++) {
            pictureFiles.append('picture', pictures[x]);
          }

          const resPictures = await axios.post(
            `${apiEndpoint}/articles/create/img/${articleId}`,
            pictureFiles
          );

          if (res.status === 200 && resPictures.status === 200) {
            console.log('Pictures Uploaded');
            console.log('Article Uploaded:', res.data);
            const article = res.data;
            window.scrollTo(0, 0);
            this.props.history.push(`/article/${article._id}`);
            this.updateMyArticles();
            this.updateSnippetList(version, '最新');
            this.resetArticleEditorBlock();
          }
        } else if (res.status === 200) {
          const article = res.data;
          console.log('Article Updated', article);
          window.scrollTo(0, 0);
          this.props.history.push(`/article/${article._id}`);
          this.updateMyArticles();
          this.updateSnippetList(version, '最新');
          this.resetArticleEditorBlock();
        }
      } catch (ex) {
        customToast.success('文章遞交更新失敗');
        console.log(ex);
      }
    }
  };

  resetArticleEditorBlock = () => {
    const articleEditorBlock = {
      article: {
        _id: '',
        version: '',
        title: '',
        label: '',
        coverImg: null,
        coverImgPath: '',
        contentBlocks: [],
        outline: '',
        loves: 0,
        errors: { title: '', outline: '' }
      },
      temp: {
        coverImgSrc: null
      },
      coverImgFile: null,
      currentTime: currentTime,
      update: false
    };
    this.setState({ articleEditorBlock });
    window.scrollTo(0, 0);
  };

  handleNavButtonSubclassChange = (method, subclassIndex) => {
    let navPanel = { ...this.state.navPanel };
    if (method === 'insert')
      navPanel.subclasses.splice(subclassIndex, 0, {
        subclass: '',
        path: '',
        buttons: []
      });
    else if (method === 'push') {
      navPanel.subclasses.push({ subclass: '', path: '', buttons: [] });
    } else if (method === 'remove') {
      navPanel.subclasses.splice(subclassIndex, 1);
    }
    this.setState(navPanel);
  };

  fetchNavContent = async id => {
    const { apiEndpoint } = this.state;
    const { data: navContent } = await axios.get(
      `${apiEndpoint}/navigator/${id}`
    );
    this.setState({ navContent });
  };

  handleNavIconSelected = async (btn, subclass) => {
    window.scrollTo(0, 0);
    let navContent = {
      title: '',
      iconRef: '',
      blocks: []
    };

    if (!btn.contentRef) {
      this.setState({ navContent }, () => {
        navContent.iconRef = btn._id;
        navContent.subclass = subclass;
        this.setState({ navContent });
      });
    } else if (btn.contentRef) {
      this.setState({ navContent }, () => {
        this.fetchNavContent(btn.contentRef);
      });
    }
  };

  handleNavIconChange = (method, subclassIndex, iconIndex) => {
    let navPanel = { ...this.state.navPanel };
    let emptyIcon = {
      title: '',
      imgPath: '',
      contentRef: '',
      src: '',
      file: null
    };

    if (method === 'push') {
      navPanel.subclasses[subclassIndex].buttons.push(emptyIcon);
    } else if (method === 'insert') {
      navPanel.subclasses[subclassIndex].buttons.splice(
        iconIndex,
        0,
        emptyIcon
      );
    } else if (method === 'remove') {
      navPanel.subclasses[subclassIndex].buttons.splice(iconIndex, 1);
    }
    this.setState(navPanel);
  };

  handleNavPanelInputChange = (e, type, subclassIndex, iconIndex) => {
    let navPanel = { ...this.state.navPanel };

    if (iconIndex === null) {
      navPanel.subclasses[subclassIndex][type] = e.target.value;
    } else if (iconIndex === 0 || iconIndex) {
      if (type === 'title') {
        navPanel.subclasses[subclassIndex].buttons[iconIndex][type] =
          e.target.value;
      } else if (type === 'img') {
        if (!e.target.value) {
          navPanel.subclasses[subclassIndex].buttons[iconIndex].src = '';
          navPanel.subclasses[subclassIndex].buttons[iconIndex].file = null;
          return this.setState({ navPanel });
        }
        navPanel.subclasses[subclassIndex].buttons[
          iconIndex
        ].imgPath = e.target.value.slice(12);
        navPanel.subclasses[subclassIndex].buttons[
          iconIndex
        ].src = URL.createObjectURL(e.target.files[0]);
        navPanel.subclasses[subclassIndex].buttons[iconIndex].file =
          e.target.files[0];
      }
    }
    this.setState({ navPanel });
  };

  handleButtonListSubmit = async mainClass => {
    const { apiEndpoint } = this.state;
    let btnListB4Send = { ...this.state[mainClass], class: mainClass };
    try {
      const { data: btnListAfterSend } = await axios.put(
        `${apiEndpoint}/accessories/buttonList`,
        btnListB4Send
      );

      for (let i = 0; i < btnListB4Send.subclasses.length; i++) {
        const subclassImages = btnListB4Send.subclasses[i].buttons.map(
          button => {
            return button.file;
          }
        );

        for (let x = 0; x < subclassImages.length; x++) {
          if (subclassImages[x]) {
            const subclassImageFile = new FormData();
            subclassImageFile.append('img', subclassImages[x]);
            const resImg = await axios.put(
              `${apiEndpoint}/accessories/buttonList/img/${mainClass}/${btnListAfterSend.subclasses[i].buttons[x]._id}`,
              subclassImageFile
            );
            console.log(btnListAfterSend.subclasses[i].buttons[x]._id);
            console.log(subclassImages[x]);
            if (resImg.status === 200) console.log('image uploaded');
          }
        }
      }

      this.setState({ [mainClass]: btnListAfterSend }, () => {
        console.log(this.state[mainClass]);
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  handleBlockInputChange = (
    { currentTarget: input },
    type,
    blockIndex,
    subBlockIndex,
    iconIndex
  ) => {
    let navContent = { ...this.state.navContent };
    if (type === 'title') {
      navContent.title = input.value;
    } else if (type === 'text') {
      navContent.blocks[blockIndex].content = input.value;
    } else if (type === 'link') {
      navContent.blocks[blockIndex][input.name] = input.value;
    } else if (type === 'img') {
      if (!input.value) {
        navContent.blocks[blockIndex].src = '';
        navContent.blocks[blockIndex].file = null;
        return this.setState({ navContent });
      }
      navContent.blocks[blockIndex].src = input.value.slice(12);
      navContent.blocks[blockIndex].file = input.files[0];
      navContent.blocks[blockIndex].url = URL.createObjectURL(input.files[0]);
    } else if (type === 'textSubBlock') {
      navContent.blocks[blockIndex].subBlocks[subBlockIndex].content =
        input.value;
    } else if (type === 'linkSubBlock') {
      navContent.blocks[blockIndex].subBlocks[subBlockIndex][input.name] =
        input.value;
    } else if (type === 'contactUrl') {
      navContent.blocks[blockIndex].contactBar[iconIndex][input.name] =
        input.value;
    }
    this.setState({ navContent });
  };

  handleBlockTypeChange = (index, method, blockType, onInsertBarSwitch) => {
    let navContent = { ...this.state.navContent };

    if (method === 'push' || method === 'insert') {
      let navContentBlock = {
        blockType,
        label: '',
        content: '',
        url: '',
        src: '',
        file: null,
        subBlocks: [],
        contactBar: []
      };

      if (method === 'push') {
        navContent.blocks.push(navContentBlock);
      }
      if (method === 'insert') {
        navContent.blocks.splice(index, 0, navContentBlock);
        onInsertBarSwitch(false);
      }
    } else if (method === 'remove') {
      navContent.blocks.splice(index, 1);
    }

    this.setState({ navContent }, () => {
      console.log(this.state.navContent.blocks);
    });
  };

  handleSubBlockTypeChange = (
    blockIndex,
    subBlockIndex,
    method,
    subBlockType,
    onInsertBarSwitch
  ) => {
    let navContent = { ...this.state.navContent };

    if (method === 'push' || method === 'insert') {
      let subBlock = {
        subBlockType,
        label: '',
        content: '',
        url: ''
      };

      if (method === 'push') {
        navContent.blocks[blockIndex].subBlocks.push(subBlock);
      } else if (method === 'insert') {
        navContent.blocks[blockIndex].subBlocks.splice(
          subBlockIndex,
          0,
          subBlock
        );
        onInsertBarSwitch(false);
      }
    }
    if (method === 'remove') {
      navContent.blocks[blockIndex].subBlocks.splice(subBlockIndex, 1);
    }
    this.setState({ navContent }, () => {
      console.log(this.state.navContent);
    });
  };

  handleContactUrlChange = (
    blockIndex,
    iconIndex,
    method,
    media,
    onInsertBarSwitch
  ) => {
    let navContent = { ...this.state.navContent };

    if (method === 'push' || method === 'insert') {
      let contactUrlIcon = {
        media,
        label: '',
        url: ''
      };
      if (method === 'push') {
        navContent.blocks[blockIndex].contactBar.push(contactUrlIcon);
      } else if (method === 'insert') {
        navContent.blocks[blockIndex].contactBar.splice(
          iconIndex,
          0,
          contactUrlIcon
        );
        onInsertBarSwitch(false);
      }
    } else if (method === 'remove') {
      console.log('remove Icon');
      navContent.blocks[blockIndex].contactBar.splice(iconIndex, 1);
    }

    this.setState({ navContent }, () => {
      console.log(this.state.navContent.blocks[blockIndex]);
    });
  };

  handleNavContentSubmit = async id => {
    const { apiEndpoint, navContent } = this.state;
    let res = {};
    try {
      if (!id) {
        for (let i = 0; i < navContent.blocks.length; i++) {
          if (navContent.blocks[i].blockType === 'recommendSnippet') {
            navContent.blocks[i].url = '';
          }
        }
        res = await axios.post(`${apiEndpoint}/navigator/create`, navContent);
        if (res.status === 200) {
          const { navPanel, navContent } = res.data;

          for (let i = 0; i < navContent.blocks.length; i++) {
            if (navContent.blocks[i].blockType === 'recommendSnippet') {
              console.log('img found!');
            }
          }

          this.setState({ navPanel, navContent });

          this.props.history.push(`/nav/${navContent._id}`);
        }
      } else if (id) {
        for (let i = 0; i < navContent.blocks.length; i++) {
          if (navContent.blocks[i].blockType === 'recommendSnippet') {
            navContent.blocks[i].url = '';
          }
        }
        res = await axios.put(`${apiEndpoint}/navigator/${id}`, navContent);
        if (res.status === 200) {
          const updatedNavContent = res.data;

          for (let i = 0; i < navContent.blocks.length; i++) {
            if (
              navContent.blocks[i].blockType === 'recommendSnippet' &&
              navContent.blocks[i].file
            ) {
              let iconFile = new FormData();
              iconFile.append('icon', navContent.blocks[i].file);
              const resIcon = await axios.put(
                `${apiEndpoint}/navigator/img/${updatedNavContent.blocks[i]._id}`,
                iconFile
              );
              if (resIcon === 200) {
                console.log('icon uploaded!');
              }
            }
          }

          console.log(updatedNavContent);
          this.setState({ navContent: updatedNavContent }, () => {
            customToast.success('導航內容更新成功');
          });
        }
      }
    } catch (ex) {
      customToast.success('導航內容更新失敗');
      console.log(ex);
    }
  };

  handleCoveredBannerInputChange = (e, thumb, type, position) => {
    let coveredBanners = [];
    if (thumb.version === 'official')
      coveredBanners = [...this.state.officialCoveredBanners];
    if (thumb.version === 'community')
      coveredBanners = [...this.state.communityCoveredBanners];

    if (type === 'text') {
      coveredBanners[position].content = e.target.value;
    } else if (type === 'img') {
      if (!e.target.value) {
        coveredBanners[position].file = null;
      }
      coveredBanners[position].file = e.target.files[0];
      coveredBanners[position].temp = URL.createObjectURL(e.target.files[0]);
    } else if (type === 'link') {
      coveredBanners[position].link = e.target.value;
      if (coveredBanners[position].link.includes('http')) {
        coveredBanners[position].linkType = 'external';
      } else {
        coveredBanners[position].linkType = 'internal';
      }
    }
    this.setState({ coveredBanners }, () =>
      console.log(this.state.coveredBanners)
    );
  };

  handleCoveredBannerSubmit = async (thumb, version, index) => {
    let coveredBanners = [];
    if (version === 'official')
      coveredBanners = [...this.state.officialCoveredBanners];
    if (version === 'community')
      coveredBanners = [...this.state.communityCoveredBanners];
    let res = {};
    const { apiEndpoint } = this.state;
    if (!thumb._id) {
      thumb.version = version;
      res = await axios.post(
        `${apiEndpoint}/accessories/coveredbanner/create`,
        thumb
      );
    } else {
      res = await axios.put(
        `${apiEndpoint}/accessories/coveredbanner/${thumb._id}`,
        thumb
      );
    }
    if (res.status === 200) {
      const coveredBanner = res.data;
      if (thumb.file) {
        let coveredBannerFile = new FormData();
        coveredBannerFile.append('coveredBannerFile', thumb.file);

        const resImg = await axios.put(
          `${apiEndpoint}/accessories/coveredbanner/img/${coveredBanner._id}`,
          coveredBannerFile
        );

        if (resImg.status === 200) console.log('Banner Uploaded!');
      }

      coveredBanners[index] = coveredBanner;
      console.log(coveredBanners);
      this.setState({ coveredBanners }, () => {
        customToast.success('您的海報已儲存');
      });
    }
  };

  handleAccountSearchChange = e => {
    let accountSearch = { ...this.state.accountSearch };
    accountSearch[e.target.name] = e.target.value;
    this.setState({ accountSearch });
  };

  handleAccountSearchSubmit = async () => {
    const jwt = localStorage.getItem('token');
    axios.defaults.headers.common['x-auth-token'] = jwt;
    const { apiEndpoint, accountSearch } = this.state;
    const resSearch = await axios.post(`${apiEndpoint}`, accountSearch);
    if (resSearch.status === 200) {
      const { data } = resSearch;
      console.log(data);
      const accountSearch = {
        userIdSearch: '',
        nameSearch: '',
        emailSearch: '',
        timeSearch: ''
      };
      this.setState({ accountSearch });
    }
  };

  classifyAndUpdateNewsList = newsList => {
    const infoList = newsList.filter(news => news.newsType === 'info');
    const noticeList = newsList.filter(news => news.newsType === 'notice');
    const meetupList = newsList.filter(news => news.newsType === 'meetup');
    this.setState({ infoList, noticeList, meetupList });
  };

  handleNewsChange = (e, type) => {
    if (type === 'new') {
      let { newsInput } = this.state;
      newsInput[e.target.name] = e.target.value;
      this.setState({ newsInput });
    }
  };

  handleNewsSubmit = async (newsType, link) => {
    const jwt = localStorage.getItem('token');
    if (!jwt) return;
    axios.defaults.headers.common['x-auth-token'] = jwt;
    const { newsInput, apiEndpoint } = this.state;
    const sendNewsInput = {
      newsType: newsType,
      message: newsInput[newsType],
      link: newsInput[link]
    };

    let resNewsList;

    resNewsList = await axios.post(
      `${apiEndpoint}/accessories/news/add`,
      sendNewsInput
    );

    if (resNewsList.status === 200) {
      const { data: newsList } = resNewsList;
      this.classifyAndUpdateNewsList(newsList);
      const newsInput = {
        info: '',
        infoLink: '',
        notice: '',
        noticeLink: '',
        meetup: '',
        meetupLink: ''
      };
      this.setState({ newsInput });
    }
  };

  handleNewsUpdateChange = (e, index, type) => {
    const { infoList, noticeList, meetupList } = this.state;
    if (type === 'info') {
      infoList[index][e.target.name] = e.target.value;
      this.setState({ infoList });
    } else if (type === 'notice') {
      noticeList[index][e.target.name] = e.target.value;
      this.setState({ noticeList });
    } else if (type === 'meetup') {
      meetupList[index][e.target.name] = e.target.value;
    }
  };

  handleNewsUpdate = async (action, _id, type) => {
    const { apiEndpoint, infoList, noticeList, meetupList } = this.state;
    const jwt = localStorage.getItem('token');
    axios.defaults.headers.common['x-auth-token'] = jwt;

    let newsList;
    if (action === 'remove') {
      const resNewsList = await axios.delete(
        `${apiEndpoint}/accessories/news/remove/${_id}`
      );
      if (resNewsList.status === 200) {
        newsList = resNewsList.data;
        this.classifyAndUpdateNewsList(newsList);
        customToast.success('消息已删除');
      }
    } else if (action === 'save') {
      let resNewsList;
      if (type === 'info') {
        const index = infoList.findIndex(info => info._id === _id);

        resNewsList = await axios.put(
          `${apiEndpoint}/accessories/news/update/${_id}`,
          infoList[index]
        );
      } else if (type === 'notice') {
        const index = noticeList.findIndex(notice => notice._id === _id);
        resNewsList = await axios.put(
          `${apiEndpoint}/accessories/news/update/${_id}`,
          noticeList[index]
        );
      } else if (type === 'meetup') {
        const index = meetupList.findIndex(meetup => meetup._id === _id);
        resNewsList = await axios.put(
          `${apiEndpoint}/accessories/news/update/${_id}`,
          meetupList[index]
        );
      }
      if (resNewsList.status === 200) {
        newsList = resNewsList.data;
        this.classifyAndUpdateNewsList(newsList);
        customToast.success('消息已儲存');
      }
    } else if (action === 'publish') {
      const resNewsList = await axios.put(
        `${apiEndpoint}/accessories/news/publish/${_id}`
      );
      if (resNewsList.status === 200) {
        newsList = resNewsList.data;
        this.classifyAndUpdateNewsList(newsList);
      }
    }
  };

  fetchMoreSnippets = version => {
    const { apiEndpoint, articleTypeLabel } = this.state;
    const snippetLists = { ...this.state.snippetLists };
    let number = snippetLists[version][articleTypeLabel] + 20;

    snippetLists[version][articleTypeLabel] = number;
    this.setState({ snippetLists }, async () => {
      const resSnippetList = await axios.get(
        `${apiEndpoint}/articles/snippets/fetch/${version}/${articleTypeLabel}/${number}`
      );

      if (version === 'official') {
        const { data: officialSnippetList } = resSnippetList;
        this.setState({ officialSnippetList });
      } else if (version === 'community') {
        const { data: communitySnippetList } = resSnippetList;
        this.setState({ communitySnippetList });
      }
    });
  };

  handlePanelSelect = (label, side) => {
    const panel = { ...this.state.panel };
    panel[side].active = label;
    this.setState({ panel }, () => {
      localStorage.setItem('panel', JSON.stringify(panel));
    });
  };

  handlePanelExpand = (dynamicPanel, side) => {
    const panel = { ...this.state.panel };
    panel[side][dynamicPanel] = !panel[side][dynamicPanel];
    this.setState({ panel }, () => {
      localStorage.setItem('panel', JSON.stringify(panel));
    });
  };

  handleConfirmationResend = async () => {
    const { endpoint } = this.state;
    const jwt = localStorage.getItem('token');
    axios.defaults.headers.common['x-auth-token'] = jwt;
    try {
      const res = await axios.post(`${endpoint}/confirmation/resend`);

      if (res.status === 200) {
        console.log(res.data);

        this.setState({
          currentLogMode: 'login',
          account: {
            name: '',
            userId: '',
            avatar: '',
            intro: '',
            eosAccount: '',
            telosAccount: '',
            bosAccount: '',
            worbliAccount: '',
            waxAccount: '',
            meetOneAccount: '',
            lynxAccount: ''
          }
        });
        localStorage.removeItem('token');
        this.props.history.push('/account/email-confirmation-resend');
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  render() {
    const {
      panel,
      account,
      officialSnippetList,
      communitySnippetList,
      articleEditorBlock,
      articleTypeLabel,
      market,
      prevMarket,
      eosMarket,
      prevEosMarket,
      forkMarket,
      prevForkMarket,
      fiatMarket,
      prevFiatMarket,
      currentLeftTab,
      officialCoveredBanners,
      communityCoveredBanners,
      currentBanner,
      currentLogMode,
      login,
      register,
      updatedAccount,
      articleBlock,
      message,
      chatCollection,
      myArticles,
      keepMeLoggedIn,
      navPanel,
      navContent,
      endpoint,
      apiEndpoint,
      eoscityStatistic,
      infoList,
      noticeList,
      meetupList,
      newsInput,
      wrapperHeight,
      errors
    } = this.state;
    return (
      <React.Fragment>
        <div id='mobile'>
          <div className='mobile-content-container'>
            <div className='mobile-coverLogo-container'>
              <img
                className='mobile-coverLogo'
                src='/img/header/eosCity_community_logo.svg'
                alt='eosCity.io 2.1'
              />
            </div>
            <br />
            <div className='mobile-slogan'>
              柚子市 2.1 桌面版現已上線!
              <br />
              新增即時聊天及社區發文功能，馬上用 PC 登入 eosCity.io 試玩!
              <br />
              <br />
              WAP 版正努力更新中暫停開放，不便之處，敬請原諒
            </div>
          </div>
        </div>
        <div id='desktop'>
          <ToastContainer />
          <Eyebrow fiatMarket={fiatMarket} prevFiatMarket={prevFiatMarket} />
          <Header
            account={account}
            articleBlock={articleBlock}
            articleEditorBlock={articleEditorBlock}
            onArticleEditVersionChange={this.articleEditVersionChange}
            onLoggedOut={this.handleLoggedOut}
            onLoginPanelChange={this.handleLoginPanelChange}
          />
          <Switch>
            <Route
              path='/article/editor/new/:mode'
              exact
              render={props => (
                <ArticleEditor
                  account={account}
                  onArticleEditVersionChange={this.articleEditVersionChange}
                  articleEditorBlock={articleEditorBlock}
                  currentTime={currentTime}
                  currentTimeS={currentTimeS}
                  onChange={this.handleChange}
                  onInsertTypeSelected={this.handleInsertTypeSelected}
                  onSnippetChange={this.handleSnippetChange}
                  onSnippetDelete={this.handleSnippetDelete}
                  onCoverImgUpload={this.handleCoverImgUpload}
                  onCoverImgDelete={this.handleCoverImgDelete}
                  onSelectedLang={this.handleSelectedLang}
                  onArticleSubmit={this.handleArticleSubmit}
                  onLabelSelected={this.handleLabelSelected}
                  {...props}
                />
              )}
            />
            <Route
              path='/article/editor/update/:id'
              exact
              render={props => (
                <ArticleEditor
                  account={account}
                  onArticleEditVersionChange={this.articleEditVersionChange}
                  onFetchArticleForEdit={this.fetchArticleforEdit}
                  articleEditorBlock={articleEditorBlock}
                  currentTime={currentTime}
                  currentTimeS={currentTimeS}
                  onChange={this.handleChange}
                  onInsertTypeSelected={this.handleInsertTypeSelected}
                  onSnippetChange={this.handleSnippetChange}
                  onSnippetDelete={this.handleSnippetDelete}
                  onCoverImgUpload={this.handleCoverImgUpload}
                  onCoverImgDelete={this.handleCoverImgDelete}
                  onSelectedLang={this.handleSelectedLang}
                  onArticleSubmit={this.handleArticleSubmit}
                  onLabelSelected={this.handleLabelSelected}
                  {...props}
                />
              )}
            />

            <Route
              path={['/', '/nav', '/article']}
              render={props => (
                <Home
                  panel={panel}
                  account={account}
                  officialSnippetList={officialSnippetList}
                  communitySnippetList={communitySnippetList}
                  keepMeLoggedIn={keepMeLoggedIn}
                  market={market}
                  prevMarket={prevMarket}
                  eosMarket={eosMarket}
                  prevEosMarket={prevEosMarket}
                  fiatMarket={fiatMarket}
                  prevFiatMarket={prevFiatMarket}
                  forkMarket={forkMarket}
                  prevForkMarket={prevForkMarket}
                  currentLeftTab={currentLeftTab}
                  updatedAccount={updatedAccount}
                  currentLogMode={currentLogMode}
                  articleBlock={articleBlock}
                  officialCoveredBanners={officialCoveredBanners}
                  communityCoveredBanners={communityCoveredBanners}
                  currentBanner={currentBanner}
                  articleTypeLabel={articleTypeLabel}
                  login={login}
                  register={register}
                  message={message}
                  chatCollection={chatCollection}
                  myArticles={myArticles}
                  navPanel={navPanel}
                  navContent={navContent}
                  endpoint={endpoint}
                  apiEndpoint={apiEndpoint}
                  wrapperHeight={wrapperHeight}
                  eoscityStatistic={eoscityStatistic}
                  newsInput={newsInput}
                  infoList={infoList}
                  noticeList={noticeList}
                  meetupList={meetupList}
                  onArticleSelected={this.handleArticleSelected}
                  onUpdateSuccess={this.handleUpdateSuccess}
                  onLove={this.handleLove}
                  onReply={this.handleReply}
                  onReplySubmit={this.handleReplySubmit}
                  onEmbeddedReply={this.handleEmbeddedReply}
                  openEmbeddedReplyInput={this.openEmbeddedReplyInput}
                  onEmbeddedReplySubmit={this.handleEmbeddedReplySubmit}
                  openEmbeddedReplyMessages={this.openEmbeddedReplyMessages}
                  onArticleDelete={this.handleArticleDelete}
                  onUpdateSnippetList={this.updateSnippetList}
                  onThumbHover={this.handleThumbHover}
                  onLoggedOut={this.handleLoggedOut}
                  onChangeLogMode={this.handleChangeLogMode}
                  onLogin={this.handleLogin}
                  onInputChange={this.handleInputChange}
                  onLoginPanelChange={this.handleLoginPanelChange}
                  onRegistrationSubmit={this.handleRegistrationSubmit}
                  onMessageChange={this.onMessageChange}
                  onSendMessage={this.onSendMessage}
                  onKeepMeLoggedIn={this.onKeepMeLoggedIn}
                  onCalcRightHeight={this.calcRightHeight}
                  onCalcComponentHeight={this.calcComponentHeight}
                  onNavButtonSubclassChange={this.handleNavButtonSubclassChange}
                  onNavIconSelected={this.handleNavIconSelected}
                  onNavIconChange={this.handleNavIconChange}
                  onNavPanelInputChange={this.handleNavPanelInputChange}
                  onButtonListSubmit={this.handleButtonListSubmit}
                  onBlockInputChange={this.handleBlockInputChange}
                  onBlockTypeChange={this.handleBlockTypeChange}
                  onSubBlockTypeChange={this.handleSubBlockTypeChange}
                  onContactUrlChange={this.handleContactUrlChange}
                  onNavContentSubmit={this.handleNavContentSubmit}
                  onFetchNavContent={this.fetchNavContent}
                  onCoveredBannerInputChange={
                    this.handleCoveredBannerInputChange
                  }
                  onCoveredBannerSubmit={this.handleCoveredBannerSubmit}
                  onNewsChange={this.handleNewsChange}
                  onNewsSubmit={this.handleNewsSubmit}
                  onNewsUpdate={this.handleNewsUpdate}
                  onNewsUpdateChange={this.handleNewsUpdateChange}
                  onResetArticleEditorBlock={this.resetArticleEditorBlock}
                  onFetchMoreSnippets={this.fetchMoreSnippets}
                  onPanelSelect={this.handlePanelSelect}
                  onPanelExpand={this.handlePanelExpand}
                  onConfirmationResend={this.handleConfirmationResend}
                  onFetchArticleForEdit={this.fetchArticleforEdit}
                  onResetArticleBlock={this.handleResetArticleBlock}
                  errors={errors}
                  {...props}
                />
              )}
            />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
