import React, { Component } from 'react';
import SnippetList from './components/snippetList';
import BannersAndThumbnails from './components/bannersAndThumbnails';
import '../../css/community.css';

class Community extends Component {
  state = {
    version: 'community',
    articleTypeBtns: [
      '最新',
      '綜合',
      '政治',
      '經濟',
      '工業',
      '農業',
      '商業',
      '科技',
      '生活',
      '消閑'
    ],
    sticky: -600
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  time = Date.now();
  fetchTime = Date.now();

  handleScroll = () => {
    const height = this.middleContainer.clientHeight;
    const windowHeight = window.innerHeight;

    if (this.time + 250 - Date.now() < 0) {
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, 'middleHeight');
      this.time = Date.now();
    }

    if (windowHeight + document.documentElement.scrollTop - 155 > height) {
      if (this.fetchTime + 2500 - Date.now() < 0) {
        this.props.onFetchMoreSnippets('community');
        this.fetchTime = Date.now();
      }
    }
  };

  render() {
    const { version, articleTypeBtns, sticky } = this.state;
    let {
      account,
      coveredBanners,
      currentBanner,
      communitySnippetList,
      onUpdateSnippetList,
      articleTypeLabel,
      onThumbHover,
      onCoveredBannerInputChange,
      onCoveredBannerSubmit
    } = this.props;
    return (
      <div
        className='middle-community-container'
        style={{
          position: 'sticky',
          top: sticky
        }}
        ref={middleContainer => {
          this.middleContainer = middleContainer;
        }}
      >
        <BannersAndThumbnails
          account={account}
          coveredBanners={coveredBanners}
          currentBanner={currentBanner}
          onThumbHover={onThumbHover}
          version={version}
          onCoveredBannerInputChange={onCoveredBannerInputChange}
          onCoveredBannerSubmit={onCoveredBannerSubmit}
        />

        <div className='middle-community-article-type-container'>
          {articleTypeBtns.map((btn, index) => (
            <div
              key={index}
              className={articleTypeLabel === btn ? 'type active' : 'type'}
              onClick={() => {
                onUpdateSnippetList('community', btn);
              }}
            >
              {btn}
            </div>
          ))}
        </div>

        <SnippetList snippetList={communitySnippetList} />
      </div>
    );
  }
}

export default Community;
