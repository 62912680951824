import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import customToast from '../../../../../../utils/customToast';
import './css/articleManager.css';

class ArticleManager extends Component {
  state = {
    officialArticleList: [],
    communityArticleList: [],
    articleSearch: {
      titleSearch: '',
      articleType: '全部',
      outlineSearch: '',
      authorSearch: '',
      userIdSearch: '',
      dateSearch: ''
    },
    sticky: 0
  };

  classifyAndUpdateList = articleList => {
    const communityArticleList = articleList.filter(
      article => article.version === 'community'
    );

    const officialArticleList = articleList.filter(
      article => article.version === 'official'
    );

    this.setState({ officialArticleList, communityArticleList });
  };

  async componentDidMount() {
    window.addEventListener('scroll', this.throttle);

    const accountToken = localStorage.getItem('account');
    const localAccount = JSON.parse(accountToken);
    if (!localAccount.admin) this.props.history.push('/');

    const { apiEndpoint } = this.props;

    const jwt = localStorage.getItem('token');
    axios.defaults.headers.common['x-auth-token'] = jwt;
    const { data: articleList } = await axios.get(
      `${apiEndpoint}/articles/edit`
    );

    this.classifyAndUpdateList(articleList);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttle);
  }

  time = Date.now();
  throttle = () => {
    if (this.time + 250 - Date.now() < 0) {
      const height = this.middleContainer.clientHeight;
      const windowHeight = window.innerHeight;
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, 'middleHeight');
      this.time = Date.now();
    }
  };

  publishArticle = async (articleId, action) => {
    const { apiEndpoint } = this.props;

    const jwt = localStorage.getItem('token');
    axios.defaults.headers.common['x-auth-token'] = jwt;

    action = { action };

    try {
      const res = await axios.put(
        `${apiEndpoint}/articles/publish/${articleId}`,
        action
      );

      if (res.status === 200) {
        if (action.action === 'close') {
          customToast.success('文章已收起');
        } else if (action.action === 'publish') {
          customToast.success('文章已發佈');
        }
        const { data: articleList } = res;
        this.classifyAndUpdateList(articleList);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  adminRemoveArticle = async articleId => {
    const { apiEndpoint } = this.props;
    const jwt = localStorage.getItem('token');
    if (!jwt) return;
    axios.defaults.headers.common['x-auth-token'] = jwt;

    try {
      const res = await axios.delete(
        `${apiEndpoint}/articles/admin/remove/${articleId}`
      );
      if (res.status === 200) {
        const { data: articleList } = res;

        const communityArticleList = articleList.filter(
          article => article.version === 'community'
        );

        const officialArticleList = articleList.filter(
          article => article.version === 'official'
        );

        this.setState({ officialArticleList, communityArticleList }, () => {
          customToast.success('文章已删除');
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  articleSearchChange = e => {
    let articleSearch = { ...this.state.articleSearch };
    articleSearch[e.target.name] = e.target.value;
    this.setState({ articleSearch });
  };

  articleSearchSubmit = async () => {
    const jwt = localStorage.getItem('token');
    axios.defaults.headers.common['x-auth-token'] = jwt;
    let { articleSearch } = this.state;
    const { apiEndpoint } = this.props;
    const resSearch = await axios.post(
      `${apiEndpoint}/articles/search`,
      articleSearch
    );
    if (resSearch.status === 200) {
      const { data: articleList } = resSearch;

      articleSearch = {
        titleSearch: '',
        articleType: articleSearch.articleType,
        outlineSearch: '',
        authorSearch: '',
        userIdSearch: '',
        dateSearch: ''
      };
      this.setState({ articleSearch });

      this.classifyAndUpdateList(articleList);
    }
  };

  render() {
    const {
      officialArticleList,
      communityArticleList,
      articleSearch
    } = this.state;

    return (
      <div
        className='articleManager-container'
        ref={middleContainer => {
          this.middleContainer = middleContainer;
        }}
        style={{ position: 'sticky', top: this.state.sticky }}
      >
        <div className='accountSnippetTag'>文章管理員</div>
        <div className='articleManager-search-panel'>
          <div className='articleManage-search-cell'>標題搜尋</div>
          <div className='articleManager-search-input-cell'>
            <input
              name='titleSearch'
              className='articleManager-search-input'
              onChange={e => this.articleSearchChange(e)}
              value={articleSearch.titleSearch}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  this.articleSearchSubmit();
                }
              }}
            />
          </div>
          <div className='articleManage-search-cell'>文章類型</div>
          <div className='articleManager-search-input-cell'>
            <select
              name='articleType'
              className='articleManager-label'
              onChange={e => this.articleSearchChange(e)}
            >
              <option className='articleManager-label-option' value='全部'>
                全部
              </option>
              <option value='主網'>主網</option>
              <option value='遊戲'>遊戲</option>
              <option value='側鏈'>側鏈</option>
              <option value='DAPPS'>DAPPS</option>
              <option value='社區'>社區</option>
              <option value='BP'>BP</option>
              <option value='教程'>教程</option>
              <option value='綜合'>綜合</option>
              <option value='政治'>政治</option>
              <option value='經濟'>經濟</option>
              <option value='工業'>工業</option>
              <option value='農業'>農業</option>
              <option value='商業'>商業</option>
              <option value='科技'>科技</option>
              <option value='生活'>生活</option>
              <option value='消閑'>消閑</option>
            </select>
          </div>
          <div className='articleManage-search-cell'>摘錄搜尋</div>
          <div className='articleManager-search-input-cell'>
            <input
              name='outlineSearch'
              className='articleManager-search-input'
              onChange={e => this.articleSearchChange(e)}
              value={articleSearch.outlineSearch}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  this.articleSearchSubmit();
                }
              }}
            />
          </div>

          <div className='articleManage-search-cell'>作者搜尋</div>
          <div className='articleManager-search-input-cell'>
            <input
              name='authorSearch'
              className='articleManager-search-input'
              onChange={e => this.articleSearchChange(e)}
              value={articleSearch.authorSearch}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  this.articleSearchSubmit();
                }
              }}
            />
          </div>
          <div className='articleManage-search-cell'>id 搜尋</div>
          <div className='articleManager-search-input-cell'>
            <input
              name='userIdSearch'
              className='articleManager-search-input'
              onChange={e => this.articleSearchChange(e)}
              value={articleSearch.userIdSearch}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  this.articleSearchSubmit();
                }
              }}
            />
          </div>
          <div className='articleManage-search-cell'>日期搜尋</div>
          <div className='articleManager-search-input-cell'>
            <input
              name='dateSearch'
              className='articleManager-search-input'
              onChange={e => this.articleSearchChange(e)}
              value={articleSearch.dateSearch}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  this.articleSearchSubmit();
                }
              }}
            />
          </div>

          <div></div>
          <div></div>

          <div></div>
          <div></div>

          <div></div>
        </div>
        <div className='articleManager-search-bar'>
          <div
            className='articleManager-search-btn'
            onClick={() => this.articleSearchSubmit()}
          >
            搜尋
          </div>
        </div>

        <div className='articleManager-list-container'>
          <div className='articleManager-list-inner-container'>
            <div className='articleManager-list-inner-title'>官方版</div>
            {officialArticleList.length === 0 ? (
              <div className='articleManager-list-slogan'>
                官方版找不到符合條件的搜尋結果
              </div>
            ) : null}
            {officialArticleList.map((article, index) => (
              <div
                key={index}
                className='articleManager-list-snippet-container'
              >
                <div className='articleManager-list-snippet-upper-container'>
                  {article.coverImgPath ? (
                    <div className='articleManager-list-snippet-img-container'>
                      <img
                        className='articleManager-list-snippet-img'
                        src={`/img/articles/${article._id}-cover.jpg`}
                        alt=''
                      />
                    </div>
                  ) : null}

                  <div className='articleManager-list-snippet-description-container'>
                    <div className='articleManager-list-snippet-row'>
                      <Link
                        to={`/article/${article._id}`}
                        className='articleManager-list-snippet-link'
                      >
                        {article.title}
                      </Link>
                    </div>

                    <div className='articleManager-list-snippet-row author'>
                      <div>
                        <span style={{ color: '#cc0066' }}>
                          id {article.author.userId}
                        </span>{' '}
                        &nbsp;
                        {article.author.name}
                      </div>
                      <div>戦闘力 {article.author.powerLevel} </div>
                    </div>
                  </div>
                </div>
                <div className='articleManager-list-snippet-last-row'>
                  <div> {article.label} </div>
                  <div>Loves {article.loves} </div>

                  <div className='articleManager-list-snippet-publish-btns-container'>
                    <label>
                      <input
                        type='radio'
                        checked={!article.publish ? 'checked' : ''}
                        onChange={() =>
                          this.publishArticle(article._id, 'close')
                        }
                      />{' '}
                      收起
                    </label>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <label>
                      <input
                        type='radio'
                        checked={article.publish ? 'checked' : ''}
                        onChange={() =>
                          this.publishArticle(article._id, 'publish')
                        }
                      />{' '}
                      發佈
                    </label>
                  </div>
                </div>
                <div className='articleManager-list-snippet-last-row'>
                  <div>
                    <div>更新 {article.updatedTimeFormattedS}</div>
                    <div>建立 {article.publishTimeFormattedS}</div>
                  </div>

                  <div className='articleManager-list-snippet-btns-container'>
                    <div
                      className='fa fa-pencil editIcon'
                      onClick={() => {
                        this.props.history.push(
                          `/article/editor/update/${article._id}`
                        );
                        window.scrollTo(0, 0);
                      }}
                    ></div>
                    <div
                      className='fa fa-trash removeIcon'
                      onClick={() => this.adminRemoveArticle(article._id)}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='gap'></div>
          <div className='articleManager-list-inner-container'>
            <div className='articleManager-list-inner-title community'>
              社區版
            </div>
            {communityArticleList.length === 0 ? (
              <div className='articleManager-list-slogan'>
                社區版找不到符合條件的搜尋結果
              </div>
            ) : null}
            {communityArticleList.map((article, index) => (
              <div
                key={index}
                className='articleManager-list-snippet-container community'
              >
                <div className='articleManager-list-snippet-upper-container'>
                  {article.coverImgPath ? (
                    <div className='articleManager-list-snippet-img-container'>
                      <img
                        className='articleManager-list-snippet-img'
                        src={`/img/articles/${article._id}-cover.jpg`}
                        alt=''
                      />
                    </div>
                  ) : null}

                  <div className='articleManager-list-snippet-description-container'>
                    <div className='articleManager-list-snippet-row'>
                      <Link
                        to={`/article/${article._id}`}
                        className='articleManager-list-snippet-link'
                      >
                        {article.title}
                      </Link>
                    </div>

                    <div className='articleManager-list-snippet-row author'>
                      <div>
                        <span style={{ color: '#cc0066' }}>
                          id {article.author.userId}
                        </span>{' '}
                        &nbsp;
                        {article.author.name}
                      </div>
                      <div>戦闘力 {article.author.powerLevel} </div>
                    </div>
                  </div>
                </div>
                <div className='articleManager-list-snippet-last-row'>
                  <div> {article.label} </div>
                  <div>Loves {article.loves} </div>

                  <div className='articleManager-list-snippet-publish-btns-container'>
                    <label>
                      <input
                        type='radio'
                        checked={!article.publish ? 'checked' : ''}
                        onChange={() =>
                          this.publishArticle(article._id, 'close')
                        }
                      />{' '}
                      收起
                    </label>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <label>
                      <input
                        type='radio'
                        checked={article.publish ? 'checked' : ''}
                        onChange={() =>
                          this.publishArticle(article._id, 'publish')
                        }
                      />{' '}
                      發佈
                    </label>
                  </div>
                </div>
                <div className='articleManager-list-snippet-last-row'>
                  <div>
                    <div>更新 {article.updatedTimeFormattedS}</div>
                    <div>建立 {article.publishTimeFormattedS}</div>
                  </div>

                  <div className='articleManager-list-snippet-btns-container'>
                    <div
                      className='fa fa-pencil editIcon'
                      onClick={() => {
                        this.props.history.push(
                          `/article/editor/update/${article._id}`
                        );
                        window.scrollTo(0, 0);
                      }}
                    ></div>
                    <div
                      className='fa fa-trash removeIcon'
                      onClick={() => this.adminRemoveArticle(article._id)}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleManager;
