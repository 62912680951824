import React from "react";

import Subtitle1 from "../snippetCollections/subtitle1";
import Subtitle2 from "../snippetCollections/subtitle2";
import Subtitle3 from "../snippetCollections/subtitle3";
import Youtube from "../snippetCollections/youtube";
import Content from "../snippetCollections/content";
import Link from "../snippetCollections/link";
import Picture from "../snippetCollections/picture";
import Code from "../snippetCollections/code";

const SnippetTemplate = ({
  update,
  tag,
  index,
  onSnippetChange,
  value,
  onSnippetDelete,
  content,
  onSelectedLang
}) => {
  const components = {
    subtitle1: Subtitle1,
    subtitle2: Subtitle2,
    subtitle3: Subtitle3,
    youtube: Youtube,
    content: Content,
    link: Link,
    picture: Picture,
    code: Code
  };

  const Tag = components[tag];

  return (
    <Tag
      update={update}
      index={index}
      onSnippetChange={onSnippetChange}
      value={value}
      onSnippetDelete={onSnippetDelete}
      content={content}
      onSelectedLang={onSelectedLang}
    />
  );
};

export default SnippetTemplate;
