import React, { Component } from 'react';

class ContactUrlInsertBar extends Component {
  state = {
    medias: [
      'website',
      'download',
      'youtube',
      'telegram',
      'twitter',
      'facebook',
      'wechat',
      'weibo',
      'medium',
      'github',
      'discord'
    ]
  };
  render() {
    const { medias } = this.state;
    const {
      blockIndex,
      iconIndex,
      method,
      onContactUrlChange,
      onInsertBarSwitch
    } = this.props;
    return (
      <div>
        <div className='middle-navigator-edit-snippet-content-insert-contactUrl-bar-container'>
          <div className='middle-navigator-edit-snippet-content-insert-contactUrl-bar-left'>
            <i className='fa fa-arrow-up arrow' /> &nbsp; 選擇插入相關 URL
          </div>
          <div className='middle-navigator-edit-snippet-content-insert-contactUrl-bar-right'>
            {medias.map((media, index) => (
              <div
                key={index}
                className='middle-navigator-edit-snippet-right-addLinkIcon-container'
                onClick={() =>
                  onContactUrlChange(
                    blockIndex,
                    iconIndex,
                    method,
                    media,
                    onInsertBarSwitch
                  )
                }
              >
                <img src={`/img/navigator/${media}.svg`} alt={`${media}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUrlInsertBar;
