import React, { Component } from 'react';
import NavIconsContainer from '../components/navIconsContainer';
import { Link } from 'react-router-dom';

class Navigation extends Component {
  render() {
    const {
      account,
      navPanel,
      rightPanelActive,
      onNavButtonSubclassChange,
      onNavIconChange,
      onNavPanelInputChange,
      onButtonListSubmit,
      onNavIconSelected
    } = this.props;
    return (
      <React.Fragment>
        {!account.admin ? (
          <div
            className={
              rightPanelActive === '導航'
                ? 'rightContainer content active'
                : 'rightContainer content'
            }
          >
            {navPanel.subclasses.map((subclass, subclassIndex) => (
              <NavIconsContainer
                key={subclassIndex}
                subclass={subclass}
                onNavIconSelected={onNavIconSelected}
              />
            ))}
            <br />
            <br />
          </div>
        ) : (
          <div
            className={
              rightPanelActive === '導航'
                ? 'rightContainer content active'
                : 'rightContainer content'
            }
          >
            <br />
            <div className='right-nav-class-container'>
              {!navPanel.subclasses.length
                ? null
                : navPanel.subclasses.map((subclass, subclassIndex) => (
                    <div key={subclassIndex}>
                      <br />
                      <div className='right-nav-addNewClassBtn-container'>
                        <span
                          className='right-nav-addNewClassBtn'
                          onClick={() =>
                            onNavButtonSubclassChange('insert', subclassIndex)
                          }
                        >
                          &#9650; 往上插入分類
                        </span>
                      </div>
                      <br />
                      <div
                        className={
                          subclass._id
                            ? 'right-nav-title-path-input-container saved'
                            : 'right-nav-title-path-input-container'
                        }
                      >
                        <div style={{ marginLeft: 10 }}>
                          標題{' '}
                          <input
                            className='right-nav-title-input'
                            value={subclass.subclass}
                            onChange={e =>
                              onNavPanelInputChange(
                                e,
                                'subclass',
                                subclassIndex,
                                null
                              )
                            }
                          />
                        </div>
                        &nbsp; &nbsp;
                        <div
                          onClick={() =>
                            onNavButtonSubclassChange('remove', subclassIndex)
                          }
                        ></div>
                        <i className='fa fa-trash icon' />
                      </div>
                      <div className='right-nav-icon-group-edit-container'>
                        {!subclass.buttons.length
                          ? null
                          : subclass.buttons.map((btn, iconIndex) => (
                              <div
                                key={iconIndex}
                                className='right-nav-icon-edit-container-outer'
                              >
                                <div
                                  className={
                                    btn.contentRef && btn._id
                                      ? 'right-nav-icon-edit-container saved withContent'
                                      : !btn.contentRef && btn._id
                                      ? 'right-nav-icon-edit-container saved withoutContent'
                                      : 'right-nav-icon-edit-container'
                                  }
                                >
                                  <Link
                                    className='right-nav-icon-edit-img-container'
                                    to={
                                      !btn._id
                                        ? '/'
                                        : !btn.contentRef
                                        ? `/nav/create`
                                        : `/nav/${btn.contentRef}`
                                    }
                                    onClick={() =>
                                      onNavIconSelected(btn, subclass.subclass)
                                    }
                                  >
                                    <img
                                      className='right-nav-icon-edit-img'
                                      src={
                                        btn.src
                                          ? btn.src
                                          : btn.imgPath
                                          ? `/img/buttons/navPanel/${btn._id}.svg`
                                          : '/img/buttons/navPanel/navPanel-upload.svg'
                                      }
                                      alt={btn.title}
                                    />
                                  </Link>
                                  <div className='right-nav-icon-edit-input-container'>
                                    <input
                                      type='text'
                                      className='right-nav-icon-edit-input'
                                      onChange={e =>
                                        onNavPanelInputChange(
                                          e,
                                          'title',
                                          subclassIndex,
                                          iconIndex
                                        )
                                      }
                                      value={btn.title}
                                    />
                                  </div>
                                  <div className='right-nav-icon-edit-modify-container'>
                                    <i
                                      className='fa fa-plus icon plus'
                                      onClick={() =>
                                        onNavIconChange(
                                          'insert',
                                          subclassIndex,
                                          iconIndex
                                        )
                                      }
                                    ></i>
                                    <label
                                      className='fa fa-upload icon'
                                      htmlFor={`upload-icon-${subclassIndex}-${iconIndex}`}
                                    ></label>
                                    <input
                                      type='file'
                                      name='icon'
                                      className='upload-icon'
                                      id={`upload-icon-${subclassIndex}-${iconIndex}`}
                                      onChange={e =>
                                        onNavPanelInputChange(
                                          e,
                                          'img',
                                          subclassIndex,
                                          iconIndex
                                        )
                                      }
                                    />
                                    <i
                                      className='fa fa-trash icon trash'
                                      onClick={() =>
                                        onNavIconChange(
                                          'remove',
                                          subclassIndex,
                                          iconIndex
                                        )
                                      }
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            ))}
                        <div className='right-nav-icon-add-container'>
                          <img
                            className='right-nav-icon-add'
                            src='/img/buttons/navPanel/navPanel-addIcon.svg'
                            alt='新增圖標'
                            onClick={() =>
                              onNavIconChange('push', subclassIndex)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
            <br />
            <div className='right-nav-addNewClassBtn-container'>
              <span
                className='right-nav-addNewClassBtn'
                onClick={() => onNavButtonSubclassChange('push')}
              >
                &#9660; 往下新增分類
              </span>
            </div>
            <br />
            <br />
            <div className='right-nav-addNewClassBtn-container'>
              <span
                className='right-nav-addNewClassBtn'
                onClick={() => onButtonListSubmit('navPanel')}
              >
                儲存更新
              </span>
            </div>
            <br />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Navigation;
