const chineseTimeFormat = time => {
  if (time.includes('PM')) {
    time = time.replace('PM', '下午');
  } else {
    time = time.replace('AM', '上午');
  }

  if (time.includes('Mon')) {
    time = time.replace('Mon', '一');
  } else if (time.includes('Tue')) {
    time = time.replace('Tue', '二');
  } else if (time.includes('Wed')) {
    time = time.replace('Wed', '三');
  } else if (time.includes('Thu')) {
    time = time.replace('Thu', '四');
  } else if (time.includes('Fri')) {
    time = time.replace('Fri', '五');
  } else if (time.includes('Sat')) {
    time = time.replace('Sat', '六');
  } else if (time.includes('Sun')) {
    time = time.replace('Sun', '日');
  }

  return time;
};

module.exports.chineseTimeFormat = chineseTimeFormat;
