import React, { Component } from 'react';
import SubBlockTemplate from './subBlockTemplate';
import ContactUrlBar from './contactUrlBar';

class RecommendSnippet extends Component {
  render() {
    const { _id, label, subBlocks, contactBar } = this.props.block;
    return (
      <div className='middle-navigator-recommendSnippet-container'>
        <div className='middle-navigator-recommendSnippet-left-container'>
          <div className='middle-navigator-recommendSnippet-left-avatar-container'>
            <img
              className='middle-navigator-recommendSnippet-left-avatar'
              src={`/img/navigator/${_id}.png`}
              alt={`${label}`}
            />
          </div>
          <div className='middle-navigator-recommendSnippet-left-avatar-label'>
            {label}
          </div>
        </div>
        <div className='middle-navigator-recommendSnippet-right-container'>
          <div className='middle-navigator-recommendSnippet-right-upper'>
            {subBlocks.map((subBlock, subBlockIndex) => (
              <SubBlockTemplate key={subBlockIndex} subBlock={subBlock} />
            ))}
          </div>
          <div className='middle-navigator-recommendSnippet-right-lower'>
            <ContactUrlBar contactBar={contactBar} />
          </div>
        </div>
      </div>
    );
  }
}

export default RecommendSnippet;
