import React, { Component } from 'react';
import autosize from 'autosize';
import QuestionMark from '../../../../common/icons/questionMark';

class Subtitle3 extends Component {
  componentDidMount() {
    autosize(this.textarea);
  }

  render() {
    const { onSnippetChange, onSnippetDelete, value, index } = this.props;
    const info = '尺寸最少的副標題，但字型大於內容';
    return (
      <div className='articleEdit-editor-inputContainer'>
        <div className='articleEdit-editor-inputTitleContainer'>
          <div className='title'>副標題3</div>
          <div className='articleEdit-editor-input-rightContainer'>
            <QuestionMark title={info} />
            <i
              className='fa fa-trash icon'
              onClick={() => onSnippetDelete(index)}
            ></i>
          </div>
        </div>
        <textarea
          style={{ height: '30px' }}
          ref={c => (this.textarea = c)}
          onChange={e => onSnippetChange(e, index, 'text')}
          value={value}
        ></textarea>
      </div>
    );
  }
}

export default Subtitle3;
