import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './css/index.css';
import './css/reset.css';
import './css/prism_tomorrow_night.css';
import './css/icon.css';
import 'font-awesome/css/font-awesome.css';
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
