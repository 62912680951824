import React, { Component } from 'react';
import InsertBar from './insertBar';
import ContactUrlInsertBar from './contactUrlInsertBar';
import InsertSubBlockBar from './insertSubBlockBar';
import SubBlockTemplate from './subBlockTemplate';
import ContactUrlSubBlock from './contactUrlSubBlock';

class RecommendSnippetEditBlock extends Component {
  state = {
    insertBarSwitch: false
  };

  handleInsertBarSwitch = specified => {
    let insertBarSwitch;
    if (!specified) {
      insertBarSwitch = !this.state.insertBarSwitch;
    } else {
      insertBarSwitch = specified;
    }
    this.setState({ insertBarSwitch });
  };

  render() {
    const { insertBarSwitch } = this.state;
    const {
      index,
      onBlockInputChange,
      onBlockTypeChange,
      onSubBlockTypeChange,
      onContactUrlChange
    } = this.props;

    const { _id, label, src, url, subBlocks, contactBar } = this.props.block;
    return (
      <div className='middle-navigator-edit-paragraph-container snippet'>
        {insertBarSwitch ? (
          <InsertBar
            blockIndex={index}
            method={'insert'}
            onBlockTypeChange={onBlockTypeChange}
            onInsertBarSwitch={this.handleInsertBarSwitch}
          />
        ) : null}

        <div className='middle-navigator-edit-paragraph-upper-container'>
          <div className='middle-navigator-edit-paragraph-tag-container'>
            推介方塊
          </div>
          <div className='middle-navigator-edit-snippet-right-icon-rightSide'>
            <div
              className='middle-navigator-edit-snippet-right-icon-container'
              onClick={() => this.handleInsertBarSwitch()}
            >
              {insertBarSwitch ? (
                <i className='fa fa-minus minusIcon' />
              ) : (
                <i className='fa fa-plus plusIcon' />
              )}
            </div>
            <div
              className='middle-navigator-edit-snippet-right-icon-container'
              onClick={() => onBlockTypeChange(index, 'remove', null)}
            >
              <i className='fa fa-trash trashIcon' />
            </div>
          </div>
        </div>
        <div className='middle-navigator-edit-paragraph-input snippet-container'>
          <div className='left'>
            <div style={{ marginBottom: 10 }}>
              <div className='middle-navigator-edit-snippet-icon-container'>
                <img
                  className='middle-navigator-edit-snippet-icon'
                  src={
                    url
                      ? url
                      : src
                      ? `/img/navigator/${_id}.png`
                      : '/img/buttons/navPanel/navPanel-upload.svg'
                  }
                  alt='icon'
                />
              </div>
              <label
                className='middle-navigator-edit-snippet-icon-uploadBtn'
                htmlFor={`snippetIcon-${index}`}
              >
                上傳圖像
              </label>
              <input
                type='file'
                name='uploadIcon'
                className='upload-icon'
                id={`snippetIcon-${index}`}
                onChange={e => onBlockInputChange(e, 'img', index, null)}
              />
              <div className='middle-navigator-edit-snippet-icon-title-input-container'>
                <input
                  type='text'
                  name='label'
                  className='middle-navigator-edit-snippet-icon-title-input'
                  onChange={e => onBlockInputChange(e, 'link', index, null)}
                  value={label}
                />
              </div>
            </div>
          </div>

          <div className='right'>
            <div className='middle-navigator-edit-snippet-content-container'>
              {subBlocks.map((subBlock, subBlockIndex) => (
                <SubBlockTemplate
                  key={subBlockIndex}
                  subBlock={subBlock}
                  blockIndex={index}
                  subBlockIndex={subBlockIndex}
                  onSubBlockTypeChange={onSubBlockTypeChange}
                  onBlockInputChange={onBlockInputChange}
                />
              ))}
              <InsertSubBlockBar
                blockIndex={index}
                subBlockIndex={null}
                method={'push'}
                onSubBlockTypeChange={onSubBlockTypeChange}
              />

              {contactBar.map((icon, iconIndex) => (
                <ContactUrlSubBlock
                  key={iconIndex}
                  icon={icon}
                  blockIndex={index}
                  iconIndex={iconIndex}
                  onContactUrlChange={onContactUrlChange}
                  onBlockInputChange={onBlockInputChange}
                />
              ))}

              <ContactUrlInsertBar
                blockIndex={index}
                iconIndex={null}
                method={'push'}
                onContactUrlChange={onContactUrlChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RecommendSnippetEditBlock;
