import React from "react";
import QuestionMark from "../../../../common/icons/questionMark";

const Picture = ({
  index,
  onSnippetChange,
  onSnippetDelete,
  content,
  update
}) => {
  const info = "圖片闊度不得大於高度，但闊度不得大於高度的兩倍";
  return (
    <div className="articleEdit-editor-input-picture-container">
      <div className="articleEdit-editor-input-picture-preview">
        <img
          className="img"
          src={
            content.src
              ? content.src
              : update && content.path
              ? `/img/articles/${content.path}`
              : "/img/articleEditor/imgUpload/preview.svg"
          }
          alt="preview"
        />
      </div>

      <div className="articleEdit-editor-input-picture-rightContainer">
        <div className="articleEdit-editor-pictureTitleContainer">
          <h3 className="title">圖片</h3>
          <div className="articleEdit-editor-picture-iconContainer">
            <QuestionMark title={info} />
            <i
              className="fa fa-trash icon"
              onClick={() => onSnippetDelete(index)}
            />
          </div>
        </div>
        <div className="uploadPath">
          {content.src ? "圖檔: " : update ? content.path : "未選擇任何檔案"}
          <br />
          {content.src ? content.text : ""}
        </div>
        <label className="uploadBtn" htmlFor={"upload-picture" + index}>
          選擇圖片
        </label>
        <input
          type="file"
          name="picture"
          id={"upload-picture" + index}
          className="upload-picture-hidden"
          onChange={e => onSnippetChange(e, index, "img")}
        />
      </div>
    </div>
  );
};

export default Picture;
