import React, { Component } from 'react';
import Editor from './editor/editor';
import Preview from './preview/preview';
import './css/articleEditZone.css';

class ArticleEditor extends Component {
  componentDidMount() {
    const articleId = this.props.match.params.id;
    if (articleId) this.props.onFetchArticleForEdit(articleId);

    const mode = this.props.match.params.mode;
    if (mode && mode === 'community') {
      if (!this.props.account._id) return this.props.history.push('/');
      this.props.onArticleEditVersionChange('community');
    }
    if (mode && mode === 'official') {
      if (!this.props.account.admin && !this.props.account.moderator) {
        return this.props.history.push('/');
      }
      this.props.onArticleEditVersionChange('official');
    }
  }

  render() {
    const { article, temp, update } = this.props.articleEditorBlock;
    const { account, currentTime, currentTimeS } = this.props;
    const {
      onChange,
      onInsertTypeSelected,
      onSnippetChange,
      onSnippetDelete,
      onCoverImgUpload,
      onCoverImgDelete,
      onSelectedLang,
      onArticleSubmit,
      onLabelSelected
    } = this.props;

    return (
      <div className='wrapper-editor'>
        <div className='articleEditZone-container'>
          <Editor
            update={update}
            article={this.props.articleEditorBlock.article}
            temp={temp}
            articleId={this.props.match.params.id}
            onChange={onChange}
            onInsertTypeSelected={onInsertTypeSelected}
            onSnippetChange={onSnippetChange}
            onSnippetDelete={onSnippetDelete}
            onCoverImgUpload={onCoverImgUpload}
            onCoverImgDelete={onCoverImgDelete}
            onSelectedLang={onSelectedLang}
            onArticleSubmit={onArticleSubmit}
            onLabelSelected={onLabelSelected}
            onFetchArticleForEdit={this.props.onFetchArticleForEdit}
            errors={article.errors}
          />
          <Preview
            update={update}
            account={account}
            article={article}
            temp={temp}
            onSnippetChange={onSnippetChange}
            currentTime={currentTime}
            currentTimeS={currentTimeS}
            onArticleSubmit={onArticleSubmit}
          />
        </div>
      </div>
    );
  }
}

export default ArticleEditor;
