import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

class AccountLoggedIn extends Component {
  currentTime = moment().unix();

  render() {
    const {
      panel,
      account,
      myArticles,
      onArticleSelected,
      onArticleDelete,
      onLoggedOut
    } = this.props;

    return (
      <div>
        {!account.validated && account.name ? (
          <div className='left-notValidate-container'>
            <div className='notValidate-message'>
              確認電郵已經寄出，如果十五分鐘收沒有收到確認電郵，請按以下
              '發出確認電郵'
              按鈕，再等十五分鐘查看註册電郵，任何沒有經過電郵確認的帳號均不可在聊天室發言、發表文章、回覆訊息及給
              "Love"。
            </div>
            <div className='notValidate-message warning'>
              請在註册後 24
              小時內進行電郵確認，沒有經過電郵確認的帳號將會在24小時後自動被删除
            </div>

            {this.currentTime > account.updatedAt + 900 ? (
              <div className='right-confirmation-btn-container'>
                <span
                  className='right-confirmation-btn'
                  onClick={() => this.props.onConfirmationResend()}
                >
                  發出確認電郵
                </span>
                <br />
                <br />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className='left-control-panel-btns-container'>
          <Link
            to={
              account.admin || account.moderator
                ? '/article/editor/new/official'
                : '/article/editor/new/community'
            }
            className='left-control-panel-btn-container'
          >
            <img
              className='left-control-panel-btn-img'
              src='/img/buttons/controlPanel/writeArticle.svg'
              alt='發表文章'
            />
            發表文章
          </Link>
          <Link
            to='/account/edit'
            className='left-control-panel-btn-container'
            onClick={() => window.scrollTo(0, 0)}
          >
            <img
              className='left-control-panel-btn-img'
              src='/img/buttons/controlPanel/editAccount.svg'
              alt='編輯帳號'
            />
            編輯帳號
          </Link>
          <div
            className='left-control-panel-btn-container'
            onClick={() => {
              onLoggedOut();
            }}
          >
            <img
              className='left-control-panel-btn-img'
              src='/img/buttons/controlPanel/logout.svg'
              alt='登出'
            />
            登出
          </div>
        </div>

        {!account.admin && account.userId ? null : (
          <div className='left-account-admin-container'>
            <Link to='/articles/edit' className='left-account-admin-btn'>
              管理文章
            </Link>
            <Link to='/accounts/manager' className='left-account-admin-btn'>
              管理用戶
            </Link>
            <Link to='/news/edit' className='left-account-admin-btn'>
              管理新聞
            </Link>
          </div>
        )}
        <div
          className='left-title extend'
          onClick={() => this.props.onPanelExpand('myAccount', 'left')}
        >
          {!panel.myAccount ? <span>&#9658;</span> : <span>&#9660;</span>}{' '}
          我的帳號
        </div>
        <div
          className={
            panel.myAccount
              ? 'left-dynamicPanel-container active'
              : 'left-dynamicPanel-container'
          }
        >
          <div className='left-dynamicPanel-first-row'>
            <div className='left-dynamicPanel-avatar-container'>
              <img
                className='left-dynamicPanel-avatar'
                src={`/img/accounts/avatars/custom/${account.userId}.png`}
                alt={account.name}
              />
            </div>
            <div className='left-dynamicPanel-welcome-slogan'>
              {' '}
              歡迎 <span style={{ color: '#2389b1' }}>{account.name} </span>
              <br />
              來臨柚子市!
            </div>
          </div>
          <div className='leftAccountDetails-container'>
            <div className='leftAccountDetails-label'>暱稱:</div>
            <div className='leftAccountDetails-value'>{account.name}</div>

            <div className='leftAccountDetails-label'>識別碼:</div>
            <div className='leftAccountDetails-value'>{account.userId}</div>

            <div className='leftAccountDetails-label'>戦闘力:</div>
            <div className='leftAccountDetails-value'>{account.powerLevel}</div>

            <div className='leftAccountDetails-email'>
              電郵: &nbsp;{' '}
              <span style={{ color: '#2389b1' }}> {account.email}</span>
            </div>

            <div className='leftAccountDetails-container'>
              {account.gender ? (
                <div className='leftAccountDetails-label'>性別:</div>
              ) : null}
              {account.gender ? (
                <div className='leftAccountDetails-value'>
                  {account.gender === 'male' ? '男' : '女'}
                </div>
              ) : null}

              {account.birthYear ? (
                <div className='leftAccountDetails-label'>年齡:</div>
              ) : null}
              {account.birthYear ? (
                <div className='leftAccountDetails-value'>
                  {moment().format('YYYY') - account.birthYear}歲
                </div>
              ) : null}

              {account.intro ? (
                <div className='leftAccountDetails-label'>自我介紹:</div>
              ) : null}
              {account.intro ? (
                <div className='leftAccountDetails-value'>{account.intro}</div>
              ) : null}
            </div>
          </div>
          {account.eosAccount ||
          account.telosAccount ||
          account.bosAccount ||
          account.worbliAccount ||
          account.waxAccount ||
          account.meetOneAccount ||
          account.lynxAccount ? (
            <div>
              <div className='leftAccountDetails-title'>打賞帳號</div>

              {account.eosAccount ? (
                <div className='leftAccountDetails-container account'>
                  <div className='leftAccountDetails-account-icon'>
                    <img src='/img/accounts/chains/eos.png' alt='EOSIO' />
                  </div>
                  <div className='leftAccountDetails-account'>EOS主網</div>
                  <div className='leftAccountDetails-account-value'>
                    {account.eosAccount}
                  </div>
                </div>
              ) : null}

              {account.telosAccount ? (
                <div className='leftAccountDetails-container account'>
                  <div className='leftAccountDetails-account-icon'>
                    <img src='/img/accounts/chains/telos.png' alt='Telos' />
                  </div>
                  <div className='leftAccountDetails-account'>Telos</div>
                  <div className='leftAccountDetails-account-value'>
                    {account.telosAccount}
                  </div>
                </div>
              ) : null}

              {account.bosAccount ? (
                <div className='leftAccountDetails-container account'>
                  <div className='leftAccountDetails-account-icon'>
                    <img src='/img/accounts/chains/bos.png' alt='BOS' />
                  </div>
                  <div className='leftAccountDetails-account'>BOS</div>
                  <div className='leftAccountDetails-account-value'>
                    {account.bosAccount}
                  </div>
                </div>
              ) : null}

              {account.worbliAccount ? (
                <div className='leftAccountDetails-container account'>
                  <div className='leftAccountDetails-account-icon'>
                    <img src='/img/accounts/chains/worbli.png' alt='Worbli' />
                  </div>
                  <div className='leftAccountDetails-account'>Worbli</div>
                  <div className='leftAccountDetails-account-value'>
                    {account.worbliAccount}
                  </div>
                </div>
              ) : null}

              {account.waxAccount ? (
                <div className='leftAccountDetails-container account'>
                  <div className='leftAccountDetails-account-icon'>
                    <img src='/img/accounts/chains/wax.png' alt='WAX' />
                  </div>
                  <div className='leftAccountDetails-account'>WAX</div>
                  <div className='leftAccountDetails-account-value'>
                    {account.waxAccount}
                  </div>
                </div>
              ) : null}

              {account.meetOneAccount ? (
                <div className='leftAccountDetails-container account'>
                  <div className='leftAccountDetails-account-icon'>
                    <img src='/img/accounts/chains/meetOne.png' alt='MeetOne' />
                  </div>
                  <div className='leftAccountDetails-account'>MeetOne</div>
                  <div className='leftAccountDetails-account-value'>
                    {account.meetOneAccount}
                  </div>
                </div>
              ) : null}

              {account.lynxAccount ? (
                <div className='leftAccountDetails-container account'>
                  <div className='leftAccountDetails-account-icon'>
                    <img src='/img/accounts/chains/lynx.png' alt='Lynx' />
                  </div>
                  <div className='leftAccountDetails-account'>Lynx</div>
                  <div className='leftAccountDetails-account-value'>
                    {account.lynxAccount}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          <br />

          <div className='left-account-switch-btns-container'>
            <Link
              to='/account/edit'
              className='left-account-admin-btn myAccount'
              onClick={() => window.scrollTo(0, 0)}
            >
              編輯帳號
            </Link>
          </div>

          <br />
        </div>

        <div
          className='left-title extend'
          onClick={() => this.props.onPanelExpand('myArticles', 'left')}
        >
          {!panel.myArticles ? <span>&#9658;</span> : <span>&#9660;</span>}
          我的文章
        </div>

        <div
          className={
            panel.myArticles
              ? 'left-dynamicPanel-container active'
              : 'left-dynamicPanel-container'
          }
        >
          {myArticles.length === 0 ? (
            <div className='left-control-panel-slogan myArticles'>
              您沒有發表過任何文章，您可點擊以下按鈕發文
            </div>
          ) : null}
          {myArticles.map((article, index) => (
            <div key={index} className='left-article-snippet-container'>
              <div className='left-article-snippet-main-container'>
                <Link
                  to={`/article/${article._id}`}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    onArticleSelected(article._id);
                  }}
                >
                  <div className='left-article-snippet-title'>
                    {article.title}
                  </div>
                  <div className='left-article-snippet-info'>
                    <div>
                      {' '}
                      {article.version === 'community' ? '社區' : '官方'}{' '}
                    </div>
                    <div> {article.label} </div>
                    <div>Loves {article.loves} </div>
                  </div>
                </Link>
                <div className='left-article-snippet-btns-container'>
                  <Link
                    className='fa fa-pencil editIcon'
                    to={`/article/editor/update/${article._id}`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  ></Link>
                  <div
                    className='fa fa-trash removeIcon'
                    onClick={() => {
                      onArticleDelete(article);
                    }}
                  ></div>
                </div>
              </div>
              <div className='left-article-snippet-info last'>
                更新 {article.updatedTimeFormattedM}
              </div>
            </div>
          ))}
          <br />

          <div className='left-account-switch-btns-container'>
            <Link
              to={
                account.admin || account.moderator
                  ? '/article/editor/new/official'
                  : '/article/editor/new/community'
              }
              className='left-account-admin-btn myAccount'
              onClick={() => this.props.onResetArticleEditorBlock('b')}
            >
              發表文章
            </Link>
          </div>
        </div>
        <br />
        <div className='left-account-switch-btns-container'>
          <div className='left-account-admin-btn' onClick={() => onLoggedOut()}>
            登出
          </div>
        </div>
      </div>
    );
  }
}

export default AccountLoggedIn;
