import React from "react";

const Input = ({ name, label, value, onChange, elementId, type, errors }) => {
  return (
    <div className="articleEdit-editor-mainInput">
      <div className="articleEdit-editor-inputContainer">
        <div className="title">{label}</div>
        <div>
          <input
            id={elementId}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
          />
        </div>
        <div className="warning"> {errors[name]} </div>
      </div>
    </div>
  );
};

export default Input;
