import React, { Component } from 'react';
import Subtitle from './subtitle';
import Intro from './Intro';
import Content from './content';
import Link from './link';
import RecommendSnippet from './recommendSnippet';

class BlockTemplate extends Component {
  render() {
    const { index, block } = this.props;

    const components = {
      subtitle: Subtitle,
      intro: Intro,
      content: Content,
      link: Link,
      recommendSnippet: RecommendSnippet
    };

    const Tag = components[block.blockType];

    return <Tag index={index} block={block} />;
  }
}

export default BlockTemplate;
