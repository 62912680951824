import React, { Component } from 'react';

class ConfirmationResend extends Component {
  state = {};
  render() {
    const account = JSON.parse(localStorage.getItem('account'));
    const { name, email } = account;
    return (
      <div className='emailConfirmation-container'>
        <div className='emailConfirmation-header'>確認電郵已再次寄出</div>
        <br />
        <div className='emailConfirmation-p-container'>
          您好! <span style={{ color: '#cc0066' }}>{name}</span>
          <br />
          <br />
          柚子市網站會把您强制登出，系統會在 15
          分鐘內寄出確認電郵通知，您須要開啟這封電郵通知，然後點擊提供給您的連結，即可完成整個確認電郵的程序。
          <br />
          <br />
          如果 15
          分鐘後收不到確認電郵通知，您須要查看在垃圾郵件中有沒有該確認通知，如果沒有，請登入到
          &nbsp; <span style={{ color: '#0f3676' }}>https://eoscity.io</span>
          &nbsp; 登入您自己的柚子市帳號，在控制面版您會看到一個叫{' '}
          <span style={{ color: '#0f3676' }}>"發出確認電郵"</span>
          的按鈕，請點擊此按鈕讓系統再發確認電郵通知給您。
          <br />
          <br />
          請馬上前往 <span style={{ color: '#cc0066' }}> {email} </span>{' '}
          進行電郵確認
          <br />
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <span
              className='emailConfirmation-btn'
              onClick={() => {
                this.props.history.push('/');
                localStorage.removeItem('account');
              }}
            >
              確定
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationResend;
