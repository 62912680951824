import React, { Component } from 'react';
import autosize from 'autosize';
import InsertSubBlockBar from './insertSubBlockBar';

class ContentEditSubBlock extends Component {
  state = {
    insertBarSwitch: false
  };
  componentDidMount() {
    autosize(this.textarea);
  }
  handleInsertBarSwitch = specified => {
    let insertBarSwitch;
    if (!specified) {
      insertBarSwitch = !this.state.insertBarSwitch;
    } else {
      insertBarSwitch = specified;
    }
    this.setState({ insertBarSwitch });
  };

  render() {
    const { insertBarSwitch } = this.state;
    const {
      subBlock,
      blockIndex,
      subBlockIndex,
      onSubBlockTypeChange,
      onBlockInputChange
    } = this.props;
    return (
      <div className='middle-navigator-edit-snippet-content-subContainer'>
        {insertBarSwitch ? (
          <InsertSubBlockBar
            blockIndex={blockIndex}
            subBlockIndex={subBlockIndex}
            method={'insert'}
            onSubBlockTypeChange={onSubBlockTypeChange}
            onInsertBarSwitch={this.handleInsertBarSwitch}
          />
        ) : null}
        <div
          className='middle-navigator-edit-paragraph-upper-container'
          style={{ paddingRight: 13 }}
        >
          <div className='middle-navigator-edit-snippet-content-label'>
            內容
          </div>
          <div className='middle-navigator-edit-snippet-right-icon-rightSide'>
            <div
              className='middle-navigator-edit-snippet-right-icon-container small'
              onClick={() => this.handleInsertBarSwitch()}
            >
              {insertBarSwitch ? (
                <i className='fa fa-minus plusIcon small' />
              ) : (
                <i className='fa fa-plus plusIcon small' />
              )}
            </div>
            <div
              className='middle-navigator-edit-snippet-right-icon-container small'
              onClick={() =>
                onSubBlockTypeChange(blockIndex, subBlockIndex, 'remove', null)
              }
            >
              <i className='fa fa-trash trashIcon small' />
            </div>
          </div>
        </div>
        <textarea
          ref={c => {
            this.textarea = c;
          }}
          type='text'
          className='middle-navigator-edit-snippet-content-input content'
          onChange={e =>
            onBlockInputChange(e, 'textSubBlock', blockIndex, subBlockIndex)
          }
          value={subBlock.content}
        />
      </div>
    );
  }
}

export default ContentEditSubBlock;
