import React, { Component } from 'react';

class NavBar extends Component {
  state = {
    articleTypeBtns: [
      { label: '最新', version: 'official' },
      { label: '主網', version: 'official' },
      { label: '遊戲', version: 'official' },
      { label: '幣市', version: 'official' },
      { label: 'DAPPS', version: 'official' },
      { label: '社區', version: 'official' },
      { label: 'BP', version: 'official' },
      { label: '教程', version: 'official' }
    ]
  };
  render() {
    const { articleTypeBtns } = this.state;
    const { articleTypeLabel, onUpdateSnippetList } = this.props;

    return (
      <div className='middle-bottom-articleTypeBarContainer'>
        {articleTypeBtns.map((btn, index) => (
          <div
            key={index}
            className={
              articleTypeLabel === btn.label
                ? 'middle-bottom-articleTypeBtn active'
                : 'middle-bottom-articleTypeBtn'
            }
            onClick={() => onUpdateSnippetList('official', btn.label)}
          >
            {btn.label}
          </div>
        ))}
      </div>
    );
  }
}

export default NavBar;
