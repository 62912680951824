import React, { Component } from 'react';
import TitleSubBlock from './titleSubBlock';
import IntroSubBlock from './introSubBlock';
import ContentSubBlock from './contentSubBlock';
import LinkSubBlock from './linkSubBlock';

class SubBlockTemplate extends Component {
  render() {
    const { subBlockType, label, content, url } = this.props.subBlock;

    const components = {
      title: TitleSubBlock,
      intro: IntroSubBlock,
      content: ContentSubBlock,
      link: LinkSubBlock
    };

    const Tag = components[subBlockType];
    return <Tag label={label} content={content} url={url} />;
  }
}

export default SubBlockTemplate;
