import React from 'react';
import NavIcon from './navIcon';

const NavIconsContainer = ({ subclass, onNavIconSelected }) => {
  return (
    <div className='nav2-subZoneIcon'>
      <div className='nav2-titleIcon'>{subclass.subclass}</div>
      <div className='nav2-iconsContainer'>
        {subclass.buttons.map((button, index) =>
          button.contentRef ? (
            <NavIcon
              key={index}
              button={button}
              onNavIconSelected={onNavIconSelected}
            />
          ) : null
        )}
      </div>
    </div>
  );
};

export default NavIconsContainer;
