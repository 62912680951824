import React from "react";

const Link = ({ content }) => {
  return (
    <div className="articleRender-container linkBlock">
      <a href={content.path}>{content.text}</a>
    </div>
  );
};

export default Link;
