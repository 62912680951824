import React, { Component } from 'react';
import moment from 'moment';
import * as ReactDOM from 'react-dom';
import ChatInput from '../components/chatInput';
import Ads from '../components/ads';
import '../css/community.css';

class Community extends Component {
  componentWillUpdate(nextProps) {
    this.historyChanged =
      nextProps.chatCollection.length !== this.props.chatCollection.length;
    if (this.historyChanged) {
      const { messageList } = this.refs;
      const scrollPos = Math.floor(messageList.scrollTop);
      const scrollBottom = messageList.scrollHeight - messageList.clientHeight;

      if (messageList.childNodes.length) {
        const last2MessageHeight =
          parseInt(
            window.getComputedStyle(
              messageList.childNodes[messageList.childNodes.length - 1]
            ).height
          ) + 20;

        this.scrollAtBottom =
          scrollBottom <= 0 || scrollPos >= scrollBottom - last2MessageHeight;
      }
    }
  }

  componentDidMount() {
    const { messageList } = this.refs;

    this.timer = setInterval(() => {
      if (messageList.childNodes.length) {
        this.scrollToBottom();
        clearInterval(this.timer);
      }
    }, 500);
  }

  componentDidUpdate() {
    if (this.historyChanged) {
      if (this.scrollAtBottom) this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    const { messageList } = this.refs;
    const scrollHeight = messageList.scrollHeight;
    const height = messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    ReactDOM.findDOMNode(messageList).scrollTop =
      maxScrollTop > 0 ? maxScrollTop : 0;
  };

  currentTime = moment().unix();

  onScroll = () => {
    //
  };

  render() {
    const {
      leftPanelActive,
      account,
      message,
      currentLogMode,
      chatCollection,
      keepMeLoggedIn,
      onSendMessage,
      onMessageChange,
      login,
      onLogin,
      onInputChange,
      onKeepMeLoggedIn,
      errors
    } = this.props;

    const { onScroll } = this;

    return (
      <div
        className={leftPanelActive === '社區' ? 'content active' : 'content'}
      >
        {currentLogMode === 'loggedIn' ? null : (
          <div>
            <div className='left-title'>快速登入</div>
            <div className='left-community-login-container'>
              <div className='left-community-login-tag'>電郵地址</div>
              <div>
                <input
                  type='text'
                  name='email'
                  className='left-community-login-input'
                  onKeyDown={e => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                      e.preventDefault();
                      onLogin();
                    }
                  }}
                  onChange={e => {
                    onInputChange(e, 'login');
                  }}
                  value={login.email}
                />
                {errors.email ? (
                  <div className='error'> {errors.email} </div>
                ) : null}
              </div>
              <div className='left-community-login-tag'>登入密碼</div>
              <div>
                <input
                  type='password'
                  name='password'
                  className='left-community-login-input'
                  onKeyDown={e => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                      e.preventDefault();
                      onLogin();
                    }
                  }}
                  onChange={e => onInputChange(e, 'login')}
                  value={login.password}
                />
                {errors.password ? (
                  <div className='error'> {errors.password} </div>
                ) : null}
              </div>
              <div className='left-community-login-last-row login'>
                <label className='left-community-login-btn rememberMe'>
                  保持登入
                  <input
                    type='checkbox'
                    checked={keepMeLoggedIn ? 'checked' : ''}
                    value={keepMeLoggedIn}
                    onChange={onKeepMeLoggedIn}
                  />
                  <span className='checkmark'></span>
                </label>
                <div
                  className='left-community-login-submit-btn'
                  onClick={() => {
                    onLogin();
                  }}
                >
                  登入
                </div>
              </div>
              <div className='left-community-login-last-row'>
                <div className='left-community-login-btn'>忘記密碼?</div>
                <div className='left-community-login-btn'>立即註册</div>
              </div>
            </div>
          </div>
        )}

        {!account.validated && account.name ? (
          <div className='left-notValidate-container'>
            <div className='notValidate-message'>
              確認電郵已經寄出，如果十五分鐘收沒有收到確認電郵，請按以下
              '發出確認電郵'
              按鈕，再等十五分鐘查看註册電郵，任何沒有經過電郵確認的帳號均不可在聊天室發言、發表文章、回覆訊息及給
              "Love"。
            </div>
            <div className='notValidate-message warning'>
              請在註册後 24
              小時內進行電郵確認，沒有經過電郵確認的帳號將會在24小時後自動被删除
            </div>
            {this.currentTime > account.updatedAt + 900 ? (
              <div className='right-confirmation-btn-container'>
                <span
                  className='right-confirmation-btn'
                  onClick={() => this.props.onConfirmationResend()}
                >
                  發出確認電郵
                </span>
                <br />
                <br />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className='left-title'>即時討論</div>
        <div className='left-quick-login-container'></div>

        <div className='left-chatroom-container'>
          <div
            id='left-chatroom-scrollbar'
            className='left-chatroom-content-container'
            ref='messageList'
            onScroll={onScroll}
          >
            {chatCollection.length
              ? chatCollection.map((chat, index) =>
                  !chat.id ? (
                    <div key={index} className='left-message-container me'>
                      <div className='left-message-content-container warning'>
                        <div className='name'> {chat.name} </div>
                        <div className='message'> {chat.message} </div>
                      </div>
                    </div>
                  ) : chat.id === account.userId ? (
                    <div key={index} className='left-message-container me'>
                      <div className='left-content-container me'>
                        <div className='name'>
                          {chat.name} &nbsp;
                          <span className='id'>id {chat.id}</span>
                          &nbsp;
                        </div>
                        <div className='time'> {chat.time} </div>
                        <div className='message'> {chat.message} </div>
                      </div>
                    </div>
                  ) : (
                    <div key={index} className='left-message-container'>
                      <div className='left-avatar-container'>
                        <img
                          className='avatar'
                          src={`/img/accounts/avatars/custom/${chat.id}.png`}
                          alt={chat.name}
                        />
                      </div>
                      <div className='left-content-container'>
                        <div className='name'>
                          {chat.name} &nbsp;
                          <span className='id'>id {chat.id}</span>
                          &nbsp;
                        </div>
                        <div className='time'> {chat.time} </div>
                        <div className='message'> {chat.message} </div>
                      </div>
                    </div>
                  )
                )
              : null}
          </div>
          {currentLogMode === 'loggedIn' ? (
            <ChatInput
              account={account}
              message={message}
              onSendMessage={onSendMessage}
              onMessageChange={onMessageChange}
            />
          ) : null}
        </div>

        <br />

        <Ads
          aHref='https://t.me/eoscity'
          imgSrc='/img/nav1_ads/leftAds9.png'
          label='柚子市電報群'
        />

        <br />
      </div>
    );
  }
}

export default Community;
