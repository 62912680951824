import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { chineseTimeFormat } from '../../../../../../utils/chineseTimeFormat';

class AccountUpdated extends Component {
  state = {
    temp: {},
    sticky: 0
  };

  componentDidMount() {
    window.addEventListener('scroll', this.throttle);

    window.scrollTo(0, 0);
    let temp = localStorage.getItem('account');
    temp = JSON.parse(temp);
    this.setState({ temp });
  }

  componentDidUpdate() {
    if (!this.props.account.userId) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttle);
  }

  time = Date.now();

  throttle = () => {
    if (this.time + 250 - Date.now() < 0) {
      const height = this.middleContainer.clientHeight;
      const windowHeight = window.innerHeight;
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, 'middleHeight');
      this.time = Date.now();
    }
  };

  render() {
    let {
      name,
      userId,
      email,
      powerLevel,
      intro,
      gender,
      birthYear,
      eosAccount,
      telosAccount,
      bosAccount,
      worbliAccount,
      waxAccount,
      meetOneAccount,
      lynxAccount,
      updatedAt,
      createdAt
    } = this.props.account;

    createdAt = moment.unix(createdAt).format('YYYY年M月D號(ddd) Ah點m分s秒');
    createdAt = chineseTimeFormat(createdAt);

    updatedAt = moment.unix(updatedAt).format('YYYY年M月D號(ddd) Ah點m分s秒');
    updatedAt = chineseTimeFormat(updatedAt);

    return (
      <div
        style={{ position: 'sticky', top: this.state.sticky }}
        className='account-updated-container'
        ref={middleContainer => {
          this.middleContainer = middleContainer;
        }}
      >
        <div className='title'>您已成功更新您的帳號</div>

        <div>
          <img
            className='avatar'
            src={`/img/accounts/avatars/custom/${userId}.png`}
            alt={userId}
          />
        </div>

        <div className='content'>
          <div>暱稱:</div>
          <div>{name}</div>

          <div>識別碼:</div>
          <div>{userId}</div>

          <div>電郵:</div>
          <div>{email}</div>

          <div>戦闘力:</div>
          <div>{powerLevel}</div>

          {intro ? <div>自我介紹: </div> : null}
          {intro ? <div>{intro}</div> : null}

          {gender ? <div>姓別: </div> : null}
          {gender ? <div>{gender === 'male' ? '男' : '女'} </div> : null}

          {birthYear ? <div>出生年份: </div> : null}
          {birthYear ? <div>{birthYear}</div> : null}

          <div className='space'></div>
          <div></div>

          {eosAccount ? (
            <div className='titleContainer'>
              <img
                className='img'
                src='/img/accounts/chains/eos.png'
                alt='EOSIO'
              />

              <div>EOS 主網帳號:</div>
            </div>
          ) : null}
          {eosAccount ? <div>{eosAccount}</div> : null}

          {telosAccount ? (
            <div>
              <div className='titleContainer'>
                <img
                  className='img'
                  src='/img/accounts/chains/telos.png'
                  alt='Telos'
                />
                <div>Telos 帳號:</div>
              </div>
            </div>
          ) : null}
          {telosAccount ? <div>{telosAccount} </div> : null}

          {bosAccount ? (
            <div>
              <div className='titleContainer'>
                <img
                  className='img'
                  src='/img/accounts/chains/bos.png'
                  alt='BOS'
                />
                <div>BOS 帳號:</div>
              </div>
            </div>
          ) : null}
          {bosAccount ? <div>{bosAccount}</div> : null}

          {worbliAccount ? (
            <div>
              <div className='titleContainer'>
                <img
                  className='img'
                  src='/img/accounts/chains/worbli.png'
                  alt='Worli'
                />
                <div>Worbli 帳號:</div>
              </div>
            </div>
          ) : null}
          {worbliAccount ? <div>{worbliAccount} </div> : null}

          {waxAccount ? (
            <div>
              <div className='titleContainer'>
                <img
                  className='img'
                  src='/img/accounts/chains/wax.png'
                  alt='WAX'
                />
                <div>WAX 帳號:</div>
              </div>
            </div>
          ) : null}
          {waxAccount ? <div>{waxAccount}</div> : null}

          {meetOneAccount ? (
            <div>
              <div className='titleContainer'>
                <img
                  className='img'
                  src='/img/accounts/chains/meetOne.png'
                  alt='MeetOne'
                />
                <div>MeetOne 帳號:</div>
              </div>
            </div>
          ) : null}
          {meetOneAccount ? <div>{meetOneAccount} </div> : null}

          {lynxAccount ? (
            <div>
              <div className='titleContainer'>
                <img
                  className='img'
                  src='/img/accounts/chains/lynx.png'
                  alt='Lynx'
                />
                <div>Lynx 帳號:</div>
              </div>
            </div>
          ) : null}
          {lynxAccount ? <div>{lynxAccount}</div> : null}

          <div className='space'></div>
          <div></div>

          <div>最後更新時間:</div>
          <div>{updatedAt}</div>

          <div>帳號建立時間:</div>
          <div>{createdAt}</div>
        </div>

        <div className='pwHint'>
          *如果你有更新你的頭像，請按 '返回主頁'
          後刷新即可看到更新的頭像，在這頁是不會顯示更新的頭像的。
          <br />
          *如果您有更改密碼，這頁面同時是通知您的密碼已經更改，如果沒有更改，下次登入時請用原本的密碼。
        </div>

        <div>
          <Link
            className='rightLoginBtn register'
            style={{ marginTop: 75 }}
            to='/'
            onClick={() => window.scrollTo(0, 0)}
          >
            返回主頁
          </Link>
        </div>
      </div>
    );
  }
}

export default AccountUpdated;
