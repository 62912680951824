import React from 'react';
import { Link } from 'react-router-dom';
import '../css/boardTable.css';

const BoardTable = ({ imgSrc, label, content }) => {
  return (
    <div className='nav2-subZone'>
      <div className='subTag clearfix'>
        <div className='subIcon'>
          <img src={imgSrc} alt={label} />
        </div>
        <div className='title'>{label}</div>
      </div>

      <div id='rightNoticeContainer' className='subContainer'>
        <table className='news'>
          <tbody>
            {content.map((item, index) => (
              <tr key={index}>
                <td className='date'>{item.date}</td>
                {item.link ? (
                  <td className='title'>
                    <Link className='link' to={`/${item.link}`}>
                      {' '}
                      {item.message}{' '}
                    </Link>
                  </td>
                ) : (
                  <td className='title'>{item.message}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BoardTable;
