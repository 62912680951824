import React, { Component } from 'react';
import axios from 'axios';
import Joi from 'joi-browser';
import moment from 'moment';
import autosize from 'autosize';
import jwtDecode from 'jwt-decode';
import Cropper from 'react-easy-crop';
import QuestionMark from '../../../../../common/icons/questionMark';
import { chineseTimeFormat } from '../../../../../../utils/chineseTimeFormat';
import getCroppedImg from '../../../../../../utils/cropImage';

import '../../css/account.css';

class AccountEdit extends Component {
  state = {
    account: {
      name: '',
      avatar: '',
      email: '',
      birthYear: '',
      newPassword: '',
      confirmedPassword: '',
      intro: '',
      createdAt: '',
      updatedAt: '',
      eosAccount: '',
      telosAccount: '',
      bosAccount: '',
      worbliAccount: '',
      waxAccount: '',
      meetOneAccount: '',
      lynxAccount: '',
      currentPassword: ''
    },
    temp: { avatarSrc: '', avatarFile: null },
    avatarSwitch: false,
    introSwitch: false,
    birthYearSwitch: false,
    newPasswordSwitch: false,
    genderSwitch: false,
    eosAccountSwitch: false,
    telosAccountSwitch: false,
    bosAccountSwitch: false,
    worbliAccountSwitch: false,
    waxAccountSwitch: false,
    meetOneAccountSwitch: false,
    lynxAccountSwitch: false,
    imageSrc: '',
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1 / 1,
    sticky: 0,
    errors: {}
  };

  validate = () => {
    let account = { ...this.state.account };

    let validateAccount = {
      name: account.name,
      currentPassword: account.currentPassword
    };

    let updateSchema = {
      name: Joi.string()
        .min(1)
        .max(16)
        .required()
        .error(() => {
          return {
            message: '*您的暱稱最少要有1個字，或最多16個字'
          };
        }),
      currentPassword: Joi.string()
        .regex(/^[a-zA-Z0-9!@#$%&*]{8,30}$/)
        .required()
        .error(() => {
          return {
            message: '*你輸入的登入密碼不正確，請重新輸入'
          };
        })
    };

    const {
      avatarSwitch,
      introSwitch,
      birthYearSwitch,
      newPasswordSwitch,
      eosAccountSwitch,
      telosAccountSwitch,
      bosAccountSwitch,
      worbliAccountSwitch,
      waxAccountSwitch,
      meetOneAccountSwitch,
      lynxAccountSwitch
    } = this.state;

    if (avatarSwitch) {
      validateAccount.avatar = account.avatar;
      updateSchema.avatar = Joi.string()
        .min(3)
        .max(80)
        .required()
        .error(() => {
          return {
            message: '*您上傳的圖檔名字過長，或這不是圖檔'
          };
        });
    }

    if (introSwitch) {
      validateAccount.intro = account.intro;
      updateSchema.intro = Joi.string()
        .min(1)
        .max(115)
        .required()
        .error(() => {
          return {
            message: '*您沒有輸入任何自我介紹的內容、或您輸入的內容超過115字'
          };
        });
    }

    if (birthYearSwitch) {
      validateAccount.birthYear = account.birthYear;
      updateSchema.birthYear = Joi.number()
        .min(1900)
        .max(2019)
        .positive()
        .required()
        .error(() => {
          return {
            message: '*請輸入您的4位數出生年份，即 1900 ~ 2019'
          };
        });
    }

    if (newPasswordSwitch) {
      validateAccount.newPassword = account.newPassword;
      validateAccount.confirmedPassword = account.confirmedPassword;
      updateSchema.newPassword = Joi.string()
        .regex(/^[a-zA-Z0-9!@#$%&*]{8,30}$/)
        .required()
        .error(() => {
          return {
            message: '*密碼最少8位，必須包括大小階及數字，最多30位'
          };
        });
      updateSchema.confirmedPassword = Joi.string()
        .regex(/^[a-zA-Z0-9!@#$%&*]{8,30}$/)
        .required()
        .error(() => {
          return {
            message: '*密碼最少8位，必須包括大小階及數字，最多30位'
          };
        });
    }

    if (eosAccountSwitch) {
      validateAccount.eosAccount = account.eosAccount;
      updateSchema.eosAccount = Joi.string()
        .regex(/^[a-z1-5.]{1,12}$/)
        .required()
        .error(() => {
          return {
            message: '*您輸入的 EOS 帳號不正確，請重新輸入'
          };
        });
    }

    if (telosAccountSwitch) {
      validateAccount.telosAccount = account.telosAccount;
      updateSchema.telosAccount = Joi.string()
        .regex(/^[a-z1-5.]{1,12}$/)
        .required()
        .error(() => {
          return {
            message: '*您輸入的 Telos 帳號不正確，請重新輸入'
          };
        });
    }

    if (bosAccountSwitch) {
      validateAccount.bosAccount = account.bosAccount;
      updateSchema.bosAccount = Joi.string()
        .regex(/^[a-z1-5.]{1,12}$/)
        .required()
        .error(() => {
          return {
            message: '*您輸入的 BOS 帳號不正確，請重新輸入'
          };
        });
    }

    if (worbliAccountSwitch) {
      validateAccount.worbliAccount = account.worbliAccount;
      updateSchema.worbliAccount = Joi.string()
        .regex(/^[a-z1-5.]{1,12}$/)
        .required()
        .error(() => {
          return {
            message: '*您輸入的 Worbli 帳號不正確，請重新輸入'
          };
        });
    }

    if (waxAccountSwitch) {
      validateAccount.waxAccount = account.waxAccount;
      updateSchema.waxAccount = Joi.string()
        .regex(/^[a-z1-5.]{1,12}$/)
        .required()
        .error(() => {
          return {
            message: '*您輸入的 WAX 帳號不正確，請重新輸入'
          };
        });
    }

    if (meetOneAccountSwitch) {
      validateAccount.meetOneAccount = account.meetOneAccount;
      updateSchema.meetOneAccount = Joi.string()
        .regex(/^[a-z1-5.]{1,12}$/)
        .required()
        .error(() => {
          return {
            message: '*您輸入的 MeetOne 帳號不正確，請重新輸入'
          };
        });
    }

    if (lynxAccountSwitch) {
      validateAccount.lynxAccount = account.lynxAccount;
      updateSchema.lynxAccount = Joi.string()
        .regex(/^[a-z1-5.]{1,12}$/)
        .required()
        .error(() => {
          return {
            message: '*您輸入的 Lynx 帳號不正確，請重新輸入'
          };
        });
    }

    const options = { abortEarly: false };
    let result = Joi.validate(validateAccount, updateSchema, options);
    if (!result.error) {
      return {
        name: '',
        avatar: '',
        birthYear: '',
        newPassword: '',
        confirmedPassword: '',
        intro: '',
        eosAccount: '',
        telosAccount: '',
        bosAccount: '',
        worbliAccount: '',
        waxAccount: '',
        meetOneAccount: '',
        lynxAccount: ''
      };
    }

    let errors = {
      name: '',
      avatar: '',
      birthYear: '',
      newPassword: '',
      confirmedPassword: '',
      intro: '',
      eosAccount: '',
      telosAccount: '',
      bosAccount: '',
      worbliAccount: '',
      waxAccount: '',
      meetOneAccount: '',
      lynxAccount: ''
    };

    for (let item of result.error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  };

  componentDidMount() {
    window.addEventListener('scroll', this.throttle);
    autosize(this.textarea);

    const jwt = localStorage.getItem('token');
    const accountToken = localStorage.getItem('account');

    if (!jwt || !accountToken) {
      this.props.history.push('/');
    } else {
      try {
        const current = moment().unix();
        let decoded = jwtDecode(jwt);
        if (decoded.exp < current) {
          localStorage.removeItem('token');
          localStorage.removeItem('account');
          this.props.history.push('/');
        }
      } catch (ex) {
        this.props.history.push('/');
        console.log(ex);
      }
    }

    let account = JSON.parse(accountToken);
    if (!account.birthYear) account.birthYear = '';
    account.newPassword = '';
    account.confirmedPassword = '';
    account.currentPassword = '';
    this.setState({ account });

    const {
      avatar,
      intro,
      gender,
      birthYear,
      eosAccount,
      telosAccount,
      bosAccount,
      worbliAccount,
      waxAccount,
      meetOneAccount,
      lynxAccount
    } = account;

    this.setState({
      avatarSwitch: avatar ? true : false,
      introSwitch: intro ? true : false,
      genderSwitch: gender ? true : false,
      birthYearSwitch: birthYear ? true : false,
      eosAccountSwitch: eosAccount ? true : false,
      telosAccountSwitch: telosAccount ? true : false,
      bosAccountSwitch: bosAccount ? true : false,
      worbliAccountSwitch: worbliAccount ? true : false,
      waxAccountSwitch: waxAccount ? true : false,
      meetOneAccountSwitch: meetOneAccount ? true : false,
      lynxAccountSwitch: lynxAccount ? true : false
    });
  }

  componentDidUpdate() {
    autosize(this.textarea);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttle);
  }

  time = Date.now();

  throttle = () => {
    if (this.time + 250 - Date.now() < 0) {
      const height = this.middleContainer.clientHeight;
      const windowHeight = window.innerHeight;
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, 'middleHeight');
      this.time = Date.now();
    }
  };

  handleChange = e => {
    let account = { ...this.state.account };
    account[e.target.name] = e.target.value;

    if (e.target.value === 'male') account.gender = 'female';
    else if (e.target.value === 'female') account.gender = 'male';

    this.setState({ account });
  };

  handleSwitchChange = e => {
    let account = { ...this.state.account };
    let temp = { ...this.state.temp };
    let errors = { ...this.state.errors };
    const dataName = e.target.getAttribute('data-name');

    if (this.state[e.target.name] === true) {
      if (e.target.name === 'avatarSwitch') {
        temp.avatarSrc = null;
        this.setState({ temp });
      }
      if (dataName === 'newPassword') {
        account[dataName] = '';
        account.confirmedPassword = '';
      } else {
        account[dataName] = '';
      }
      errors[dataName] = '';

      this.setState({ [e.target.name]: false, account, errors });
    } else if (this.state[e.target.name] === false) {
      if (dataName === 'gender') {
        if (!account[dataName]) account[dataName] = 'male';
      }
      this.setState({ [e.target.name]: true, account });
    }
  };

  handleAvatarUpload = e => {
    let account = { ...this.state.account };
    account.avatar = e.target.value.slice(12);
    let temp = { ...this.state.temp };

    if (!e.target.value) {
      temp.avatarSrc = null;
      return;
    }

    temp.avatarFile = e.target.files[0];
    let imageSrc = URL.createObjectURL(e.target.files[0]);

    this.setState({ account, temp, imageSrc });
  };

  handleSubmit = async () => {
    const jwt = localStorage.getItem('token');
    const accountToken = localStorage.getItem('account');

    if (!jwt || !accountToken) {
      this.props.history.push('/');
    } else {
      try {
        const current = moment().unix();
        let decoded = jwtDecode(jwt);
        if (decoded.exp < current) {
          localStorage.removeItem('token');
          localStorage.removeItem('account');
          this.props.history.push('/');
        }
      } catch (ex) {
        this.props.history.push('/');
        console.log(ex);
      }
    }

    axios.defaults.headers.common['x-auth-token'] = jwt;

    let errors = this.validate();

    if (
      this.state.account.newPassword !== this.state.account.confirmedPassword
    ) {
      errors.confirmedPassword =
        '*您輸入的確認密碼不一致，請核對與新密碼是否想相同';
    }

    this.setState({ errors });

    for (const err in errors) {
      if (errors[err]) return;
    }

    const temp = { ...this.state.temp };
    const {
      name,
      avatar,
      intro,
      gender,
      birthYear,
      newPassword,
      eosAccount,
      telosAccount,
      bosAccount,
      worbliAccount,
      waxAccount,
      meetOneAccount,
      lynxAccount,
      currentPassword
    } = this.state.account;
    const { userId } = this.state.account;
    const avatarSrc = temp.avatarSrc;

    if (avatarSrc) {
      let avatarFile = new FormData();

      avatarFile.append('avatarFile', temp.avatarFile);

      try {
        const resAvatar = await axios.put(
          `${this.props.apiEndpoint}/account/img/avatar/${userId}`,
          avatarFile
        );
        if (resAvatar.status === 200) console.log('Avatar Upload Successful');
      } catch (ex) {
        console.log(ex);
      }
    }

    let account = {};
    account.name = name;
    account.currentPassword = currentPassword;
    account.avatar = avatar ? avatar : '';
    account.intro = intro ? intro : '';
    account.gender = gender ? gender : '';
    account.birthYear = birthYear ? birthYear : '';
    account.newPassword = newPassword ? newPassword : '';
    account.eosAccount = eosAccount ? eosAccount : '';
    account.telosAccount = telosAccount ? telosAccount : '';
    account.bosAccount = bosAccount ? bosAccount : '';
    account.worbliAccount = worbliAccount ? worbliAccount : '';
    account.waxAccount = waxAccount ? waxAccount : '';
    account.meetOneAccount = meetOneAccount ? meetOneAccount : '';
    account.lynxAccount = lynxAccount ? lynxAccount : '';

    try {
      const resAccount = await axios.put(
        `${this.props.apiEndpoint}/account/update/${userId}`,
        account
      );
      account = resAccount.data;
      if (resAccount.status === 200) {
        localStorage.setItem('account', JSON.stringify(account));
        account.currentPassword = '';
        this.setState(account);
        console.log('Account Update Successful', account);
        this.props.onUpdateSuccess(account);
        this.props.history.push('/account/updated');
      }
    } catch (ex) {
      const errorMessages = ex.response.data;
      let errors = { ...this.state.errors };
      for (let err in errorMessages) {
        errors[err] = errorMessages[err];
      }
      this.setState({ errors });
      return;
    }
  };

  handleCancel = () => {
    console.log('cancel');
    this.setState({
      account: {
        name: '',
        avatar: '',
        birthYear: '',
        gender: '',
        email: '',
        newPassword: '',
        confirmedPassword: '',
        intro: '',
        currentPassword: ''
      }
    });
    this.props.history.push('/');
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = async (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    let temp = { ...this.state.temp };

    let imgSrc = await getCroppedImg(this.state.imageSrc, croppedAreaPixels, 0);
    temp.avatarSrc = imgSrc;
    let file = await fetch(imgSrc)
      .then(r => r.blob())
      .then(
        blobFile =>
          new File([blobFile], 'tempImage', {
            type: 'image/png'
          })
      );
    temp.avatarFile = file;

    this.setState({ temp });
  };

  onZoomChange = zoom => {
    this.setState({ zoom });
  };

  render() {
    let {
      name,
      userId,
      email,
      powerLevel,
      avatar,
      gender,
      birthYear,
      newPassword,
      confirmedPassword,
      intro,
      createdAt,
      updatedAt,
      eosAccount,
      telosAccount,
      bosAccount,
      worbliAccount,
      waxAccount,
      meetOneAccount,
      lynxAccount,
      currentPassword
    } = this.state.account;

    const {
      temp,
      avatarSwitch,
      introSwitch,
      newPasswordSwitch,
      genderSwitch,
      birthYearSwitch,
      eosAccountSwitch,
      telosAccountSwitch,
      bosAccountSwitch,
      worbliAccountSwitch,
      waxAccountSwitch,
      meetOneAccountSwitch,
      lynxAccountSwitch,
      errors
    } = this.state;

    createdAt = moment.unix(createdAt).format('YYYY年M月D號(ddd) Ah點m分s秒');
    createdAt = chineseTimeFormat(createdAt);

    updatedAt = moment.unix(updatedAt).format('YYYY年M月D號(ddd) Ah點m分s秒');
    updatedAt = chineseTimeFormat(updatedAt);

    let lastErrors = [];
    for (const err in errors) {
      if (errors[err]) {
        lastErrors.push(errors[err]);
      }
    }

    return (
      <React.Fragment>
        <div
          style={{ position: 'sticky', top: this.state.sticky }}
          className='account-wrapper'
          ref={middleContainer => {
            this.middleContainer = middleContainer;
          }}
        >
          <div className='accountSnippetTag'>編輯帳號資料</div>

          <div className='account-edit-container'>
            <div className='account-essential'>
              <div className='avatar-container'>
                <div className='avatar'>
                  <img
                    className='avatarImg'
                    src={
                      temp.avatarSrc
                        ? temp.avatarSrc
                        : `/img/accounts/avatars/custom/${userId}.png`
                    }
                    alt={avatar}
                  />
                </div>
              </div>

              <div className='mainInputs'>
                <div className='label'>電郵地址:</div>
                <div className='inputContainer'>{email}</div>
                <div className='q'>
                  <QuestionMark title='您註册的電郵地址不能修改' />
                </div>

                <div className='label'>識別碼:</div>
                <div className='inputContainer'>{userId}</div>
                <div className='q'>
                  <QuestionMark title="由於用戶設定的 '暱稱' 可以與其他使用者相同，因此識別碼用於確認發表者的身分" />
                </div>

                <div className='label'>戦闘力:</div>
                <div className='inputContainer'>{powerLevel}</div>
                <div className='q'>
                  <QuestionMark title="'戦闘力' 是使用者發表文章後，讀者的給您 '愛' 的總數量，換句話說愈多 '愛' 的話 '戦闘力' 愈高，瀏覽量多並不會增加發表者的 '戦闘力'" />
                </div>

                <div className='label'>暱稱:</div>
                <div className='inputContainer'>
                  <input
                    type='text'
                    className='input'
                    name='name'
                    value={name}
                    onChange={e => this.handleChange(e)}
                  />
                  <div className='error'>{errors.name}</div>
                </div>
                <div className='q'>
                  <QuestionMark title='您發言時將會顯示這個名稱，可跟其他人重覆但不能超過16字' />
                </div>
              </div>
            </div>

            <div className='optionalInputs'>
              <div className='label'>編輯頭像:</div>
              <div className='onOffBtn'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    name='avatarSwitch'
                    data-name='avatar'
                    checked={avatarSwitch === true ? 'checked' : ''}
                    onChange={e => this.handleSwitchChange(e)}
                    value={''}
                  />
                  <span className='roundSlider'></span>
                </label>
              </div>
              <div className='inputContainer'>
                {avatarSwitch ? (
                  <div className='avatar-edit-container'>
                    <div className='avatar-edit-control-container'>
                      <div className='crop-container'>
                        {temp.avatarFile ? (
                          <Cropper
                            image={this.state.imageSrc}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.state.aspect}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onZoomChange={this.onZoomChange}
                          />
                        ) : (
                          <img
                            className='img'
                            src={'/img/articleEditor/imgUpload/preview.svg'}
                            alt='upload'
                          />
                        )}
                      </div>
                    </div>
                    <div className='avatar-edit-upload'>
                      <div className='avatar-edit-avatarBtnContainer'>
                        <label
                          className='avatarUploadBtn'
                          htmlFor='upload-avatar-img'
                        >
                          選擇圖片
                        </label>
                        <input
                          type='file'
                          name='avatar'
                          id='upload-avatar-img'
                          onChange={e => this.handleAvatarUpload(e)}
                        />
                      </div>
                      <div className='error' style={{ margin: 10 }}>
                        {errors.avatar}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='q'>
                <QuestionMark title="按下 '選擇圖片' 選擇要上傳的頭像圖片，您可以移動滑鼠的滑輪以放大或縮小頭像，對頭像按實滑鼠左鍵拖著可移動頭像的位置，把它關閉時將自動删除選擇的圖檔" />
              </div>

              <div className='label'>自我介紹:</div>
              <div className='onOffBtn'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    data-name='intro'
                    name='introSwitch'
                    checked={introSwitch ? 'checked' : ''}
                    onChange={e => this.handleSwitchChange(e)}
                    value={''}
                  />
                  <span className='roundSlider'></span>
                </label>
              </div>
              <div>
                {introSwitch ? (
                  <textarea
                    className='textarea'
                    ref={c => (this.textarea = c)}
                    name='intro'
                    value={intro}
                    onChange={e => this.handleChange(e)}
                  ></textarea>
                ) : null}
                <div className='error'>{errors.intro}</div>
              </div>
              <div className='q'>
                <QuestionMark title='介紹一下自己讓別人認識你是什麼人，例如: 您是在哪個機構擔任什麼的職位，或者您在哪一間大學就讀或畢業...等等，這裡填寫的資料會在發表的文章顯示' />
              </div>

              <div className='label'>姓別:</div>
              <div className='onOffBtn'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    data-name='gender'
                    name='genderSwitch'
                    checked={genderSwitch ? 'checked' : ''}
                    onChange={e => this.handleSwitchChange(e)}
                    value={''}
                  />
                  <span className='roundSlider'></span>
                </label>
              </div>
              <div>
                {genderSwitch ? (
                  <div className='radiosContainer'>
                    <label className='genderRadioContainer'>
                      男
                      <input
                        type='radio'
                        name='genderRadio'
                        value={gender}
                        checked={gender === 'male' ? 'checked' : ''}
                        onChange={e => this.handleChange(e)}
                      />
                      <span className='checkmark'></span>
                    </label>

                    <label className='genderRadioContainer'>
                      女
                      <input
                        type='radio'
                        name='genderRadio'
                        value={gender}
                        checked={gender === 'female' ? 'checked' : ''}
                        onChange={e => this.handleChange(e)}
                      />
                      <span className='checkmark'></span>
                    </label>
                  </div>
                ) : null}
              </div>
              <div className='q'>
                <QuestionMark title='您可以告訴別人您是男生還是女生' />
              </div>

              <div className='label'>出生年份:</div>
              <div className='onOffBtn'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    data-name='birthYear'
                    name='birthYearSwitch'
                    checked={birthYearSwitch ? 'checked' : ''}
                    onChange={e => this.handleSwitchChange(e)}
                    value={''}
                  />
                  <span className='roundSlider'></span>
                </label>
              </div>
              <div>
                {birthYearSwitch ? (
                  <input
                    type='text'
                    className='input'
                    name='birthYear'
                    onChange={e => this.handleChange(e)}
                    value={birthYear}
                  />
                ) : null}
                <div className='error'>{errors.birthYear}</div>
              </div>
              <div className='q'>
                <QuestionMark title="您可以輸入您的4位數的出生年份(1900 ~ 2019)，以告訴別人您有多大, i.e. '1999'" />
              </div>

              <div className='label pw'>
                <div className='pw'>修改密碼:</div>
                <div>{newPasswordSwitch ? '確認新密碼:' : null}</div>
              </div>
              <div className='onOffBtn'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    data-name='newPassword'
                    name='newPasswordSwitch'
                    onChange={e => this.handleSwitchChange(e)}
                    value={''}
                  />
                  <span className='roundSlider'></span>
                </label>
              </div>
              <div>
                <div className='inputContainer'>
                  {newPasswordSwitch ? (
                    <input
                      type='password'
                      className='input'
                      name='newPassword'
                      onChange={e => this.handleChange(e)}
                      value={newPassword}
                    />
                  ) : null}
                  <div className='error'>{errors.newPassword}</div>
                </div>
                <div className='inputContainer'>
                  {newPasswordSwitch ? (
                    <div className='pw'>
                      <input
                        type='password'
                        className='input'
                        value={confirmedPassword}
                        name='confirmedPassword'
                        onChange={e => this.handleChange(e)}
                      />
                      <div className='error'>{errors.confirmedPassword}</div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='q'>
                <QuestionMark title='您的密碼必須包括數字、大階及小階英文字母，最少8位，最多30位的字串，可選擇性包括 ! @ # $ % & * 這幾種特別符號' />
              </div>
            </div>

            <div className='network-account-container'>
              <div className='network-account-inputs'>
                <div className='networkLogo'>
                  <img src='/img/accounts/chains/eos.png' alt='EOSIO' />
                </div>
                <div className='label'>EOS 主網帳號:</div>
                <div className='onOffBtn'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      data-name='eosAccount'
                      name='eosAccountSwitch'
                      checked={eosAccountSwitch ? 'checked' : ''}
                      onChange={e => this.handleSwitchChange(e)}
                    />
                    <span className='roundSlider'></span>
                  </label>
                </div>
                <div className='inputContainer'>
                  {eosAccountSwitch ? (
                    <input
                      type='text'
                      className='input'
                      name='eosAccount'
                      onChange={e => this.handleChange(e)}
                      value={eosAccount}
                    />
                  ) : null}
                  <div className='error'>{errors.eosAccount}</div>
                </div>
                <div className='q'>
                  <QuestionMark title='不建議把您的主要帳號填上，您可以在這裡填入 EOSIO 主網帳號，該帳號用於您發表文章後，用戶給你打賞之用，該帳號及其交易記錄會公開給其他使用者查看' />
                </div>

                <div className='networkLogo'>
                  <img src='/img/accounts/chains/telos.png' alt='Telos' />
                </div>
                <div className='label'>Telos 網絡帳號:</div>
                <div className='onOffBtn'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      data-name='telosAccount'
                      name='telosAccountSwitch'
                      checked={telosAccountSwitch ? 'checked' : ''}
                      onChange={e => this.handleSwitchChange(e)}
                    />
                    <span className='roundSlider'></span>
                  </label>
                </div>
                <div className='inputContainer'>
                  {telosAccountSwitch ? (
                    <input
                      type='text'
                      className='input'
                      name='telosAccount'
                      onChange={e => this.handleChange(e)}
                      value={telosAccount}
                    />
                  ) : null}
                  <div className='error'>{errors.telosAccount}</div>
                </div>
                <div className='q'>
                  <QuestionMark title='不建議把您的主要帳號填上，您可以在這裡填入 Telos 網絡帳號，該帳號用於您發表文章後，用戶給你打賞之用，該帳號及其交易記錄會公開給其他使用者查看' />
                </div>

                <div className='networkLogo'>
                  <img src='/img/accounts/chains/bos.png' alt='BOS' />
                </div>
                <div className='label'>BOS 網絡帳號:</div>
                <div className='onOffBtn'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      data-name='bosAccount'
                      name='bosAccountSwitch'
                      checked={bosAccountSwitch ? 'checked' : ''}
                      onChange={e => this.handleSwitchChange(e)}
                    />
                    <span className='roundSlider'></span>
                  </label>
                </div>
                <div className='inputContainer'>
                  {bosAccountSwitch ? (
                    <input
                      type='text'
                      className='input'
                      name='bosAccount'
                      onChange={e => this.handleChange(e)}
                      value={bosAccount}
                    />
                  ) : null}
                  <div className='error'>{errors.bosAccount}</div>
                </div>
                <div className='q'>
                  <QuestionMark title='不建議把您的主要帳號填上，您可以在這裡填入 BOS 網絡帳號，該帳號用於您發表文章後，用戶給你打賞之用，該帳號及其交易記錄會公開給其他使用者查看' />
                </div>

                <div className='networkLogo'>
                  <img src='/img/accounts/chains/worbli.png' alt='Worbli' />
                </div>
                <div className='label'>Worbli 網絡帳號:</div>
                <div className='onOffBtn'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      data-name='worbliAccount'
                      name='worbliAccountSwitch'
                      checked={worbliAccountSwitch ? 'checked' : ''}
                      onChange={e => this.handleSwitchChange(e)}
                    />
                    <span className='roundSlider'></span>
                  </label>
                </div>
                <div className='inputContainer'>
                  {worbliAccountSwitch ? (
                    <input
                      type='text'
                      className='input'
                      name='worbliAccount'
                      onChange={e => this.handleChange(e)}
                      value={worbliAccount}
                    />
                  ) : null}
                  <div className='error'>{errors.worbliAccount}</div>
                </div>
                <div className='q'>
                  <QuestionMark title='不建議把您的主要帳號填上，您可以在這裡填入 Worbli 網絡帳號，該帳號用於您發表文章後，用戶給你打賞之用，該帳號及其交易記錄會公開給其他使用者查看' />
                </div>

                <div className='networkLogo'>
                  <img src='/img/accounts/chains/wax.png' alt='wax' />
                </div>
                <div className='label'>WAX 網絡帳號:</div>
                <div className='onOffBtn'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      data-name='waxAccount'
                      name='waxAccountSwitch'
                      checked={waxAccountSwitch ? 'checked' : ''}
                      onChange={e => this.handleSwitchChange(e)}
                    />
                    <span className='roundSlider'></span>
                  </label>
                </div>
                <div className='inputContainer'>
                  {waxAccountSwitch ? (
                    <input
                      type='text'
                      className='input'
                      name='waxAccount'
                      onChange={e => this.handleChange(e)}
                      value={waxAccount}
                    />
                  ) : null}
                  <div className='error'>{errors.waxAccount}</div>
                </div>
                <div className='q'>
                  <QuestionMark title='不建議把您的主要帳號填上，您可以在這裡填入 WAX 網絡帳號，該帳號用於您發表文章後，用戶給你打賞之用，該帳號及其交易記錄會公開給其他使用者查看' />
                </div>

                <div className='networkLogo'>
                  <img src='/img/accounts/chains/meetOne.png' alt='MeetOne' />
                </div>
                <div className='label'>MeetOne 網絡帳號:</div>
                <div className='onOffBtn'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      data-name='meetOneAccount'
                      name='meetOneAccountSwitch'
                      checked={meetOneAccountSwitch ? 'checked' : ''}
                      onChange={e => this.handleSwitchChange(e)}
                    />
                    <span className='roundSlider'></span>
                  </label>
                </div>
                <div className='inputContainer'>
                  {meetOneAccountSwitch ? (
                    <input
                      type='text'
                      className='input'
                      name='meetOneAccount'
                      onChange={e => this.handleChange(e)}
                      value={meetOneAccount}
                    />
                  ) : null}
                  <div className='error'>{errors.meetOneAccount}</div>
                </div>
                <div className='q'>
                  <QuestionMark title='不建議把您的主要帳號填上，您可以在這裡填入 MeetOne 網絡帳號，該帳號用於您發表文章後，用戶給你打賞之用，該帳號及其交易記錄會公開給其他使用者查看' />
                </div>

                <div className='networkLogo'>
                  <img src='/img/accounts/chains/lynx.png' alt='Lynx' />
                </div>
                <div className='label'>Lynx 網絡帳號:</div>
                <div className='onOffBtn'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      data-name='lynxAccount'
                      name='lynxAccountSwitch'
                      checked={lynxAccountSwitch ? 'checked' : ''}
                      onChange={e => this.handleSwitchChange(e)}
                    />
                    <span className='roundSlider'></span>
                  </label>
                </div>
                <div className='inputContainer'>
                  {lynxAccountSwitch ? (
                    <input
                      type='text'
                      className='input'
                      name='lynxAccount'
                      onChange={e => this.handleChange(e)}
                      value={lynxAccount}
                    />
                  ) : null}
                  <div className='error'>{errors.lynxAccount}</div>
                </div>
                <div className='q'>
                  <QuestionMark title='不建議把您的主要帳號填上，您可以在這裡填入 Lynx 網絡帳號，該帳號用於您發表文章後，用戶給你打賞之用，該帳號及其交易記錄會公開給其他使用者查看' />
                </div>
              </div>
            </div>

            <div className='account-essential'>
              <div className='mainInputs bottom'>
                <div className='label'>最後更新於:</div>
                <div className='label'>{updatedAt}</div>
                <div className='q'>
                  <QuestionMark title='這裡顯示你最後的修改時間' />
                </div>

                <div className='label'>建立於:</div>
                <div className='label'>{createdAt}</div>
                <div className='q'>
                  <QuestionMark title='這裡顥示您什麼時候註册柚子市帳號' />
                </div>

                <div className='label'>現時密碼:</div>
                <div className='inputContainer'>
                  <input
                    type='password'
                    className='input'
                    name='currentPassword'
                    onKeyDown={e => {
                      if (e.keyCode === 13 && e.shiftKey === false) {
                        e.preventDefault();
                        this.handleSubmit();
                      }
                    }}
                    onChange={e => this.handleChange(e)}
                    value={currentPassword}
                  />

                  <div className='error'>{errors.currentPassword}</div>
                </div>
                <div className='q'>
                  <QuestionMark title='請輸入密碼以確認是您本人提交修改' />
                </div>
              </div>
            </div>

            <div>
              {errors
                ? lastErrors.map((error, index) => (
                    <div key={index} className='error'>
                      {error}
                    </div>
                  ))
                : null}
            </div>

            <div className='account-edit-submitContainer'>
              <div className='btn' onClick={() => this.handleSubmit()}>
                提交修改
              </div>
              <div className='btn cancel' onClick={() => this.handleCancel()}>
                取消
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountEdit;
