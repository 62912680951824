import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import NavBar from './components/navBar';
import SnippetList from './components/snippetList';
import BannersAndThumbnails from './components/bannersAndThumbnails';

class Home extends Component {
  state = {
    version: 'official'
  };
  render() {
    let {
      account,
      coveredBanners,
      currentBanner,
      onThumbHover,
      articleTypeLabel,
      onUpdateSnippetList,
      officialSnippetList,
      onCoveredBannerInputChange,
      onCoveredBannerSubmit
    } = this.props;
    const { version } = this.state;

    return (
      <React.Fragment>
        <BannersAndThumbnails
          account={account}
          coveredBanners={coveredBanners}
          currentBanner={currentBanner}
          onThumbHover={onThumbHover}
          onCoveredBannerInputChange={onCoveredBannerInputChange}
          onCoveredBannerSubmit={onCoveredBannerSubmit}
          version={version}
        />

        <div id='coverZone' className='main active'>
          <div className='articleZone'>
            <NavBar
              articleTypeLabel={articleTypeLabel}
              onUpdateSnippetList={onUpdateSnippetList}
            />
            <SnippetList snippetList={officialSnippetList} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
