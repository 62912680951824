import React, { Component } from 'react';
import autosize from 'autosize';
import QuestionMark from '../../../../common/icons/questionMark';

class Subtitle1 extends Component {
  componentDidMount() {
    autosize(this.textarea);
  }

  render() {
    const { onSnippetChange, onSnippetDelete, value, index } = this.props;
    const info = '最大的副標題, 尺寸僅次於主標題';

    return (
      <div className='articleEdit-editor-inputContainer'>
        <div className='articleEdit-editor-inputTitleContainer'>
          <div className='title'>副標題1</div>
          <div className='articleEdit-editor-input-rightContainer'>
            <QuestionMark title={info} />
            <i
              className='fa fa-trash icon'
              onClick={() => onSnippetDelete(index)}
            ></i>
          </div>
        </div>
        <textarea
          ref={c => (this.textarea = c)}
          onChange={e => onSnippetChange(e, index, 'text')}
          value={value}
        ></textarea>
      </div>
    );
  }
}

export default Subtitle1;
