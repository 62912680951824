import React, { Component } from 'react';

class InsertBar extends Component {
  state = {
    buttons: [
      { blockType: 'subtitle', label: '副標題' },
      { blockType: 'intro', label: '簡介段落' },
      { blockType: 'content', label: '內容段落' },
      { blockType: 'link', label: '連結' },
      { blockType: 'recommendSnippet', label: '推介方塊' }
    ]
  };
  render() {
    const {
      blockIndex,
      method,
      onBlockTypeChange,
      onInsertBarSwitch
    } = this.props;
    const { buttons } = this.state;
    return (
      <div className='middle-navigator-edit-insert-container'>
        <div className='title'>
          <i className='fa fa-arrow-up arrow' /> &nbsp;選擇插入內容類型
        </div>
        <div className='btnContainer'>
          {buttons.map((btn, index) => (
            <div
              key={index}
              className='btn'
              onClick={() => {
                onBlockTypeChange(
                  blockIndex,
                  method,
                  btn.blockType,
                  onInsertBarSwitch
                );
              }}
            >
              {btn.label}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default InsertBar;
