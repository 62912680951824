import React, { Component } from 'react';
import moment from 'moment';
import { chineseTimeFormat } from '../../../../../../../utils/chineseTimeFormat';
import autosize from 'autosize';

class ArticleReplies extends Component {
  componentDidMount() {
    autosize(this.textarea);
  }
  componentWillUnmount() {
    this.props.onResetArticleBlock();
  }

  render() {
    let {
      errors,
      articleId,
      onReply,
      onReplySubmit,
      onEmbeddedReply,
      onEmbeddedReplySubmit,
      openEmbeddedReplyInput,
      reply,
      replies,
      embeddedReply,
      openEmbeddedReplyMessages
    } = this.props;

    const { name, userId, powerLevel } = this.props.account;

    return (
      <div className='articleReplies-container'>
        <div className='replyTitle'>
          {name ? `${name} 想發表意見嗎?` : '立即登入發表意見'}
        </div>

        {name ? (
          <div>
            <div className='replyInputContainer'>
              <div className='avatarContainer'>
                <img
                  src={
                    name
                      ? `/img/accounts/avatars/custom/${userId}.png`
                      : '/img/accounts/avatars/default/eosCity.svg'
                  }
                  alt={'avatar'}
                />
              </div>
              <div className='input'>
                <textarea
                  className='textarea'
                  ref={c => (this.textarea = c)}
                  onKeyDown={e => {
                    if (e.keyCode === 13 && e.shiftKey === false) {
                      e.preventDefault();
                      onReplySubmit(articleId);
                    }
                  }}
                  onChange={e => onReply(e)}
                  value={reply}
                ></textarea>
              </div>
            </div>
            <div className='submitContainer'>
              <div className='error'> {errors.reply} </div>
              <div className='btn' onClick={() => onReplySubmit(articleId)}>
                發表
              </div>
            </div>
          </div>
        ) : null}

        <div className='replyTitle bottom'>
          {replies.length ? '讀者意見' : '目前沒有人發表意見'}
        </div>
        {replies.map((reply, index) => (
          <div key={index} className='replyMessageContainer'>
            <div className='avatarContainer'>
              <img
                src={`/img/accounts/avatars/custom/${reply.userId}.png`}
                alt={reply.name}
              />
            </div>
            <div className='messageContainer'>
              <div className='idContainer'>
                <div className='name'>
                  {reply.name} <span className='id'>id {reply.userId}</span>
                </div>
                <div className='small'>戦闘力: {reply.powerLevel}</div>
              </div>
              <div className='message'>{reply.message}</div>
              <div className='bottomContainer'>
                <div className='replyBtnContainer'>
                  <div
                    className='replyBtn'
                    onClick={() => openEmbeddedReplyInput(reply._id)}
                  >
                    回覆
                  </div>
                  {reply.replyMessages.length === 0 ? null : (
                    <div
                      className='replyBtn'
                      onClick={() => openEmbeddedReplyMessages(reply._id)}
                    >
                      查看回覆({reply.replyMessages.length})
                    </div>
                  )}
                </div>
                <div className='replyBtn num'>
                  {chineseTimeFormat(
                    moment
                      .unix(reply.publishTime)
                      .format('YYYY年M月D號(ddd) Ah點m分s秒')
                  )}
                </div>
              </div>
              <div
                className={
                  !reply.open
                    ? 'embeddedReplyInputContainer none'
                    : 'embeddedReplyInputContainer'
                }
              >
                <div className='avatarContainer'>
                  <img
                    src={`/img/accounts/avatars/custom/${userId}.png`}
                    alt={userId}
                  />
                </div>
                <div className='inputContainer'>
                  <div className='nameContainer'>
                    <div className='name'>
                      {name ? (
                        <div>
                          {name}{' '}
                          <span
                            style={{
                              color: '#cc0066',
                              fontWeight: 'bold',
                              fontFamily: 'Ubuntu, sans-serif'
                            }}
                          >
                            id {userId}
                          </span>
                        </div>
                      ) : (
                        '立即登入發表回覆'
                      )}
                    </div>
                    <div className='small'>戦闘力 {powerLevel}</div>
                  </div>
                  <div className='input'>
                    <textarea
                      name={reply._id}
                      className='textarea2'
                      onKeyDown={e => {
                        if (e.keyCode === 13 && e.shiftKey === false) {
                          e.preventDefault();
                          onEmbeddedReplySubmit(reply._id, articleId);
                        }
                      }}
                      onChange={e => onEmbeddedReply(e)}
                      value={embeddedReply[reply._id]}
                    ></textarea>
                  </div>
                  <div className='replyBtnContainer'>
                    <div
                      className='replyBtn'
                      onClick={() => {
                        onEmbeddedReplySubmit(reply._id, articleId);
                      }}
                    >
                      發表回覆
                    </div>
                  </div>
                </div>
              </div>
              {!reply.replyMessages.length
                ? null
                : !reply.openReplyMessages
                ? null
                : reply.replyMessages.map((snippet, index) => (
                    <div key={index} className='embeddedReplyInputContainer'>
                      <div className='avatarContainer'>
                        <img
                          src={`/img/accounts/avatars/custom/${snippet.userId}.png`}
                          alt='avatar'
                        />
                      </div>
                      <div className='inputContainer'>
                        <div className='nameContainer'>
                          <div className='name'>
                            {snippet.name}{' '}
                            <span className='id'>id {snippet.userId}</span>
                          </div>
                          <div className='small'>戦闘力 {powerLevel}</div>
                          <div className='small time'>
                            {chineseTimeFormat(
                              moment
                                .unix(snippet.publishTime)
                                .format('YYYY年M月D號(ddd) Ah點mm分ss秒')
                            )}
                          </div>
                        </div>
                        <div className='message'>{snippet.message}</div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default ArticleReplies;
