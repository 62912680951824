import React from "react";
import Board from "../components/board";
import Ads from "../components/ads";

const News = ({ leftPanelActive, infoList, noticeList, meetupList }) => {
  return (
    <div className={leftPanelActive === "消息" ? "content active" : "content"}>
      {infoList.length !== 0 ? (
        <Board label="最新消息" content={infoList} />
      ) : null}

      {meetupList.length !== 0 ? (
        <Board label="Meet Up" content={meetupList} />
      ) : null}

      <Ads
        aHref="https://www.tokenpocket.pro"
        imgSrc="/img/nav1_ads/leftAds1.png"
        label="Token Pocket"
      />

      {noticeList.length !== 0 ? (
        <Board label="站內公告" content={noticeList} />
      ) : null}

      <Ads
        aHref="http://t.cn/ES2ggI7"
        imgSrc="/img/nav1_ads/leftAds7.png"
        label="Sword & Magic"
      />

      <Ads
        aHref="https://eospark.com/"
        imgSrc="/img/nav1_ads/leftAds6.png"
        label="EOS Park"
      />

      <Ads
        aHref="https://www.teloscanton.io/"
        imgSrc="/img/nav1_ads/leftAds2.png"
        label="Telos 廣東"
      />

      <Ads
        aHref="https://imeos.one/"
        imgSrc="/img/nav1_ads/leftAds3.png"
        label="IMEOS"
      />

      <br />
    </div>
  );
};

export default News;
