import React, { Component } from 'react';
import InsertSubBlockBar from './insertSubBlockBar';

class LinkEditSubBlock extends Component {
  state = {
    insertBarSwitch: false
  };

  handleInsertBarSwitch = specified => {
    let insertBarSwitch;
    if (!specified) {
      insertBarSwitch = !this.state.insertBarSwitch;
    } else {
      insertBarSwitch = specified;
    }
    this.setState({ insertBarSwitch });
  };

  render() {
    const { insertBarSwitch } = this.state;
    const {
      subBlock,
      blockIndex,
      subBlockIndex,
      onSubBlockTypeChange,
      onBlockInputChange
    } = this.props;
    return (
      <div className='middle-navigator-edit-snippet-content-subContainer'>
        {insertBarSwitch ? (
          <InsertSubBlockBar
            blockIndex={blockIndex}
            subBlockIndex={subBlockIndex}
            method={'insert'}
            onSubBlockTypeChange={onSubBlockTypeChange}
            onInsertBarSwitch={this.handleInsertBarSwitch}
          />
        ) : null}
        <div
          className='middle-navigator-edit-paragraph-upper-container'
          style={{ paddingRight: 13 }}
        >
          <div className='middle-navigator-edit-snippet-content-label'>
            連結
          </div>
          <div className='middle-navigator-edit-snippet-right-icon-rightSide'>
            <div
              className='middle-navigator-edit-snippet-right-icon-container small'
              onClick={() => this.handleInsertBarSwitch()}
            >
              {insertBarSwitch ? (
                <i className='fa fa-minus plusIcon small' />
              ) : (
                <i className='fa fa-plus plusIcon small' />
              )}
            </div>
            <div
              className='middle-navigator-edit-snippet-right-icon-container small'
              onClick={() =>
                onSubBlockTypeChange(blockIndex, subBlockIndex, 'remove', null)
              }
            >
              <i className='fa fa-trash trashIcon small' />
            </div>
          </div>
        </div>

        <div className='middle-navigator-edit-snippet-content-input link'>
          <div style={{ marginBottom: 8 }}>
            <label className='middle-navigator-edit-snippet-content-link-label'>
              標籤{' '}
            </label>
            <input
              type='text'
              name='label'
              className='middle-navigator-edit-snippet-content-link-label-input'
              onChange={e =>
                onBlockInputChange(e, 'linkSubBlock', blockIndex, subBlockIndex)
              }
              value={subBlock.label}
            />
          </div>
          <div>
            <label className='middle-navigator-edit-snippet-content-link-label'>
              URL{' '}
            </label>
            <input
              type='text'
              name='url'
              className='middle-navigator-edit-snippet-content-link-label-input url'
              onChange={e =>
                onBlockInputChange(e, 'linkSubBlock', blockIndex, subBlockIndex)
              }
              value={subBlock.url}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LinkEditSubBlock;
