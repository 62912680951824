import React from 'react';
import QuestionMark from './../../../../../../common/icons/questionMark';

const AuthorSnippetBottom = ({
  update,
  author,
  account,
  love,
  loves,
  publishTimeS,
  updatedTimeS,
  onLove,
  articleId,
  endpoint
}) => {
  const {
    name,
    intro,
    userId,
    powerLevel,
    eosAccount,
    telosAccount,
    bosAccount,
    worbliAccount,
    waxAccount,
    meetOneAccount,
    lynxAccount
  } = account;
  const shareLink = articleId
    ? `${endpoint}/article/${articleId}`
    : `${endpoint}`;
  return (
    <div className='article-authorSnippet-bottom-container'>
      <div className='article-authorSnippet-bottom'>
        <div className='avatarContainer'>
          <img
            src={
              update
                ? `/img/accounts/avatars/custom/${author.userId}.png`
                : `/img/accounts/avatars/custom/${userId}.png`
            }
            alt={userId}
          />
        </div>
        <div className='authorInfoContainer'>
          <div className='firstRow'>
            <div>
              {update ? (
                <div className='large'>
                  {author.name}{' '}
                  <span className='small id'>id {author.userId}</span>
                </div>
              ) : (
                <div className='large'>
                  {name} <span className='small id'>id {userId}</span>
                </div>
              )}
              <div className='small'>
                戦闘力 {update ? author.powerLevel : powerLevel}
              </div>
            </div>
            <div className='loveContainer'>
              <i
                className={love ? 'loveIcon' : 'loveIcon mono'}
                onClick={() => {
                  onLove(articleId);
                }}
              />
              <div className='small'>{loves}</div>
            </div>
          </div>
          <div className='firstRow'>
            <div className='small'> {update ? author.intro : intro} </div>
          </div>
        </div>
      </div>
      {eosAccount ||
      telosAccount ||
      bosAccount ||
      worbliAccount ||
      waxAccount ||
      meetOneAccount ||
      lynxAccount ? (
        <div className='tipsTitleContainer'>
          <div className='title'>打賞給 {update ? author.name : name}</div>
          <div className='query'>
            <QuestionMark
              title={`您可以直接打款去以下帳號以支持 ${name} 創作文章或報告消息，如果下面沒有帳號 (留了空白) 代表發表者沒有加上打賞帳號`}
            />
          </div>
        </div>
      ) : null}

      <div className='tipsContainer'>
        {eosAccount ? (
          <div>
            <img
              className='chainLogo'
              src='/img/accounts/chains/eos.png'
              alt='EOS'
            />
          </div>
        ) : null}
        {eosAccount ? <div className='account'>{eosAccount}</div> : null}

        {telosAccount ? (
          <div>
            <img
              className='chainLogo'
              src='/img/accounts/chains/telos.png'
              alt='Telos'
            />
          </div>
        ) : null}
        {telosAccount ? <div className='account'>{telosAccount}</div> : null}

        {bosAccount ? (
          <div>
            <img
              className='chainLogo'
              src='/img/accounts/chains/bos.png'
              alt='BOS'
            />
          </div>
        ) : null}
        {bosAccount ? <div className='account'>{bosAccount}</div> : null}

        {worbliAccount ? (
          <div>
            <img
              className='chainLogo'
              src='/img/accounts/chains/worbli.png'
              alt='Worbli'
            />
          </div>
        ) : null}
        {worbliAccount ? <div className='account'>{worbliAccount} </div> : null}

        {waxAccount ? (
          <div>
            <img
              className='chainLogo'
              src='/img/accounts/chains/wax.png'
              alt='WAX'
            />
          </div>
        ) : null}
        {waxAccount ? <div className='account'>{waxAccount}</div> : null}

        {meetOneAccount ? (
          <div>
            <img
              className='chainLogo'
              src='/img/accounts/chains/meetOne.png'
              alt='MeetOne'
            />
          </div>
        ) : null}
        {meetOneAccount ? (
          <div className='account'>{meetOneAccount}</div>
        ) : null}

        {lynxAccount ? (
          <div>
            <img
              className='chainLogo'
              src='/img/accounts/chains/lynx.png'
              alt='Lynx'
            />
          </div>
        ) : null}
        {lynxAccount ? <div className='account'>{lynxAccount}</div> : null}
      </div>
      <div className='firstRow'>
        <div>
          首次發表:
          <br /> {publishTimeS}{' '}
        </div>
        <div>
          最後更新:
          <br /> {updatedTimeS}{' '}
        </div>
      </div>
      <div className='link'>
        分享連結:
        <br />
        <a href={shareLink}>{shareLink}</a>
      </div>
    </div>
  );
};

export default AuthorSnippetBottom;
