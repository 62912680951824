import React, { Component } from 'react';

class ContactUrlBar extends Component {
  render() {
    const { contactBar } = this.props;
    return (
      <div className='middle-navigator-recommendSnippet-right-contactUrlIconBar-container'>
        {contactBar.map((icon, index) => (
          <a
            key={index}
            href={icon.url}
            className='middle-navigator-recommendSnippet-right-contactUrlIcon-container'
          >
            <img
              className='middle-navigator-recommendSnippet-right-contactUrlIcon'
              src={`/img/navigator/${icon.media}.svg`}
              alt={icon.media}
            />
            <div className='middle-navigator-recommendSnippet-right-contactUrlIcon-label'>
              {icon.label}
            </div>
          </a>
        ))}
      </div>
    );
  }
}

export default ContactUrlBar;
