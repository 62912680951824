import React from "react";

const Youtube = ({ content }) => {
  const link = content.text;
  let videoHash = "";
  if (link.includes("https://www.youtube.com/watch?v=")) {
    videoHash = link.replace("https://www.youtube.com/watch?v=", "");
  } else if (link.includes("https://youtu.be/")) {
    videoHash = link.replace("https://youtu.be/", "");
  }

  return (
    <div>
      <div>
        <iframe
          title={videoHash}
          width="710"
          height="400"
          src={`https://www.youtube.com/embed/${videoHash}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Youtube;
