import React, { Component } from 'react';
import TitleEditSubBlock from './titleEditSubBlock';
import IntroEditSubBlock from './introEditSubBlock';
import ContentEditSubBlock from './contentEditSubBlock';
import LinkEditSubBlock from './linkEditSubBlock';

class SubBlockTemplate extends Component {
  render() {
    const {
      subBlock,
      blockIndex,
      subBlockIndex,
      onSubBlockTypeChange,
      onBlockInputChange
    } = this.props;

    const components = {
      title: TitleEditSubBlock,
      intro: IntroEditSubBlock,
      content: ContentEditSubBlock,
      link: LinkEditSubBlock
    };

    const Tag = components[subBlock.subBlockType];

    return (
      <Tag
        subBlock={subBlock}
        blockIndex={blockIndex}
        subBlockIndex={subBlockIndex}
        onSubBlockTypeChange={onSubBlockTypeChange}
        onBlockInputChange={onBlockInputChange}
      />
    );
  }
}

export default SubBlockTemplate;
