import React from 'react';
import Subtitle1 from '../renderCollections/subtitle1';
import Subtitle2 from '../renderCollections/subtitle2';
import Subtitle3 from '../renderCollections/subtitle3';
import Youtube from '../renderCollections/youtube';
import Content from '../renderCollections/content';
import Link from '../renderCollections/link';
import Picture from '../renderCollections/picture';
import Code from '../renderCollections/code';

const RenderTemplate = ({ tag, content }) => {
  const components = {
    subtitle1: Subtitle1,
    subtitle2: Subtitle2,
    subtitle3: Subtitle3,
    youtube: Youtube,
    content: Content,
    link: Link,
    picture: Picture,
    code: Code
  };

  const Tag = components[tag];

  return <Tag content={content} />;
};

export default RenderTemplate;
