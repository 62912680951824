import React, { Component } from 'react';
import autosize from 'autosize';
import InsertBar from './insertBar';

class ContentEditBlock extends Component {
  state = {
    insertBarSwitch: false
  };

  componentDidMount() {
    autosize(this.textarea);
  }

  handleInsertBarSwitch = specified => {
    let insertBarSwitch;
    if (!specified) {
      insertBarSwitch = !this.state.insertBarSwitch;
    } else {
      insertBarSwitch = specified;
    }
    this.setState({ insertBarSwitch });
  };

  render() {
    const { insertBarSwitch } = this.state;
    const { index, block, onBlockInputChange, onBlockTypeChange } = this.props;
    return (
      <div className='middle-navigator-edit-paragraph-container'>
        {insertBarSwitch ? (
          <InsertBar
            blockIndex={index}
            method={'insert'}
            onBlockTypeChange={onBlockTypeChange}
            onInsertBarSwitch={this.handleInsertBarSwitch}
          />
        ) : null}

        <div className='middle-navigator-edit-paragraph-upper-container'>
          <div className='middle-navigator-edit-paragraph-tag-container'>
            內容段落
          </div>
          <div className='middle-navigator-edit-snippet-right-icon-rightSide'>
            <div
              className='middle-navigator-edit-snippet-right-icon-container'
              onClick={() => this.handleInsertBarSwitch()}
            >
              {insertBarSwitch ? (
                <i className='fa fa-minus minusIcon' />
              ) : (
                <i className='fa fa-plus plusIcon' />
              )}
            </div>
            <div
              className='middle-navigator-edit-snippet-right-icon-container'
              onClick={() => onBlockTypeChange(index, 'remove', null)}
            >
              <i className='fa fa-trash trashIcon' />
            </div>
          </div>
        </div>
        <textarea
          ref={c => (this.textarea = c)}
          className='middle-navigator-edit-paragraph-input contentParagraph'
          onChange={e => onBlockInputChange(e, 'text', index, null)}
          defaultValue={block.content}
        />
      </div>
    );
  }
}

export default ContentEditBlock;
