import React from 'react';

const Market = ({
  leftPanelActive,
  market,
  prevMarket,
  eosMarket,
  prevEosMarket,
  forkMarket,
  prevForkMarket,
  fiatMarket,
  prevFiatMarket
}) => {
  return (
    <div className={leftPanelActive === '行情' ? 'content active' : 'content'}>
      <div className='left-market-container'>
        <div className='left-title market'>EOS兌主要貨幣</div>
        <div className='left-first-priceList-container'>
          <div className='coinContainer title'>
            <div className='coin'>主要貨幣</div>
            <div className='price'>價格</div>
            <div className='price'></div>
          </div>
          {fiatMarket ? (
            <div>
              <a className='coinContainer' href={fiatMarket[1].url}>
                <div className='coin'>比特幣</div>
                <div
                  className={
                    fiatMarket[1].last > prevFiatMarket[1].last
                      ? 'price up'
                      : fiatMarket[1].last < prevFiatMarket[1].last
                      ? 'price down'
                      : 'price'
                  }
                >
                  ₿ {fiatMarket[1].last}
                </div>
                <div
                  className={
                    fiatMarket[1].last > prevFiatMarket[1].last
                      ? 'price up'
                      : fiatMarket[1].last < prevFiatMarket[1].last
                      ? 'price down'
                      : 'price'
                  }
                >
                  <span>
                    {fiatMarket[1].last > prevFiatMarket[1].last ? (
                      <span>&#9650;</span>
                    ) : fiatMarket[1].last < prevFiatMarket[1].last ? (
                      <span>&#9660;</span>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </a>
              <a className='coinContainer' href={fiatMarket[1].url}>
                <div className='coin'>新台幣</div>
                <div className='price'>
                  NT$ {(fiatMarket[0].last * 30.03).toFixed(1)}
                </div>
                <div className='price'></div>
              </a>
              <a className='coinContainer' href={fiatMarket[1].url}>
                <div className='coin'>港元</div>
                <div className='price'>
                  HK$ {(fiatMarket[0].last * 7.78).toFixed(2)}
                </div>
                <div className='price'></div>
                <div className='price'></div>
              </a>
              <a className='coinContainer' href={fiatMarket[1].url}>
                <div className='coin'>人民幣</div>
                <div className='price'>
                  ¥ {(fiatMarket[0].last * 6.95).toFixed(2)}
                </div>
                <div className='price'></div>
              </a>
              <a className='coinContainer' href={fiatMarket[0].url}>
                <div className='coin'>美元</div>
                <div
                  className={
                    fiatMarket[0].last > prevFiatMarket[0].last
                      ? 'price up'
                      : fiatMarket[0].last < prevFiatMarket[0].last
                      ? 'price down'
                      : 'price'
                  }
                >
                  US$ {fiatMarket[0].last}
                </div>
                <div
                  className={
                    fiatMarket[0].last > prevFiatMarket[0].last
                      ? 'price up'
                      : fiatMarket[0].last < prevFiatMarket[0].last
                      ? 'price down'
                      : 'price'
                  }
                >
                  <span>
                    {fiatMarket[0].last > prevFiatMarket[0].last ? (
                      <span>&#9650;</span>
                    ) : fiatMarket[0].last < prevFiatMarket[0].last ? (
                      <span>&#9660;</span>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </a>
            </div>
          ) : null}
        </div>

        <div className='left-title market'>分叉幣兌EOS</div>
        <div className='left-priceList-container'>
          <div className='coinContainer title'>
            <div className='coin'>幣種</div>
            <div className='price'>價格(EOS)</div>
            <div className='volume'>成交量</div>
          </div>
          {forkMarket.length && prevForkMarket.length
            ? forkMarket.map((coin, index) => (
                <a
                  key={index}
                  className={
                    coin.last > prevForkMarket[index].last
                      ? 'coinContainer up'
                      : coin.last < prevForkMarket[index].last
                      ? 'coinContainer down'
                      : 'coinContainer'
                  }
                  href={coin.url}
                >
                  <div className='coin'>{coin.currency}</div>
                  <div className='price'>{coin.last}</div>
                  <div className='volume'>{coin.volume.toFixed(1)}</div>
                </a>
              ))
            : null}
        </div>

        <div className='left-title market'>合約幣兌EOS</div>
        <div className='left-priceList-container'>
          <div className='coinContainer title'>
            <div className='coin'>幣種</div>
            <div className='price'>價格(EOS)</div>
            <div className='volume'>成交量</div>
          </div>
          {eosMarket.length && prevEosMarket.length
            ? eosMarket.map((coin, index) => (
                <a
                  key={index}
                  className={
                    coin.last > prevEosMarket[index].last
                      ? 'coinContainer up'
                      : coin.last < prevEosMarket[index].last
                      ? 'coinContainer down'
                      : 'coinContainer'
                  }
                  href={coin.url}
                >
                  <div className='coin'>{coin.currency}</div>
                  <div className='price'>{coin.last}</div>
                  <div className='volume'>{coin.volume.toFixed(1)}</div>
                </a>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default Market;
