import React, { Component } from 'react';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import '../css/boardTable.css';
import '../css/dynamicPanel.css';
import { chineseTimeFormat } from '../../../../../utils/chineseTimeFormat';

class BoardAccount extends Component {
  handleExpand = type => {
    let updatedType = !this.state[type];
    this.setState({ [type]: updatedType });
  };

  currentTime = moment().unix();

  render() {
    const {
      panel,
      account,
      onLoggedOut,
      onLoginPanelChange,
      myArticles,
      onArticleSelected,
      onArticleDelete
    } = this.props;

    let createdAt = moment
      .unix(account.createdAt)
      .format('YYYY年M月D號(ddd) Ah點m分s秒');
    createdAt = chineseTimeFormat(createdAt);

    let updatedAt = moment
      .unix(account.updatedAt)
      .format('YYYY年M月D號(ddd) Ah點m分s秒');

    updatedAt = chineseTimeFormat(updatedAt);

    return (
      <div className='nav2-subZone'>
        {!account.validated && account.name ? (
          <div>
            <div className='notValidate-message'>
              確認電郵已經寄出，如果十五分鐘收沒有收到確認電郵，請按以下
              '發出確認電郵'
              按鈕，再等十五分鐘查看註册電郵，任何沒有經過電郵確認的帳號均不可在聊天室發言、發表文章、回覆訊息及給
              "Love"。
            </div>
            <div className='notValidate-message warning'>
              請在註册後 24
              小時內進行電郵確認，沒有經過電郵確認的帳號將會在24小時後自動被删除
            </div>
            {this.currentTime > account.updatedAt + 900 ? (
              <div className='right-confirmation-btn-container'>
                <span
                  className='right-confirmation-btn'
                  onClick={() => this.props.onConfirmationResend()}
                >
                  發出確認電郵
                </span>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className='right-control-panel-btns-container'>
          <Link
            to={
              account.admin || account.moderator
                ? '/article/editor/new/official'
                : '/article/editor/new/community'
            }
            className='right-control-panel-btn-container'
          >
            <img
              className='right-control-panel-btn-img'
              src='/img/buttons/controlPanel/writeArticle.svg'
              alt='發表文章'
            />
            發表文章
          </Link>
          <Link
            to='/account/edit'
            className='right-control-panel-btn-container'
            onClick={() => window.scrollTo(0, 0)}
          >
            <img
              className='right-control-panel-btn-img'
              src='/img/buttons/controlPanel/editAccount.svg'
              alt='編輯帳號'
            />
            編輯帳號
          </Link>
          <div
            className='right-control-panel-btn-container'
            onClick={() => {
              onLoggedOut();
              onLoginPanelChange('login');
            }}
          >
            <img
              className='right-control-panel-btn-img'
              src='/img/buttons/controlPanel/logout.svg'
              alt='登出'
            />
            登出
          </div>
        </div>

        {account.admin ? (
          <div className='right-account-admin-container'>
            <Link to='/articles/edit' className='right-account-admin-btn'>
              管理文章
            </Link>
            <Link to='/accounts/manager' className='right-account-admin-btn'>
              管理用戶
            </Link>
            <Link to='/news/edit' className='right-account-admin-btn'>
              管理新聞
            </Link>
          </div>
        ) : null}

        <div className='right-dynamicPanel-container'>
          <div className='right-dynamicPanel-title-container'>
            <div className='right-dynamicPanel-title-icon-container'>
              <img
                className='icon'
                src={`/img/accounts/avatars/custom/${account.userId}.png`}
                alt={account.name}
              />
            </div>
            <div
              className='tagContainer'
              onClick={() => this.props.onPanelExpand('myAccount', 'right')}
            >
              {!panel.myAccount ? (
                <div className='tag'>&#9654; 我的帳號</div>
              ) : (
                <div className='tag'>&#9660; 我的帳號</div>
              )}
            </div>
          </div>
          <div
            className={
              panel.myAccount
                ? 'right-dynamicPanel-content-container active'
                : 'right-dynamicPanel-content-container'
            }
          >
            <div
              className={
                panel.myAccount
                  ? 'right-dynamicPanel-content active'
                  : 'right-dynamicPanel-content'
              }
            >
              <div className='rightAccountDetails-container'>
                <div className='rightAccountDetails-label'>暱稱:</div>
                <div className='rightAccountDetails-value'>{account.name}</div>

                <div className='rightAccountDetails-label'>識別碼:</div>
                <div className='rightAccountDetails-value'>
                  {account.userId}
                </div>

                <div className='rightAccountDetails-label'>電郵:</div>
                <div className='rightAccountDetails-value'>{account.email}</div>

                <div className='rightAccountDetails-label'>戦闘力:</div>
                <div className='rightAccountDetails-value'>
                  {account.powerLevel}
                </div>

                {account.gender ? (
                  <div className='rightAccountDetails-label'>性別:</div>
                ) : null}
                {account.gender ? (
                  <div className='rightAccountDetails-value'>
                    {account.gender === 'male' ? '男' : '女'}
                  </div>
                ) : null}

                {account.birthYear ? (
                  <div className='rightAccountDetails-label'>年齡:</div>
                ) : null}
                {account.birthYear ? (
                  <div className='rightAccountDetails-value'>
                    {moment().format('YYYY') - account.birthYear}歲
                  </div>
                ) : null}

                {account.intro ? (
                  <div className='rightAccountDetails-label'>自我介紹:</div>
                ) : null}
                {account.intro ? (
                  <div className='rightAccountDetails-value'>
                    {account.intro}
                  </div>
                ) : null}
              </div>

              {account.eosAccount ||
              account.telosAccount ||
              account.bosAccount ||
              account.worbliAccount ||
              account.waxAccount ||
              account.meetOneAccount ||
              account.lynxAccount ? (
                <div>
                  <div className='rightAccountDetail-tips'>打賞帳號</div>
                  <div className='rightAccountDetails-container chains'>
                    {account.eosAccount ? (
                      <div className='rightAccountDetails-label'>
                        <img src='/img/accounts/chains/eos.png' alt='EOSIO' />
                        <div>EOS 主網</div>
                      </div>
                    ) : null}
                    {account.eosAccount ? (
                      <div className='rightAccountDetails-value'>
                        {account.eosAccount}
                      </div>
                    ) : null}

                    {account.telosAccount ? (
                      <div className='rightAccountDetails-label'>
                        <img src='/img/accounts/chains/telos.png' alt='Telos' />
                        <div>Telos</div>
                      </div>
                    ) : null}
                    {account.telosAccount ? (
                      <div className='rightAccountDetails-value'>
                        {account.telosAccount}
                      </div>
                    ) : null}

                    {account.bosAccount ? (
                      <div className='rightAccountDetails-label'>
                        <img src='/img/accounts/chains/bos.png' alt='BOS' />
                        <div>BOS</div>
                      </div>
                    ) : null}
                    {account.bosAccount ? (
                      <div className='rightAccountDetails-value'>
                        {account.bosAccount}
                      </div>
                    ) : null}

                    {account.worbliAccount ? (
                      <div className='rightAccountDetails-label'>
                        <img
                          src='/img/accounts/chains/worbli.png'
                          alt='Worbli'
                        />
                        <div>Worbli</div>
                      </div>
                    ) : null}
                    {account.worbliAccount ? (
                      <div className='rightAccountDetails-value'>
                        {account.worbliAccount}
                      </div>
                    ) : null}

                    {account.waxAccount ? (
                      <div className='rightAccountDetails-label'>
                        <img src='/img/accounts/chains/wax.png' alt='WAX' />
                        <div>WAX</div>
                      </div>
                    ) : null}
                    {account.waxAccount ? (
                      <div className='rightAccountDetails-value'>
                        {account.waxAccount}
                      </div>
                    ) : null}

                    {account.meetOneAccount ? (
                      <div className='rightAccountDetails-label'>
                        <img
                          src='/img/accounts/chains/meetOne.png'
                          alt='MeetOne'
                        />
                        <div>MeetOne</div>
                      </div>
                    ) : null}
                    {account.meetOneAccount ? (
                      <div className='rightAccountDetails-value'>
                        {account.meetOneAccount}
                      </div>
                    ) : null}

                    {account.lynxAccount ? (
                      <div className='rightAccountDetails-label'>
                        <img src='/img/accounts/chains/lynx.png' alt='Lynx' />
                        <div>Lynx</div>
                      </div>
                    ) : null}
                    {account.lynxAccount ? (
                      <div className='rightAccountDetails-value'>
                        {account.lynxAccount}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div className='rightAccountDetails-container'>
                <div className='rightAccountDetails-label'>最後更新:</div>
                <div className='rightAccountDetails-value'>{updatedAt}</div>

                <div className='rightAccountDetails-label'>建立於:</div>
                <div className='rightAccountDetails-value'>{createdAt}</div>
              </div>

              <div className='rightAccountTag'>
                點擊 "編輯用戶" 加入頭像等個人資料
              </div>

              <br />

              <div className='right-confirmation-btn-container'>
                <Link
                  to='/account/edit'
                  className='right-confirmation-btn'
                  onClick={() => window.scrollTo(0, 0)}
                >
                  編輯帳號
                </Link>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className='right-dynamicPanel-container'>
          <div className='right-dynamicPanel-title-container'>
            <div className='right-dynamicPanel-title-icon-container'>
              <img
                className='icon'
                src='/img/boards/eosCity2.svg'
                alt='我的文章'
              />
            </div>
            <div
              className='tagContainer'
              onClick={() => this.props.onPanelExpand('myArticles', 'right')}
            >
              {!panel.myArticles ? (
                <div className='tag'>&#9654; 我的文章</div>
              ) : (
                <div className='tag'>&#9660; 我的文章</div>
              )}
            </div>
          </div>
          <div
            className={
              panel.myArticles
                ? 'right-dynamicPanel-content-container active'
                : 'right-dynamicPanel-content-container'
            }
          >
            <div
              className={
                panel.myArticles
                  ? 'right-dynamicPanel-content active'
                  : 'right-dynamicPanel-content'
              }
            >
              {!myArticles.length ? (
                <div className='rightAccountTag'>
                  您沒有發表過任何文章，您可點擊以下按鈕發文
                </div>
              ) : (
                myArticles.map((article, index) => (
                  <div key={index} className='right-edit-snippet-container'>
                    <Link
                      to={`/article/${article._id}`}
                      className='right-edit-snippet-content-container'
                      onClick={() => {
                        window.scrollTo(0, 0);
                        onArticleSelected(article._id);
                      }}
                    >
                      {article.coverImgPath ? (
                        <div className='right-edit-snippet-img-container'>
                          <img
                            className='right-edit-snippet-img'
                            src={`/img/articles/${article._id}-cover.jpg`}
                            alt='preview'
                          />
                        </div>
                      ) : null}
                      <div className='right-edit-snippet-text-container'>
                        <div className='right-edit-snippet-content-title'>
                          {article.title}
                        </div>
                        <div className='right-edit-snippet-content-info'>
                          <div className='loves'>
                            {article.version === 'community' ? '社區' : '官方'}
                          </div>
                          <div className='loves'>Loves {article.loves}</div>
                          <div className='time'>
                            {moment
                              .unix(article.updatedTime)
                              .format('YYYY-MM-DD h:mm:ss a')}
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className='right-edit-snippet-edit-container'>
                      <div className='edit-btn'>
                        <i
                          className='fa fa-pencil editIcon'
                          onClick={() => {
                            this.props.onFetchArticleForEdit(article._id);
                            this.props.history.push(
                              `/article/editor/update/${article._id}`
                            );
                            window.scrollTo(0, 0);
                          }}
                        />
                      </div>
                      <div className='remove-btn'>
                        <i
                          className='fa fa-trash removeIcon'
                          onClick={() => {
                            onArticleDelete(article);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))
              )}

              <br />

              <div className='right-confirmation-btn-container'>
                <Link
                  to={
                    account.admin || account.moderator
                      ? '/article/editor/new/official'
                      : '/article/editor/new/community'
                  }
                  className='right-confirmation-btn'
                  onClick={() => {
                    this.props.onResetArticleEditorBlock();
                  }}
                >
                  發表文章
                </Link>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div
          className='rightLoginBtn'
          onClick={() => {
            onLoggedOut();
            onLoginPanelChange('login');
          }}
        >
          登出
        </div>
      </div>
    );
  }
}

export default withRouter(BoardAccount);
