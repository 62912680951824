import React, { Component } from 'react';
import Ads from '../components/ads';
import BoardTable from '../components/boardTable';
import PartnerIconsAndContainer from '../components/partnerIconsAndContainer';

class News extends Component {
  state = {
    eoscityPartners: [
      {
        position: 1,
        label: 'EOS台灣',
        href: 'https://www.eostw.info',
        imgSrc: '/img/eoscityPartners/eosTaiwan.svg'
      },
      {
        position: 2,
        label: 'IMEOS',
        href: 'https://imeos.one',
        imgSrc: '/img/eoscityPartners/imeos.svg'
      },
      {
        position: 3,
        label: 'Telos 廣東',
        href: 'https://teloscanton.io/',
        imgSrc: '/img/eoscityPartners/telosCanton.svg'
      },
      {
        position: 4,
        label: 'EOSWING',
        href: 'https://eoswing.io',
        imgSrc: '/img/eoscityPartners/eoswing.svg'
      },
      {
        position: 5,
        label: 'BOXDAPP',
        href: 'http://boxdapp.io',
        imgSrc: '/img/eoscityPartners/BOXDAPP.svg'
      },
      {
        position: 6,
        label: 'Equilibrium',
        href: 'https://gateway.eosdt.com/zh',
        imgSrc: '/img/eoscityPartners/eosdt.svg'
      }
    ]
  };

  render() {
    const { rightPanelActive, infoList, noticeList, meetupList } = this.props;

    return (
      <div
        className={
          rightPanelActive === '新聞'
            ? 'rightContainer content active'
            : 'rightContainer content'
        }
      >
        {infoList.length !== 0 ? (
          <BoardTable
            imgSrc='/img/boards/news.svg'
            label='最新消息'
            content={infoList}
          />
        ) : null}

        {meetupList.length !== 0 ? (
          <BoardTable
            imgSrc='/img/boards/meetup2.svg'
            label='Meet Up'
            content={meetupList}
          />
        ) : null}

        <Ads
          aHref='https://www.tokenpocket.pro/'
          imgSrc='/img/nav2_ads/rightAds4.png'
          imgAlt='Token Pocket 錢包令你快速擁 EOS 帳號'
        />

        {noticeList.length !== 0 ? (
          <BoardTable
            imgSrc='/img/boards/eoscityNotice.svg'
            label='站內公告'
            content={noticeList}
          />
        ) : null}

        <Ads
          aHref='http://t.cn/ES2ggI7'
          imgSrc='/img/nav2_ads/rightAds5.png'
          imgAlt='Sword & Magic'
        />

        <Ads
          aHref=''
          imgSrc='/img/nav2_ads/rightAds1.png'
          imgAlt='請支持柚子市超級節點'
        />

        <Ads
          aHref='https://www.more.top'
          imgSrc='/img/nav2_ads/rightAds2.png'
          imgAlt='MORE.TOP 你的柚子手機錢包'
        />

        <PartnerIconsAndContainer
          eoscityPartners={this.state.eoscityPartners}
        />
      </div>
    );
  }
}

export default News;
