import React, { Component } from 'react';
import moment from 'moment';
import { chineseTimeFormat } from '../../../../../../utils/chineseTimeFormat';
import RenderTemplate from './components/renderTemplate';
import AuthorSnippetTop from './components/authorSnippetTop';
import AuthorSnippetBottom from './components/authorSnippetBottom';
import ArticleReplies from './components/articleReplies';
import './css/articleRender.css';
import { withRouter } from 'react-router-dom';

class Article extends Component {
  state = { sticky: 0 };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const articleId = this.props.match.params.id;
    this.props.onArticleSelected(articleId);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  time = Date.now();

  handleScroll = () => {
    if (this.time + 250 - Date.now() < 0) {
      const height = this.middleContainer.clientHeight;
      const windowHeight = window.innerHeight;
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, 'middleHeight');
      this.time = Date.now();
    }
  };

  render() {
    const { author, loves, replies, label } = this.props.articleBlock.article;
    const {
      endpoint,
      account,
      onLove,
      onReply,
      onReplySubmit,
      onEmbeddedReply,
      openEmbeddedReplyInput,
      onEmbeddedReplySubmit,
      openEmbeddedReplyMessages,
      onArticleDelete
    } = this.props;
    const {
      embeddedReply,
      article,
      love,
      reply,
      errors
    } = this.props.articleBlock;

    let publishTimeS = moment
      .unix(article.publishTime)
      .format('YYYY年MM月DD號(ddd) Ah點mm分ss秒');
    publishTimeS = chineseTimeFormat(publishTimeS);

    let updatedTimeS = moment
      .unix(article.updatedTime)
      .format('YYYY年MM月DD號(ddd) Ah點mm分ss秒');
    updatedTimeS = chineseTimeFormat(updatedTimeS);

    return (
      <div
        className='article-render-container'
        ref={middleContainer => {
          this.middleContainer = middleContainer;
        }}
        style={{
          position: 'sticky',
          top: this.state.sticky
        }}
      >
        {!author._id || author._id !== account._id ? null : (
          <div className='messageForAuthor-container'>
            你的文章已成功在
            <span style={{ color: '#cc0066' }}>
              {article.version === 'community' ? '社區版' : '官方版'}
            </span>
            發佈! &nbsp;&nbsp;&nbsp;
            <span
              className='messageForAuthor-editBtn'
              onClick={() => {
                this.props.history.push(
                  `/article/editor/update/${article._id}`
                );
              }}
            >
              編輯文章
            </span>
            &nbsp;&nbsp;
            <span
              className='messageForAuthor-editBtn remove'
              onClick={() => {
                onArticleDelete(article);
              }}
            >
              删除文章
            </span>
            <div className='warning'>
              <br />
              *如果您有更新或修改您的圖片，請按 '重新整理'
              刷新整篇文章，否則這裡不會顯示更新或新加的圖片
            </div>
          </div>
        )}
        {article.coverImgPath ? (
          <div className='coverImg'>
            <img src={`/img/articles/${article._id}-cover.jpg`} alt='Cover' />
          </div>
        ) : null}

        <div>
          <div className='title'>
            {' '}
            <span
              className={
                label === '主網'
                  ? 'label mainnet'
                  : label === '遊戲'
                  ? 'label game'
                  : label === '幣市'
                  ? 'label market'
                  : label === 'DAPPS'
                  ? 'label dapps'
                  : label === '社區'
                  ? 'label community'
                  : label === 'BP'
                  ? 'label bp'
                  : label === '教程'
                  ? 'label tutorial'
                  : label === '綜合'
                  ? 'label comprehensive'
                  : label === '政治'
                  ? 'label politics'
                  : label === '經濟'
                  ? 'label economy'
                  : label === '工業'
                  ? 'label industry'
                  : label === '農業'
                  ? 'label archiculture'
                  : label === '商業'
                  ? 'label commerical'
                  : label === '科技'
                  ? 'label technology'
                  : label === '生活'
                  ? 'label lifestyle'
                  : label === '消閑'
                  ? 'label leisure'
                  : 'label'
              }
            >
              {' '}
              {label}{' '}
            </span>{' '}
            {article.title}
          </div>
          <AuthorSnippetTop
            account={author}
            love={love}
            loves={loves}
            publishTimeS={publishTimeS}
            onLove={onLove}
            articleId={this.props.match.params.id}
          />
        </div>
        {article.contentBlocks
          ? article.contentBlocks.map((block, index) => (
              <RenderTemplate
                key={index}
                content={block.content}
                tag={block.componentType}
              />
            ))
          : null}
        <AuthorSnippetBottom
          endpoint={endpoint}
          account={author}
          articleId={this.props.match.params.id}
          love={love}
          loves={loves}
          publishTimeS={publishTimeS}
          updatedTimeS={updatedTimeS}
          onLove={onLove}
        />
        <ArticleReplies
          errors={errors}
          account={account}
          articleId={this.props.match.params.id}
          onReply={onReply}
          onReplySubmit={onReplySubmit}
          reply={reply}
          replies={replies}
          onEmbeddedReply={onEmbeddedReply}
          onEmbeddedReplySubmit={onEmbeddedReplySubmit}
          openEmbeddedReplyInput={openEmbeddedReplyInput}
          embeddedReply={embeddedReply}
          openEmbeddedReplyMessages={openEmbeddedReplyMessages}
          onResetArticleBlock={this.props.onResetArticleBlock}
        />
      </div>
    );
  }
}

export default withRouter(Article);
