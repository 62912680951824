import React, { Component } from 'react';

class Intro extends Component {
  render() {
    const { content } = this.props.block;
    return <div className='middle-navigator-intro-container'>{content}</div>;
  }
}

export default Intro;
