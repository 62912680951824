import React, { Component } from 'react';
import Prism from 'prismjs';

class Code extends Component {
  componentDidUpdate() {
    Prism.highlightAll();
  }

  render() {
    const { content } = this.props;
    return (
      <pre style={{ borderRadius: 10 }}>
        <code
          className={
            !content.path ? 'language-clike' : `language-${content.path}`
          }
        >
          {content.text}
        </code>
      </pre>
    );
  }
}

export default Code;
