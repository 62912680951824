import React from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';

const Footer = () => {
  return (
    <div id='footer clearfix'>
      <div className='bottomShortcut'>
        <Link
          to='/'
          className={
            window.location.pathname === '/' ? 'home selected' : 'home top'
          }
          onClick={() => window.scrollTo(0, 0)}
        >
          <img src='/img/footer/home.svg' alt='官方' />
          <div className='label'>官方</div>
        </Link>

        <Link
          to='/community'
          className={
            window.location.pathname === '/community'
              ? 'home selected'
              : 'home top'
          }
          onClick={() => window.scrollTo(0, 0)}
        >
          <img src='/img/footer/community.svg' alt='社區' />
          <div className='label'>社區</div>
        </Link>

        <div className='top' onClick={() => window.scrollTo(0, 0)}>
          <img src='/img/footer/back2Top.svg' alt='頂層' />
          <div className='label'>頂層</div>
        </div>
      </div>

      <div id='contact1'>
        <div>
          <img src='/img/footer/eosCity_logo_alt.svg' alt='柚子市' />
        </div>
      </div>
      <div id='contact2'>
        <a href='https://t.me/eoscity' rel='noopener noreferrer'>
          <img src='/img/footer/telegram.svg' alt='電報群' />
        </a>

        <a
          href='https://www.facebook.com/groups/eoscity/'
          rel='noopener noreferrer'
        >
          <img src='/img/footer/facebook.svg' alt='臉書' />
        </a>

        <a href='https://twitter.com/eoscityio' rel='noopener noreferrer'>
          <img src='/img/footer/twitter.svg' alt='推特' />
        </a>

        <a href='mailto:support@eoscity.io' rel='noopener noreferrer'>
          <img src='/img/footer/email.svg' alt='電郵' />
        </a>
      </div>
    </div>
  );
};

export default Footer;
