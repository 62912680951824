import React, { Component } from 'react';
import Snippet from '../../homeMode/middleZone/mode/homeMode/components/snippet';
import AuthorSnippetTop from '../../homeMode/middleZone/mode/articleMode/components/authorSnippetTop';
import AuthorSnippetBottom from './../../homeMode/middleZone/mode/articleMode/components/authorSnippetBottom';
import RenderTemplate from '../../homeMode/middleZone/mode/articleMode/components/renderTemplate';
import '../../homeMode/middleZone/mode/articleMode/css/articleRender.css';

class Preview extends Component {
  state = {
    love: false
  };

  handleLove = () => {
    let love = this.state.love;
    this.setState({ love: !love });
  };

  render() {
    const {
      article,
      temp,
      account,
      currentTime,
      currentTimeS,
      onArticleSubmit,
      update
    } = this.props;
    const { love } = this.state;
    let updatedTimeS = currentTimeS;

    return (
      <div className='article-render-container'>
        <div className='article-preview-message'>
          {article.version === 'community' ? (
            <span>
              您正在使用
              <span className='highlight'>社區版</span>
              的文章編輯器，發佈後將會在
              <span className='highlight'>社區版</span>
              顯示，但不會在<span>官方版</span>顯示
            </span>
          ) : article.version === 'official' ? (
            <span>
              您正在使用
              <span className='highlight'>官方版</span>
              的文章編輯器，發佈後將會在
              <span className='highlight'>官方版</span>
              顯示，但不會在<span>社區版</span>顯示
            </span>
          ) : null}
        </div>
        <div className='article-render-tagContainer'>
          <div className='editSnippetTag'>首頁摘錄</div>
        </div>
        {!article.title && !article.outline && !temp.coverImgSrc ? (
          <div className='articleEdit-preview-snippetContainer'>
            <div className='previewLocationSnippet'>摘錄預覽</div>
          </div>
        ) : (
          <div style={{ marginBottom: 64 }}>
            <Snippet
              update={update}
              author={article.author}
              article={article}
              temp={temp}
              account={account}
              currentTime={currentTime}
            />
          </div>
        )}

        <div className='article-render-tagContainer'>
          <div className='editSnippetTag'>文章內容</div>
        </div>
        <div>
          {!article.title && !temp.coverImgSrc ? (
            <div className='previewLocation'>內容預覽</div>
          ) : null}
          {temp.coverImgSrc ? (
            <div className='coverImg'>
              <img src={temp.coverImgSrc} alt={article.title} />
            </div>
          ) : update && article.coverImgPath ? (
            <div className='coverImg'>
              <img
                src={`/img/articles/${article._id}-cover.jpg`}
                alt={article.title}
              />
            </div>
          ) : null}
          {!article.title ? null : <div className='title'>{article.title}</div>}

          <AuthorSnippetTop
            update={update}
            author={article.author}
            account={account}
            love={love}
            loves={article.loves}
            publishTimeS={currentTimeS}
            onLove={this.handleLove}
          />

          {article.contentBlocks.map((block, index) => (
            <RenderTemplate
              key={index}
              content={block.content}
              tag={block.componentType}
            />
          ))}

          {article.contentBlocks.length !== 0 ? (
            <AuthorSnippetBottom
              update={update}
              author={article.author}
              account={account}
              article={article}
              publishTimeS={currentTimeS}
              updatedTimeS={updatedTimeS}
              love={love}
              loves={article.loves}
              onLove={this.handleLove}
            />
          ) : null}
        </div>
        <div style={{ textAlign: 'center' }}>
          <div className='articleSubmitBtn' onClick={onArticleSubmit}>
            {update ? '更新文章' : '建立文章'}
          </div>
        </div>
      </div>
    );
  }
}

export default Preview;
