import React, { Component } from 'react';

class LinkSubBlock extends Component {
  render() {
    const { label, url } = this.props;
    return (
      <a href={url} className='middle-navigator-recommendSnippet-right-link'>
        {label}
      </a>
    );
  }
}

export default LinkSubBlock;
