import React, { Component } from 'react';
import NavIconsContainer from '../components/navIconsContainer';
import '../css/navigation.css';

class Navigation extends Component {
  render() {
    const { leftPanelActive, navPanel, onNavIconSelected } = this.props;

    return (
      <div
        className={leftPanelActive === '導航' ? 'content active' : 'content'}
      >
        {navPanel.subclasses.map((subclass, subclassIndex) => (
          <NavIconsContainer
            key={subclassIndex}
            subclass={subclass}
            onNavIconSelected={onNavIconSelected}
          />
        ))}
      </div>
    );
  }
}

export default Navigation;
