import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Home from "./mode/homeMode/home";
import "./css/middleZone.css";

class MiddleZone extends Component {
  state = { sticky: -600 };

  componentDidMount() {
    window.addEventListener("scroll", this.throttle);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttle);
  }

  time = Date.now();
  fetchTime = Date.now();

  throttle = () => {
    const height = this.middleContainer.clientHeight;
    const windowHeight = window.innerHeight;

    if (this.time + 250 - Date.now() < 0) {
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, "middleHeight");
      this.time = Date.now();
    }

    if (windowHeight + document.documentElement.scrollTop - 155 > height) {
      if (this.fetchTime + 1000 - Date.now() < 0) {
        this.props.onFetchMoreSnippets("official");

        const sticky = windowHeight - height - 50;
        this.setState({ sticky });
        this.props.onCalcComponentHeight(height, "middleHeight");
        this.fetchTime = Date.now();
      }
    }
  };

  render() {
    const {
      account,
      coveredBanners,
      currentBanner,
      onThumbHover,
      articleTypeLabel,
      onUpdateSnippetList,
      officialSnippetList,
      onCoveredBannerInputChange,
      onCoveredBannerSubmit
    } = this.props;

    return (
      <React.Fragment>
        <div
          id="middleZone-home"
          ref={middleContainer => {
            this.middleContainer = middleContainer;
          }}
          style={{
            position: "sticky",
            top: this.state.sticky
          }}
        >
          <Home
            account={account}
            coveredBanners={coveredBanners}
            currentBanner={currentBanner}
            onThumbHover={onThumbHover}
            articleTypeLabel={articleTypeLabel}
            onUpdateSnippetList={onUpdateSnippetList}
            officialSnippetList={officialSnippetList}
            onCoveredBannerInputChange={onCoveredBannerInputChange}
            onCoveredBannerSubmit={onCoveredBannerSubmit}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(MiddleZone);
