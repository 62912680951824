import React from 'react';
import QuestionMark from './../../../../common/icons/questionMark';
import '../css/boardTable.css';

const BoardLogin = ({
  imgSrc,
  currentLogMode,
  onLoginPanelChange,
  onRegistrationSubmit,
  register,
  eoscityStatistic,
  onLogin,
  onInputChange,
  login,
  onKeepMeLoggedIn,
  keepMeLoggedIn,
  errors
}) => {
  return (
    <div className='nav2-subZone'>
      <div className='right-login-switch-btns-container'>
        <div
          className={
            currentLogMode === 'register'
              ? 'rightLoginBtn active'
              : 'rightLoginBtn'
          }
          onClick={() => {
            onLoginPanelChange('register');
          }}
        >
          立即註冊
        </div>
        <div
          className={
            currentLogMode === 'login'
              ? 'rightLoginBtn active'
              : 'rightLoginBtn'
          }
          onClick={() => {
            onLoginPanelChange('login');
          }}
        >
          帳號登入
        </div>
      </div>
      <div className='subTag clearfix'>
        <div className='subIcon'>
          <img
            src={imgSrc}
            alt={
              currentLogMode === 'login'
                ? '登入'
                : currentLogMode === 'register'
                ? '登記註册'
                : 'unknow'
            }
          />
        </div>
        <div className='title'>
          {currentLogMode === 'login'
            ? '登入'
            : currentLogMode === 'register'
            ? '註册會員'
            : 'unknow'}
        </div>
      </div>

      {currentLogMode === 'login' ? (
        <div id='rightNoticeContainer' className='subContainer'>
          <div className='rightLoginInput'>
            <div className='description'>
              <label>電郵地址</label>
            </div>
            <input
              name='email'
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  onLogin();
                }
              }}
              onChange={e => onInputChange(e, currentLogMode)}
              value={login.email}
              style={{ backgroundColor: errors.email ? '#ffe6e6' : '' }}
            />
            <div className='error'>{errors.email}</div>
          </div>

          <div className='rightLoginInput'>
            <div className='description'>
              <label>登入密碼</label>
            </div>
            <input
              type='password'
              name='password'
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  onLogin();
                }
              }}
              onChange={e => onInputChange(e, currentLogMode)}
              value={login.password}
              style={{ backgroundColor: errors.password ? '#ffe6e6' : '' }}
            />
            <div className='error'>{errors.password}</div>
          </div>

          <div className='rightLoginAssist'>
            <label className='rememberMeContainer'>
              保持登入
              <input
                type='checkbox'
                checked={keepMeLoggedIn ? 'checked' : ''}
                value={keepMeLoggedIn}
                onChange={onKeepMeLoggedIn}
              />
              <span className='checkmark'></span>
            </label>

            <div className='link'>忘記密碼?</div>
          </div>

          <div
            className='rightLoginBtn'
            onClick={() => {
              onLogin();
            }}
          >
            登入
          </div>
          <br />
          <div className='rightLoginTag'>
            沒有帳號? 立即註冊登記成為柚子市一份子!
          </div>
        </div>
      ) : currentLogMode === 'register' ? (
        <div>
          <div id='rightNoticeContainer' className='subContainer'>
            <div className='rightLoginTag'>歡迎登記註册成為柚子市會員</div>
            <div className='rightLoginInput'>
              <div className='description'>
                <label>暱稱*</label>
                <QuestionMark title='您發言時將會顯示這個名稱，可跟其他人重覆但不能超過16字' />
              </div>
              <input
                type='text'
                name='name'
                onKeyDown={e => {
                  if (e.keyCode === 13 && e.shiftKey === false) {
                    e.preventDefault();
                    onRegistrationSubmit();
                  }
                }}
                onChange={e => onInputChange(e, currentLogMode)}
                value={register.name}
                style={!errors.name ? null : { backgroundColor: '#ffe6e6' }}
              />
              <div className='error'>{errors.name}</div>
            </div>

            <div className='rightLoginInput'>
              <div className='description'>
                <label>電郵地址*</label>
                <QuestionMark title='點擊註册時，系統會寄電郵到該電郵地址以確認身份' />
              </div>
              <input
                type='text'
                name='email'
                onKeyDown={e => {
                  if (e.keyCode === 13 && e.shiftKey === false) {
                    e.preventDefault();
                    onRegistrationSubmit();
                  }
                }}
                onChange={e => onInputChange(e, currentLogMode)}
                value={register.email}
                style={errors.email ? { backgroundColor: '#ffe6e6' } : null}
              />
              <div className='error'>{errors.email}</div>
            </div>

            <div className='rightLoginInput'>
              <div className='description'>
                <label>登入密碼*</label>
                <QuestionMark title='您的密碼必須包括數字、大階及小階英文字母，最少8位，最多30位的字串，可選擇性包括 ! @ # $ % & * 這幾種特別符號' />
              </div>
              <input
                type='password'
                name='password'
                onKeyDown={e => {
                  if (e.keyCode === 13 && e.shiftKey === false) {
                    e.preventDefault();
                    onRegistrationSubmit();
                  }
                }}
                onChange={e => onInputChange(e, currentLogMode)}
                value={register.password}
                style={errors.password ? { backgroundColor: '#ffe6e6' } : null}
              />
              <div className='error'>{errors.password}</div>
            </div>

            <div className='rightLoginInput'>
              <div className='description'>
                <label style={{ width: 120 }}>再次輸入密碼*</label>
                <QuestionMark title='您必須再次輸入以保証您沒有打錯密碼' />
              </div>
              <input
                type='password'
                name='confirmedPassword'
                onKeyDown={e => {
                  if (e.keyCode === 13 && e.shiftKey === false) {
                    e.preventDefault();
                    onRegistrationSubmit();
                  }
                }}
                onChange={e => onInputChange(e, currentLogMode)}
                value={register.confirmedPassword}
                style={
                  errors.confirmedPassword
                    ? { backgroundColor: '#ffe6e6' }
                    : null
                }
              />
              <div className='error'>{errors.confirmedPassword}</div>
            </div>

            <div className='rightLoginTag' style={{ color: '#aaa' }}>
              *所有資料必需填寫
            </div>
            <div style={{ display: 'flex' }}>
              <div
                className='rightLoginBtn'
                onClick={() => {
                  onRegistrationSubmit();
                }}
              >
                遞交註册
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <br />
      <br />
      <div className='subTag clearfix'>
        <div className='subIcon'>
          <img src='/img/boards/statistic.svg' alt='本站統計' />
        </div>
        <div className='title'>本站統計</div>
      </div>
      <div id='rightNoticeContainer' className='subContainer'>
        <div className='right-eoscity-statistic-table'>
          <div className='right-eoscity-statistic-row'>
            <div>會員總數</div>
            <div> {eoscityStatistic.accountNumber} </div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>文章數量</div>
            <div> {eoscityStatistic.articleNumber} </div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>EOS 帳號</div>
            <div> {eoscityStatistic.eosAccount} </div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>Telos 帳號</div>
            <div> {eoscityStatistic.telosAccount} </div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>BOS 帳號</div>
            <div> {eoscityStatistic.bosAccount} </div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>Worbli 帳號</div>
            <div> {eoscityStatistic.worbliAccount} </div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>WAX 帳號</div>
            <div> {eoscityStatistic.waxAccount} </div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>MeetOne 帳號</div>
            <div> {eoscityStatistic.meetOneAccount} </div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>Lynx 帳號</div>
            <div> {eoscityStatistic.lynxAccount} </div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>Telos 帳號</div>
            <div> {eoscityStatistic.telosAccount} </div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>本站版本</div>
            <div>2.1</div>
          </div>

          <div className='right-eoscity-statistic-row'>
            <div>建立日期</div>
            <div>2018年9月3日 (一)</div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default BoardLogin;
