import React from 'react';
import QuestionMark from '../../../../common/icons/questionMark';

const Link = ({ index, onSnippetChange, content, onSnippetDelete }) => {
  const info =
    '只有標籤能在文章內容上顯示，URL是不會顯示出來，但點擊時網頁會跳轉去URL設定的網址，如果想顯示連結給讀者複製，請把標籤和 URL 設為相同';
  return (
    <div className='articleEdit-editor-inputContainer'>
      <div className='articleEdit-editor-inputTitleContainer'>
        <div className='title'>連結</div>
        <div className='articleEdit-editor-input-rightContainer'>
          <QuestionMark title={info} />
          <i
            className='fa fa-trash icon'
            onClick={() => onSnippetDelete(index)}
          ></i>
        </div>
      </div>
      <div className='articleEdit-editor-input-linkContainer'>
        <div className='articleEdit-editor-input-link-inputContainer'>
          <label htmlFor={'articleEdit-editor-input-linkLabel' + index}>
            標籤:
          </label>
          <br />
          <input
            type='text'
            id={'articleEdit-editor-input-linkLabel' + index}
            onChange={e => onSnippetChange(e, index, 'link', 'label')}
            value={content.text}
          />
        </div>
        <div className='articleEdit-editor-input-link-inputContainer'>
          <label htmlFor={'articleEdit-editor-input-link' + index}>URL:</label>
          <br />
          <input
            type='text'
            style={{
              color: 'black',
              fontStyle: 'normal',
              fontWeight: 'normal'
            }}
            id={'articleEdit-editor-input-link' + index}
            onChange={e => onSnippetChange(e, index, 'link', 'href')}
            value={content.path}
          />
        </div>
      </div>
    </div>
  );
};

export default Link;
