import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: '#0f3676'
  },
  tooltip: {
    backgroundColor: '#0f3676',
    fontFamily: 'Microsoft JhengHei, 微軟正黑體, Ubuntu, sans-serif',
    fontSize: 13,
    width: 180,
    // color: "#ffd837",
    // fontWeight: "bold",
    padding: 12
    // border: "2px solid #ebf8ff"
  }
}));

const QuestionMark = props => {
  const classes = useStylesBootstrap();
  return (
    <Tooltip arrow classes={classes} placement='top' {...props}>
      <Icon className='fa fa-question icon' />
    </Tooltip>
  );
};

export default QuestionMark;
