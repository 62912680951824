import React, { Component } from 'react';
import ContactUrlInsertBar from './contactUrlInsertBar';

class ContactUrlSubBlock extends Component {
  state = {
    insertBarSwitch: false
  };

  handleInsertBarSwitch = specified => {
    let insertBarSwitch;
    if (!specified) {
      insertBarSwitch = !this.state.insertBarSwitch;
    } else {
      insertBarSwitch = specified;
    }
    this.setState({ insertBarSwitch });
  };

  render() {
    const { insertBarSwitch } = this.state;
    const {
      iconIndex,
      blockIndex,
      icon,
      onContactUrlChange,
      onBlockInputChange
    } = this.props;
    return (
      <React.Fragment>
        {insertBarSwitch ? (
          <ContactUrlInsertBar
            blockIndex={blockIndex}
            iconIndex={iconIndex}
            method={'insert'}
            onContactUrlChange={onContactUrlChange}
            onInsertBarSwitch={this.handleInsertBarSwitch}
          />
        ) : null}
        <div className='middle-navigator-edit-snippet-right-addLinkIcon-url-container'>
          <div className='upper'>
            <div className='leftside'>
              <div className='middle-navigator-edit-snippet-right-addLinkIcon-url-label-container'>
                <img
                  className='middle-navigator-edit-snippet-right-addLinkIcon-url-label-img'
                  src={`/img/navigator/${icon.media}.svg`}
                  alt='website'
                />
              </div>
              <div className='middle-navigator-edit-snippet-right-addLinkIcon-url-label-container'>
                <input
                  type='text'
                  name='label'
                  className='middle-navigator-edit-snippet-right-addLinkIcon-url-input'
                  onChange={e =>
                    onBlockInputChange(
                      e,
                      'contactUrl',
                      blockIndex,
                      null,
                      iconIndex
                    )
                  }
                  value={icon.label}
                />
              </div>
              &nbsp; &nbsp;
              <div className='middle-navigator-edit-snippet-right-addLinkIcon-url-label-container'>
                URL
                <input
                  type='text'
                  name='url'
                  className='middle-navigator-edit-snippet-right-addLinkIcon-url-input url'
                  onChange={e =>
                    onBlockInputChange(
                      e,
                      'contactUrl',
                      blockIndex,
                      null,
                      iconIndex
                    )
                  }
                  value={icon.url}
                />
              </div>
            </div>
            <div className='middle-navigator-edit-snippet-right-icon-rightSide contactUrl'>
              <div
                className='middle-navigator-edit-snippet-right-icon-container '
                onClick={() => this.handleInsertBarSwitch()}
              >
                {insertBarSwitch ? (
                  <i className='fa fa-minus plusIcon' />
                ) : (
                  <i className='fa fa-plus plusIcon' />
                )}
              </div>
              <div
                className='middle-navigator-edit-snippet-right-icon-container'
                onClick={() =>
                  onContactUrlChange(blockIndex, iconIndex, 'remove', null)
                }
              >
                <i className='fa fa-trash trashIcon' />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactUrlSubBlock;
