import React from 'react';
import '../css/partnerIconsAndContainer.css';

const PartnerIconsAndContainer = ({ eoscityPartners }) => {
  return (
    <div className='nav2-subZoneIcon'>
      <div className='nav2-titleIcon'>合作夥伴</div>
      <div id='partner2'>
        {eoscityPartners.map(partner => (
          <a key={partner.label} href={partner.href} rel='noopener noreferrer'>
            <img src={partner.imgSrc} alt={partner.label} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default PartnerIconsAndContainer;
