import React from 'react';
import moment from 'moment';
import { chineseTimeFormat } from '../../../../../../../utils/chineseTimeFormat';
import '../../../css/snippet.css';

const Snippet = ({
  update,
  author,
  snippet,
  article,
  temp,
  account,
  index,
  currentTime
}) => {
  let publishTime;
  if (snippet) {
    publishTime = moment
      .unix(snippet.publishTime)
      .format('YYYY年MM月DD號(ddd) Ah點mm分');
    publishTime = chineseTimeFormat(publishTime);
  }

  return (
    <React.Fragment>
      {snippet ? (
        <div className='middleZone-snippet'>
          {snippet.coverImgPath ? (
            index % 2 === 0 ? (
              <div className='middleZone-snippet-imgContainer'>
                <img
                  className='img'
                  src={`/img/articles/${snippet._id}-cover.jpg`}
                  alt=''
                />
              </div>
            ) : null
          ) : null}
          <div className='middleZone-snippet-content'>
            <div>
              <span className='middleZone-snippet-content-title'>
                {snippet.title}
              </span>
              <br />
              <span className='middleZone-snippet-content-outline'>
                {snippet.outline}
              </span>
              <br />
            </div>
            <div className='middleZone-snippet-content-info'>
              <span
                className={
                  snippet.label === '主網'
                    ? 'middleZone-snippet-content-articleType mainnet'
                    : snippet.label === '遊戲'
                    ? 'middleZone-snippet-content-articleType game'
                    : snippet.label === '幣市'
                    ? 'middleZone-snippet-content-articleType market'
                    : snippet.label === 'DAPPS'
                    ? 'middleZone-snippet-content-articleType dapps'
                    : snippet.label === '社區'
                    ? 'middleZone-snippet-content-articleType community'
                    : snippet.label === 'BP'
                    ? 'middleZone-snippet-content-articleType bp'
                    : snippet.label === '教程'
                    ? 'middleZone-snippet-content-articleType tutorial'
                    : snippet.label === '綜合'
                    ? 'middleZone-snippet-content-articleType comprehensive'
                    : snippet.label === '政治'
                    ? 'middleZone-snippet-content-articleType politics'
                    : snippet.label === '經濟'
                    ? 'middleZone-snippet-content-articleType economy'
                    : snippet.label === '工業'
                    ? 'middleZone-snippet-content-articleType industry'
                    : snippet.label === '農業'
                    ? 'middleZone-snippet-content-articleType archiculture'
                    : snippet.label === '商業'
                    ? 'middleZone-snippet-content-articleType commerical'
                    : snippet.label === '科技'
                    ? 'middleZone-snippet-content-articleType technology'
                    : snippet.label === '生活'
                    ? 'middleZone-snippet-content-articleType lifestyle'
                    : snippet.label === '消閑'
                    ? 'middleZone-snippet-content-articleType leisure'
                    : 'middleZone-snippet-content-articleType'
                }
              >
                {snippet.label}
              </span>
              <span className='middleZone-snippet-content-label'>
                {snippet.author.name}
              </span>
              <span className='middleZone-snippet-content-label userId'>
                id {snippet.author.userId}
              </span>
              <span className='middleZone-snippet-content-label'>
                Loves {snippet.loves}
              </span>
              <span className='middleZone-snippet-content-label'>
                {publishTime}
              </span>
            </div>
          </div>
          {snippet.coverImgPath ? (
            index % 2 === 1 ? (
              <div className='middleZone-snippet-imgContainer'>
                <img
                  className='img'
                  src={`/img/articles/${snippet._id}-cover.jpg`}
                  alt=''
                />
              </div>
            ) : null
          ) : null}
        </div>
      ) : (
        <div className='preview-snippet'>
          {temp.coverImgSrc ? (
            <div className='middleZone-snippet-imgContainer'>
              <img className='img' src={temp.coverImgSrc} alt='preview' />
            </div>
          ) : null}
          <div className='middleZone-snippet-content'>
            <div>
              <span className='middleZone-snippet-content-title'>
                {article.title}
              </span>
              <br />
              <span className='middleZone-snippet-content-outline'>
                {article.outline}
              </span>
              <br />
            </div>
            <div className='middleZone-snippet-content-info'>
              <span className='middleZone-snippet-content-label'>
                {update ? author.name : account.name}
              </span>
              <span className='middleZone-snippet-content-label'>
                Loves {article.loves}
              </span>
              <span className='middleZone-snippet-content-label'>
                {currentTime}
              </span>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Snippet;
