import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className='left-footer-container'>
        <div className='first-row'>
          <Link
            className={
              window.location.pathname === '/'
                ? 'shortcut-container active'
                : 'shortcut-container'
            }
            onClick={() => window.scrollTo(0, 0)}
            to='/'
          >
            <img className='icon' src='/img/footer/home.svg' alt='官方' />
            官方
          </Link>

          <Link
            className={
              window.location.pathname === '/community'
                ? 'shortcut-container active'
                : 'shortcut-container'
            }
            onClick={() => window.scrollTo(0, 0)}
            to='/community'
          >
            <img className='icon' src='/img/footer/community.svg' alt='社區' />
            社區
          </Link>
          <div
            className='shortcut-container'
            onClick={() => window.scrollTo(0, 0)}
          >
            <img className='icon' src='/img/footer/back2Top.svg' alt='頂層' />
            頂層
          </div>
        </div>
        <div className='second-row'>
          <img className='logo' src='/img/footer/eosCity_logo_alt.svg' alt='' />
        </div>
        <div className='third-row'>
          <a
            className='contact-container'
            href='https://t.me/eoscity'
            rel='noopener noreferrer'
          >
            <img
              className='contact'
              src='/img/footer/telegram.svg'
              alt='電報群'
            />
          </a>

          <a
            className='contact-container'
            href='https://www.facebook.com/groups/eoscity/'
            rel='noopener noreferrer'
          >
            <img
              className='contact'
              src='/img/footer/facebook.svg'
              alt='臉書'
            />
          </a>

          <a
            className='contact-container'
            href='https://twitter.com/eoscityio'
            rel='noopener noreferrer'
          >
            <img className='contact' src='/img/footer/twitter.svg' alt='推特' />
          </a>

          <a
            className='contact-container'
            href='mailto:support@eoscity.io'
            rel='noopener noreferrer'
          >
            <img className='contact' src='/img/footer/email.svg' alt='電郵' />
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
