import React, { Component } from 'react';
import axios from 'axios';
import '../../css/accountManager.css';
import customToast from '../../../../../../utils/customToast';

class AccountManager extends Component {
  state = {
    accountList: [],
    accountSearch: {
      userIdSearch: '',
      nameSearch: '',
      emailSearch: '',
      timeSearch: ''
    }
  };

  async componentDidMount() {
    window.addEventListener('scroll', this.throttle);
    const accountToken = localStorage.getItem('account');
    const localAccount = JSON.parse(accountToken);
    if (!localAccount.admin) this.props.history.push('/');

    const { apiEndpoint } = this.props;
    const jwt = localStorage.getItem('token');
    axios.defaults.headers.common['x-auth-token'] = jwt;
    let { data: accountList } = await axios.get(
      `${apiEndpoint}/account/manager`
    );

    accountList.forEach(account => (account.extend = false));

    this.setState({ accountList });
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttle);
  }

  time = Date.now();

  throttle = () => {
    if (this.time + 250 - Date.now() < 0) {
      const height = this.middleContainer.clientHeight;
      const windowHeight = window.innerHeight;
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, 'middleHeight');
      this.time = Date.now();
    }
  };

  authorizationChange = async (userId, action) => {
    const { apiEndpoint } = this.props;
    action = { action };

    const res = await axios.put(
      `${apiEndpoint}/account/manager/authorized/${userId}`,
      action
    );

    if (res.status === 200) {
      const { data: accountList } = res;
      console.log(action);
      this.setState({ accountList }, () => {
        if (action.action === 'authorize') {
          customToast.success('帳號的權限已設為正常');
        } else if (action.action === 'unauthorize') {
          customToast.success('帳號已停權');
        }
      });
    }
  };

  extendAccountDetail = userId => {
    let accountList = [...this.state.accountList];
    const index = accountList.findIndex(account => account.userId === userId);
    accountList[index].extend = !accountList[index].extend;
    this.setState({ accountList });
  };

  accountSearchChange = e => {
    let accountSearch = { ...this.state.accountSearch };
    accountSearch[e.target.name] = e.target.value;
    this.setState({ accountSearch });
  };

  accountSearchSubmit = async () => {
    const jwt = localStorage.getItem('token');
    axios.defaults.headers.common['x-auth-token'] = jwt;
    const { apiEndpoint } = this.props;
    const { accountSearch } = this.state;
    const resSearch = await axios.post(
      `${apiEndpoint}/account/search`,
      accountSearch
    );
    if (resSearch.status === 200) {
      const { data: accountList } = resSearch;

      const accountSearch = {
        userIdSearch: '',
        nameSearch: '',
        emailSearch: '',
        timeSearch: ''
      };
      this.setState({ accountSearch, accountList });
    }
  };

  render() {
    const { accountList, accountSearch } = this.state;
    return (
      <div
        className='accountManager-container'
        ref={middleContainer => {
          this.middleContainer = middleContainer;
        }}
        style={{ position: 'sticky', top: this.state.sticky }}
      >
        <div className='accountSnippetTag'>帳號管理</div>
        <div className='accountManager-search-panel'>
          <div className='accountManager-search-cell'>
            id 搜尋 &nbsp;
            <input
              type='text'
              name='userIdSearch'
              className='accountManager-search-input id'
              onChange={e => this.accountSearchChange(e)}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  this.accountSearchSubmit();
                }
              }}
              value={accountSearch.userIdSearch}
            />
          </div>
          <div className='accountManager-search-cell'>
            暱稱搜尋 &nbsp;
            <input
              type='text'
              name='nameSearch'
              className='accountManager-search-input'
              onChange={e => this.accountSearchChange(e)}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  this.accountSearchSubmit();
                }
              }}
              value={accountSearch.nameSearch}
            />
          </div>
          <div className='accountManager-search-cell'>
            電郵搜尋 &nbsp;
            <input
              type='text'
              name='emailSearch'
              className='accountManager-search-input'
              onChange={e => this.accountSearchChange(e)}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  this.accountSearchSubmit();
                }
              }}
              value={accountSearch.emailSearch}
            />
          </div>
          <div className='accountManager-search-cell'>
            時間搜尋 &nbsp;
            <input
              type='text'
              name='timeSearch'
              className='accountManager-search-input date'
              onChange={e => this.accountSearchChange(e)}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  this.accountSearchSubmit();
                }
              }}
              value={accountSearch.timeSearch}
            />
          </div>
        </div>
        <div className='accountManager-search-bar'>
          <div
            className='accountManager-search-btn'
            onClick={() => this.accountSearchSubmit()}
          >
            搜尋
          </div>
        </div>

        <div className='accountManager-account-container-first-row title'>
          <div></div>
          <div>id</div>
          <div>暱稱</div>
          <div>電郵</div>
          <div>戦闘力</div>
          <div></div>
        </div>
        {accountList.length === 0 ? (
          <div className='accountManager-account-slogan'>
            找不到符合條件的搜尋結果
          </div>
        ) : null}
        {accountList.map((account, index) => (
          <div key={index} className='accountManager-account-container'>
            <div className='accountManager-account-container-first-row'>
              <div className='accountManager-account-avatar-container'>
                <img
                  className='accountManager-account-avatar'
                  src={`/img/accounts/avatars/custom/${account.userId}.png`}
                  alt=''
                />
              </div>
              <div className='accountManager-account-cell'>
                {account.userId}
              </div>
              <div className='accountManager-account-cell'>
                {account.moderator ? (
                  <span style={{ color: '#20b2aa' }}>
                    {account.name} (官方編輯)
                  </span>
                ) : (
                  account.name
                )}
              </div>
              <div className='accountManager-account-cell'>{account.email}</div>
              <div className='accountManager-account-cell'>
                {account.powerLevel}
              </div>
              <div>
                {!account.admin ? (
                  <div className='accountManager-account-cell-last'>
                    <label>
                      <input
                        type='radio'
                        checked={!account.authorized ? 'checked' : ''}
                        onChange={() =>
                          this.authorizationChange(
                            account.userId,
                            'unauthorize'
                          )
                        }
                      />
                      停權
                    </label>

                    <label>
                      <input
                        type='radio'
                        checked={account.authorized ? 'checked' : ''}
                        onChange={() =>
                          this.authorizationChange(account.userId, 'authorize')
                        }
                      />
                      正常
                    </label>

                    <label
                      className='accountManager-account-extend'
                      onClick={() => this.extendAccountDetail(account.userId)}
                    >
                      {!account.extend ? (
                        <span> &#9658; </span>
                      ) : (
                        <span> &#9660;</span>
                      )}
                      詳細
                    </label>
                  </div>
                ) : (
                  <div className='accountManager-account-cell-admin'>
                    <div>管理員</div>
                    <label
                      className='accountManager-account-extend'
                      onClick={() => this.extendAccountDetail(account.userId)}
                    >
                      {!account.extend ? (
                        <span> &#9658; </span>
                      ) : (
                        <span> &#9660;</span>
                      )}
                      詳細
                    </label>
                  </div>
                )}
              </div>
            </div>

            {account.extend ? (
              <div>
                <div className='accountManager-account-details-container active'>
                  <div className='accountManager-account-details-cell'>
                    更新: {account.updatedAtFormattedS}
                  </div>
                  <div className='accountManager-account-details-cell'>
                    建立: {account.createdAtFormattedS}
                  </div>
                </div>
                {account.intro ? (
                  <div className='accountManager-account-details-container intro active'>
                    <div>自我介紹: {account.intro}</div>
                  </div>
                ) : null}

                <div className='accountManager-account-details-container active'>
                  {account.gender ? (
                    <div className='accountManager-account-details-cell'>
                      Gender: {account.gender}
                    </div>
                  ) : null}

                  {account.birthYear ? (
                    <div className='accountManager-account-details-cell'>
                      出生年份: {account.birthYear}
                    </div>
                  ) : null}

                  {account.eosAccount ? (
                    <div className='accountManager-account-details-cell'>
                      EOS 帳號: {account.eosAccount}
                    </div>
                  ) : null}

                  {account.telosAccount ? (
                    <div className='accountManager-account-details-cell'>
                      Telos 帳號: {account.telosAccount}
                    </div>
                  ) : null}

                  {account.bosAccount ? (
                    <div className='accountManager-account-details-cell'>
                      BOS 帳號: {account.bosAccount}
                    </div>
                  ) : null}

                  {account.worbliAccount ? (
                    <div className='accountManager-account-details-cell'>
                      Worbli 帳號: {account.worbliAccount}
                    </div>
                  ) : null}

                  {account.waxAccount ? (
                    <div className='accountManager-account-details-cell'>
                      WAX 帳號: {account.waxAccount}
                    </div>
                  ) : null}

                  {account.meetOneAccount ? (
                    <div className='accountManager-account-details-cell'>
                      MeetOne 帳號: {account.meetOneAccount}
                    </div>
                  ) : null}

                  {account.lynxAccount ? (
                    <div className='accountManager-account-details-cell'>
                      Lynx 帳號: {account.lynxAccount}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    );
  }
}

export default AccountManager;
