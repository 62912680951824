import React, { Component } from 'react';
import NavIcon from './navIcon';

class NavIconsContainer extends Component {
  render() {
    const { subclass, onNavIconSelected } = this.props;

    return (
      <React.Fragment>
        <div className='nav1_nav-zone'>
          <div className='left-title'>{subclass.subclass}</div>
          <div className='nav1_nav-zone-container'>
            {subclass.buttons.map((button, index) => (
              <NavIcon
                key={index}
                button={button}
                onNavIconSelected={onNavIconSelected}
              />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NavIconsContainer;
