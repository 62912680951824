import React from 'react';
import { Link } from 'react-router-dom';

const Board = ({ label, content }) => {
  return (
    <div>
      <div className='left-title'>{label}</div>
      <div className='nav1_nav-zone leftNoticeContainer'>
        <table className='news'>
          <tbody>
            {content.map((item, index) => (
              <tr key={index}>
                <td className='date'>{item.date}</td>
                {item.link ? (
                  <td className='title'>
                    <Link className='link' to={`/${item.link}`}>
                      {' '}
                      {item.message}{' '}
                    </Link>
                  </td>
                ) : (
                  <td className='title'>{item.message}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Board;
