import React, { Component } from 'react';
import BoardLogin from '../components/boardLogin';
import BoardAccount from '../components/boardAccount';
import { withRouter } from 'react-router-dom';

class Account extends Component {
  render() {
    const {
      panel,
      rightPanelActive,
      account,
      currentLogMode,
      login,
      register,
      eoscityStatistic,
      onLogin,
      onLoggedOut,
      onInputChange,
      onLoginPanelChange,
      onRegistrationSubmit,
      onKeepMeLoggedIn,
      keepMeLoggedIn,
      myArticles,
      onArticleSelected,
      onArticleDelete,
      onTopIconSelect,
      errors
    } = this.props;

    return (
      <div
        className={
          rightPanelActive === '帳號'
            ? 'rightContainer content active'
            : 'rightContainer content'
        }
      >
        {currentLogMode === 'login' || currentLogMode === 'register' ? (
          <BoardLogin
            imgSrc='/img/boards/eosCity2.svg'
            login={login}
            register={register}
            currentLogMode={currentLogMode}
            eoscityStatistic={eoscityStatistic}
            onLoginPanelChange={onLoginPanelChange}
            onRegistrationSubmit={onRegistrationSubmit}
            onInputChange={onInputChange}
            onLogin={onLogin}
            onKeepMeLoggedIn={onKeepMeLoggedIn}
            keepMeLoggedIn={keepMeLoggedIn}
            onTopIconSelect={onTopIconSelect}
            errors={errors}
          />
        ) : currentLogMode === 'loggedIn' ? (
          <BoardAccount
            panel={panel}
            onArticleSelected={onArticleSelected}
            account={account}
            onLoggedOut={onLoggedOut}
            onLoginPanelChange={onLoginPanelChange}
            myArticles={myArticles}
            onArticleDelete={onArticleDelete}
            onResetArticleEditorBlock={this.props.onResetArticleEditorBlock}
            onPanelExpand={this.props.onPanelExpand}
            onConfirmationResend={this.props.onConfirmationResend}
            onFetchArticleForEdit={this.props.onFetchArticleForEdit}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(Account);
