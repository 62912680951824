import React, { Component } from 'react';
import autosize from 'autosize';

class ChatInput extends Component {
  componentDidMount() {
    autosize(this.textarea);
  }

  scrollToBottom = () => {
    // console.log('bottom');
  };

  render() {
    const { message, account, onSendMessage, onMessageChange } = this.props;

    return (
      <div className='chatroom-input-container'>
        <div className='chatroom-input-avatarContainer'>
          <img
            src={
              account.name
                ? `/img/accounts/avatars/custom/${account.userId}.png`
                : '/img/accounts/avatars/default/eosCity.svg'
            }
            alt='default'
          />
        </div>
        <div className='chatroom-input'>
          <textarea
            ref={c => (this.textarea = c)}
            className='input'
            onKeyDown={e => {
              if (e.keyCode === 13 && e.shiftKey === false) {
                e.preventDefault();
                onSendMessage();
                this.scrollToBottom();
              }
            }}
            onChange={e => onMessageChange(e)}
            value={message}
          />
          <div className='sendContainer'>
            <span className='hints'>
              {account.name ? '' : '*登入後即可發言'}
            </span>
            <span
              className='send'
              onClick={() => {
                onSendMessage();
                this.scrollToBottom();
              }}
            >
              發送
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatInput;
