import React from 'react';
import QuestionMark from '../../../../common/icons/questionMark';

const Youtube = ({ index, onSnippetChange, content, onSnippetDelete }) => {
  const info =
    "通常在直接在 URL 欄位複製並貼上即可，或點擊 '分享' 出現 URL 後直接按 '複製' 即可，貼上的連結開頭必須是 'https://www.youtube.com/watch?v=' 或 'https://youtu.be/'";

  return (
    <div className='articleEdit-editor-inputContainer'>
      <div className='articleEdit-editor-inputTitleContainer'>
        <div className='title'>Youtube</div>
        <div className='articleEdit-editor-input-rightContainer'>
          <QuestionMark title={info} />
          <i
            className='fa fa-trash icon'
            onClick={() => onSnippetDelete(index)}
          ></i>
        </div>
      </div>
      <div className='articleEdit-editor-input-linkContainer'>
        <div className='articleEdit-editor-input-link-inputContainer'>
          <label htmlFor={'articleEdit-editor-input-linkLabel' + index}>
            URL 或 分享連結:
          </label>
          <br />
          <input
            id={'articleEdit-editor-input-linkLabel' + index}
            onChange={e => onSnippetChange(e, index, 'text')}
            value={content.text}
          />
        </div>
      </div>
    </div>
  );
};

export default Youtube;
