import React, { Component } from "react";
import autosize from "autosize";

class Inputbox extends Component {
  componentDidMount() {
    autosize(this.textarea);
  }

  render() {
    const { label, name, value, onChange, elementId, errors } = this.props;
    return (
      <div className="articleEdit-editor-mainInput">
        <div className="articleEdit-editor-inputContainer">
          <div className="title">{label}</div>
          <textarea
            ref={c => (this.textarea = c)}
            id={elementId}
            onChange={e => onChange(e)}
            name={name}
            value={value}
          ></textarea>
          <div className="warning"> {errors[name]} </div>
        </div>
      </div>
    );
  }
}

export default Inputbox;
