import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import autosize from 'autosize';
import moment from 'moment';
import '../css/community.css';
import QuickLogin from '../components/quickLogin';
import ChatInput from '../components/chatInput';
import Ads from '../components/ads';

class Community extends Component {
  componentWillUpdate(nextProps) {
    this.historyChanged =
      nextProps.chatCollection.length !== this.props.chatCollection.length;
    if (this.historyChanged) {
      const { messageList } = this.refs;
      const scrollPos = Math.floor(messageList.scrollTop);
      const scrollBottom = messageList.scrollHeight - messageList.clientHeight;

      if (messageList.childNodes.length) {
        const last2MessageHeight =
          parseInt(
            window.getComputedStyle(
              messageList.childNodes[messageList.childNodes.length - 1]
            ).height
          ) + 30;

        this.scrollAtBottom =
          scrollBottom <= 0 || scrollPos >= scrollBottom - last2MessageHeight;
      }
    }
  }

  componentDidMount() {
    autosize(this.textarea);
    const { messageList } = this.refs;

    this.timer = setInterval(() => {
      if (messageList.childNodes.length) {
        this.scrollToBottom();
        clearInterval(this.timer);
      }
    }, 500);
  }

  componentDidUpdate() {
    if (this.historyChanged) {
      if (this.scrollAtBottom) this.scrollToBottom();
    }
  }

  currentTime = moment().unix();

  onScroll = () => {
    // const { refs } = this;
    // const scrollTop = refs.messageList.scrollTop;
    // if (scrollTop === 0) console.log('you are on the top');
  };

  scrollToBottom = () => {
    const { messageList } = this.refs;
    const scrollHeight = messageList.scrollHeight;
    const height = messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    ReactDOM.findDOMNode(messageList).scrollTop =
      maxScrollTop > 0 ? maxScrollTop : 0;
  };

  render() {
    const {
      rightPanelActive,
      login,
      onLogin,
      onInputChange,
      errors,
      currentLogMode,
      account,
      onSendMessage,
      onMessageChange,
      message,
      chatCollection,
      onKeepMeLoggedIn,
      keepMeLoggedIn
    } = this.props;

    const { onScroll } = this;
    return (
      <div
        className={
          rightPanelActive === '社區'
            ? 'rightContainer content active'
            : 'rightContainer content'
        }
      >
        {currentLogMode === 'loggedIn' ? null : (
          <QuickLogin
            onKeepMeLoggedIn={onKeepMeLoggedIn}
            keepMeLoggedIn={keepMeLoggedIn}
            onInputChange={onInputChange}
            onLogin={onLogin}
            login={login}
            errors={errors}
          />
        )}
        <div className='nav2-subZone'>
          {account._id && !account.validated ? (
            <div>
              <div className='notValidate-message'>
                確認電郵已經寄出，如果十五分鐘收沒有收到確認電郵，請按以下
                '發出確認電郵'
                按鈕，再等十五分鐘查看註册電郵，任何沒有經過電郵確認的帳號均不可在聊天室發言、發表文章、回覆訊息及給
                "Love"。
              </div>
              <div className='notValidate-message warning'>
                請在註册後 24
                小時內進行電郵確認，沒有經過電郵確認的帳號將會在24小時後自動被删除
              </div>
              {this.currentTime > account.updatedAt + 900 ? (
                <div className='right-confirmation-btn-container'>
                  <span
                    className='right-confirmation-btn'
                    onClick={() => this.props.onConfirmationResend()}
                  >
                    發出確認電郵
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className='subTag clearfix'>
            <div className='subIcon'>
              <img src='/img/boards/chatroom.svg' alt='聊天室' />
            </div>
            <div className='title'>即時討論</div>
          </div>
          <div className='subContainer'>
            <div className='chatroom-container'>
              <div
                id='right-chatroom-scrollbar'
                className='chatroom-content-container'
                ref='messageList'
                onScroll={onScroll}
              >
                {chatCollection.length
                  ? chatCollection.map((chat, index) =>
                      !chat.id ? (
                        <div key={index} className='messageContainer me'>
                          <div className='contentContainer warning'>
                            <div className='name'>{chat.name} </div>
                            <div className='message'>{chat.message}</div>
                          </div>
                        </div>
                      ) : chat.id === account.userId ? (
                        <div key={index} className='messageContainer me'>
                          <div className='contentContainer me'>
                            <div className='name'>
                              {chat.name}{' '}
                              <span className='id'>id {chat.id}</span>{' '}
                              <span className='time'>
                                {' '}
                                &nbsp;&nbsp; {chat.time}
                              </span>
                            </div>
                            <div className='message'>{chat.message}</div>
                          </div>
                        </div>
                      ) : (
                        <div key={index} className='messageContainer'>
                          <div className='avatarContainer'>
                            <img
                              className='avatar'
                              src={`/img/accounts/avatars/custom/${chat.id}.png`}
                              alt={chat.name}
                            />
                          </div>
                          <div className='contentContainer'>
                            <div className='name'>
                              {chat.name}{' '}
                              <span className='id'>id {chat.id}</span>{' '}
                              <span className='time'>
                                {' '}
                                &nbsp;&nbsp; {chat.time}
                              </span>
                            </div>
                            <div className='message'>{chat.message}</div>
                          </div>
                        </div>
                      )
                    )
                  : null}
              </div>

              {currentLogMode === 'loggedIn' ? (
                <div>
                  <ChatInput
                    account={account}
                    message={message}
                    onSendMessage={onSendMessage}
                    onMessageChange={onMessageChange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <br />

        <Ads
          aHref='https://t.me/eoscity'
          imgSrc='/img/nav2_ads/rightAds3.png'
          imgAlt='立即加入柚子市電報群'
        />

        <br />
      </div>
    );
  }
}

export default Community;
