import React, { Component } from 'react';

class EmailConfirmed extends Component {
  render() {
    return (
      <div className='emailConfirmation-container'>
        <div className='emailConfirmation-header'>
          您已成功註冊成為柚子市會員
        </div>

        <br />
        <br />

        <div className='emailConfirmation-p-container'>
          您可以馬上登入您的柚子市帳號使用各種柚子市提供的服務。
          <br />
          <br />
          您可以打開 "帳號" 面板後點擊 "編輯帳號"
          按鈕，由此您可修改您的頭像、加入您的自我介紹、年齡、性別及打賞帳號
          (用於發表文章收集小費)。
          <br />
          <br />
          <span style={{ color: '#0f3676', fontSize: 18 }}>
            如果您已登入柚子市網站，請先登出網站後再登入，才可正常使用柚子市服務。
          </span>
        </div>
      </div>
    );
  }
}

export default EmailConfirmed;
