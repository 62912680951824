import React, { Component } from 'react';
import SubtitleEditBlock from './subtitleEditBlock';
import IntroEditBlock from './introEditBlock';
import ContentEditBlock from './contentEditBlock';
import LinkEditBlock from './linkEditBlock';
import RecommendSnippetEditBlock from './recommendSnippetEditBlock';

class BlockEditTemplate extends Component {
  render() {
    const {
      index,
      block,
      value,
      onBlockInputChange,
      onBlockTypeChange,
      onSubBlockTypeChange,
      onContactUrlChange
    } = this.props;

    const components = {
      subtitle: SubtitleEditBlock,
      intro: IntroEditBlock,
      content: ContentEditBlock,
      link: LinkEditBlock,
      recommendSnippet: RecommendSnippetEditBlock
    };

    const Tag = components[block.blockType];

    return (
      <Tag
        index={index}
        value={value}
        block={block}
        onBlockInputChange={onBlockInputChange}
        onBlockTypeChange={onBlockTypeChange}
        onSubBlockTypeChange={onSubBlockTypeChange}
        onContactUrlChange={onContactUrlChange}
      />
    );
  }
}

export default BlockEditTemplate;
