import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Community from './mode/community';
import News from './mode/news';
import Navigation from './mode/navigation';
import Market from './mode/market';
import Account from './mode/account';
import Footer from './components/footer';
import './css/rightZone.css';

class RightZone extends Component {
  state = {
    navTopIcons: [
      {
        label: '社區',
        imgSrc: '/img/topNavBtnsRight/community.svg'
      },
      {
        label: '行情',
        imgSrc: '/img/topNavBtnsRight/market.svg'
      },
      {
        label: '新聞',
        imgSrc: '/img/topNavBtnsRight/news.svg'
      },
      {
        label: '導航',
        imgSrc: '/img/topNavBtnsRight/navigatorColor.svg'
      },
      {
        label: '帳號',
        imgSrc: '/img/topNavBtnsRight/eosCity2.svg'
      }
    ],
    sticky: 0
  };

  componentDidMount() {
    window.addEventListener('scroll', this.throttle);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttle);
  }

  time = Date.now();

  throttle = () => {
    if (this.time + 250 - Date.now() < 0) {
      const height = this.rightContainer.clientHeight;
      const windowHeight = window.innerHeight;
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, 'rightHeight');
      this.time = Date.now();
    }
  };

  render() {
    const {
      panel,
      market,
      prevMarket,
      eosMarket,
      prevEosMarket,
      forkMarket,
      prevForkMarket,
      noticeList,
      infoList,
      meetupList,
      eoscityStatistic,
      currentLogMode,
      onLogin,
      onLoggedOut,
      onInputChange,
      onChangeLogMode,
      onLoginPanelChange,
      onRegistrationSubmit,
      account,
      login,
      register,
      navPanel,
      onMessageChange,
      onSendMessage,
      message,
      chatCollection,
      onKeepMeLoggedIn,
      keepMeLoggedIn,
      myArticles,
      onArticleSelected,
      onArticleDelete,
      onNavButtonSubclassChange,
      onNavIconSelected,
      onNavIconChange,
      onNavPanelInputChange,
      onButtonListSubmit,
      errors
    } = this.props;

    const { navTopIcons } = this.state;

    return (
      <div id='rightZone'>
        <div
          className='nav2'
          ref={rightContainer => {
            this.rightContainer = rightContainer;
          }}
          style={{ position: 'sticky', top: this.state.sticky }}
        >
          <div className='topContainer'>
            {navTopIcons.map((icon, index) => (
              <div
                key={index}
                className={
                  icon.label === panel.active ? 'topIcon active' : 'topIcon'
                }
                onClick={() => this.props.onPanelSelect(icon.label, 'right')}
              >
                <img
                  className='nav2_topIconContainer-img'
                  src={icon.imgSrc}
                  alt={icon.label}
                />
              </div>
            ))}
          </div>

          <div className='bottomContainer clearfix'>
            <Community
              rightPanelActive={panel.active}
              login={login}
              onLogin={onLogin}
              onInputChange={onInputChange}
              errors={errors}
              currentLogMode={currentLogMode}
              onMessageChange={onMessageChange}
              onSendMessage={onSendMessage}
              message={message}
              chatCollection={chatCollection}
              account={account}
              onKeepMeLoggedIn={onKeepMeLoggedIn}
              keepMeLoggedIn={keepMeLoggedIn}
              onConfirmationResend={this.props.onConfirmationResend}
            />
            <Market
              rightPanelActive={panel.active}
              market={market}
              prevMarket={prevMarket}
              eosMarket={eosMarket}
              prevEosMarket={prevEosMarket}
              forkMarket={forkMarket}
              prevForkMarket={prevForkMarket}
            />
            <News
              rightPanelActive={panel.active}
              infoList={infoList}
              noticeList={noticeList}
              meetupList={meetupList}
            />
            <Navigation
              rightPanelActive={panel.active}
              account={account}
              navPanel={navPanel}
              onNavButtonSubclassChange={onNavButtonSubclassChange}
              onNavIconSelected={onNavIconSelected}
              onNavIconChange={onNavIconChange}
              onNavPanelInputChange={onNavPanelInputChange}
              onButtonListSubmit={onButtonListSubmit}
            />
            <Account
              panel={panel}
              rightPanelActive={panel.active}
              account={account}
              login={login}
              register={register}
              currentLogMode={currentLogMode}
              keepMeLoggedIn={keepMeLoggedIn}
              myArticles={myArticles}
              eoscityStatistic={eoscityStatistic}
              onLogin={onLogin}
              onLoggedOut={onLoggedOut}
              onInputChange={onInputChange}
              onChangeLogMode={onChangeLogMode}
              onLoginPanelChange={onLoginPanelChange}
              onRegistrationSubmit={onRegistrationSubmit}
              onKeepMeLoggedIn={onKeepMeLoggedIn}
              onArticleSelected={onArticleSelected}
              onArticleDelete={onArticleDelete}
              onResetArticleEditorBlock={this.props.onResetArticleEditorBlock}
              onPanelExpand={this.props.onPanelExpand}
              onConfirmationResend={this.props.onConfirmationResend}
              onFetchArticleForEdit={this.props.onFetchArticleForEdit}
              errors={errors}
            />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(RightZone);
