import React, { Component } from 'react';
import autosize from 'autosize';
import QuestionMark from '../../../../common/icons/questionMark';

class Content extends Component {
  componentDidMount() {
    autosize(this.textarea);
  }

  render() {
    const { onSnippetChange, onSnippetDelete, value, index } = this.props;
    const info =
      "如果圖片、Youtube 視頻與內容之間距離太近，請直接在輸入欄按 'Enter' 留下空字行把不同內容分開";
    return (
      <div className='articleEdit-editor-inputContainer'>
        <div className='articleEdit-editor-inputTitleContainer'>
          <div className='title'>內容</div>
          <div className='articleEdit-editor-input-rightContainer'>
            <QuestionMark title={info} />
            <i
              className='fa fa-trash icon'
              onClick={() => onSnippetDelete(index)}
            ></i>
          </div>
        </div>
        <textarea
          style={{ height: '30px' }}
          ref={c => (this.textarea = c)}
          onChange={e => onSnippetChange(e, index, 'text')}
          value={value}
        ></textarea>
      </div>
    );
  }
}

export default Content;
