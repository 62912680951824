import React from 'react';

const Ads = ({ aHref, imgSrc, label }) => {
  return (
    <div className='nav2-subZone'>
      <a className='rightPromo' href={aHref} rel='noopener noreferrer'>
        <img src={imgSrc} alt={label} />
      </a>
    </div>
  );
};

export default Ads;
