import React, { Component } from 'react';
import autosize from 'autosize';
import QuestionMark from '../../../../common/icons/questionMark';

class Code extends Component {
  componentDidMount() {
    autosize(this.textarea);
  }

  render() {
    const {
      onSnippetChange,
      onSnippetDelete,
      content,
      index,
      onSelectedLang
    } = this.props;

    const info =
      '以下黑色方格輸入或貼上你的代碼，顏色强調不能用於C++、Javascript、HTML 或 CSS 以外的程式語言';
    return (
      <div className='articleEdit-editor-inputContainer'>
        <div className='articleEdit-editor-inputTitleContainer'>
          <div className='title'>代碼</div>
          <div className='articleEdit-editor-input-rightContainer code'>
            <select
              className='custom-select'
              name='language'
              onChange={e => onSelectedLang(e, index)}
            >
              <option value='clike'>C++</option>
              <option value='js'>Javascript</option>
              <option value='html'>HTML</option>
              <option value='css'>CSS</option>
            </select>
            <QuestionMark title={info} />
            <i
              className='fa fa-trash icon'
              onClick={() => onSnippetDelete(index)}
            ></i>
          </div>
        </div>

        <textarea
          ref={c => (this.textarea = c)}
          className='codeInput'
          placeholder='輸入你的代碼.....'
          onChange={e => onSnippetChange(e, index, 'text')}
          value={content.text}
        ></textarea>
      </div>
    );
  }
}

export default Code;
