import React from "react";

const AuthorSnippetTop = ({
  update,
  author,
  account,
  love,
  loves,
  publishTimeS,
  onLove,
  articleId
}) => {
  const { name, userId, powerLevel } = account;
  return (
    <div className="article-authorSnippet-top">
      <div className="avatarContainer">
        <img
          src={
            update
              ? `/img/accounts/avatars/custom/${author.userId}.png`
              : `/img/accounts/avatars/custom/${userId}.png`
          }
          alt={userId}
        />
      </div>
      <div className="authorInfoContainer">
        <div className="firstRow">
          {update ? (
            <div className="large">
              {author.name} <span className="small id">id {author.userId}</span>
            </div>
          ) : (
            <div className="large">
              {name} <span className="small id">id {userId}</span>
            </div>
          )}
          <div className="small">{publishTimeS}</div>
        </div>
        <div className="firstRow">
          <div className="small">
            戦闘力 {update ? author.powerLevel : powerLevel}
          </div>
          <div className="loveContainer">
            <i
              className={love ? "loveIcon" : "loveIcon mono"}
              onClick={() => {
                onLove(articleId);
              }}
            />
            <div className="small">{loves}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthorSnippetTop;
