import React, { Component } from 'react';

class TitleSubBlock extends Component {
  render() {
    const { content } = this.props;
    return (
      <div className='middle-navigator-recommendSnippet-right-title'>
        {content}
      </div>
    );
  }
}

export default TitleSubBlock;
