import React, { Component } from "react";
import BlockTemplate from "./renderCollection/blockTemplate";
import BlockEditTemplate from "./editCollection/blockEditTemplate";
import "../../css/navigator.css";
import InsertBar from "./editCollection/insertBar";

class Navigator extends Component {
  state = { sticky: -600 };

  componentDidMount() {
    window.addEventListener("scroll", this.throttle);
    if (!this.props.navContent.iconRef && !this.props.match.params.id) {
      this.props.history.push("/");
    } else if (this.props.match.params.id) {
      this.props.onFetchNavContent(this.props.match.params.id);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttle);
  }

  time = Date.now();

  throttle = () => {
    if (this.time + 250 - Date.now() < 0) {
      const height = this.middleContainer.clientHeight;
      const windowHeight = window.innerHeight;
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, "middleHeight");
      this.time = Date.now();
    }
  };

  render() {
    const {
      account,
      onBlockInputChange,
      onBlockTypeChange,
      onSubBlockTypeChange,
      onContactUrlChange,
      onNavContentSubmit
    } = this.props;

    const { title, iconRef, blocks } = this.props.navContent;

    return (
      <div
        style={{ position: "sticky", top: this.state.sticky }}
        className="middle-navigator-wrapper"
        ref={middleContainer => {
          this.middleContainer = middleContainer;
        }}
      >
        {!account.admin ? (
          <div>
            <div className="middle-navigator-header-container">
              <div className="middle-navigator-img-container">
                <img src={`/img/buttons/navPanel/${iconRef}.svg`} alt={title} />
              </div>
              <div className="middle-navigator-title-container">{title}</div>
            </div>
            {blocks.map((block, index) => (
              <BlockTemplate key={index} block={block} />
            ))}
          </div>
        ) : (
          <div>
            <div className="middle-navigator-header-container">
              <div className="middle-navigator-img-container">
                <img
                  src={
                    iconRef
                      ? `/img/buttons/navPanel/${iconRef}.svg`
                      : "/img/buttons/navPanel/navPanel-upload.svg"
                  }
                  alt="preview"
                />
              </div>
              <div className="middle-navigator-title-edit-container">
                <input
                  type="text"
                  className="middle-navigator-title-input"
                  onChange={e => onBlockInputChange(e, "title", null, null)}
                  value={title}
                />
              </div>
            </div>
            <br />

            {blocks.map((block, index) => (
              <BlockEditTemplate
                key={index}
                index={index}
                block={block}
                onBlockInputChange={onBlockInputChange}
                onBlockTypeChange={onBlockTypeChange}
                onSubBlockTypeChange={onSubBlockTypeChange}
                onContactUrlChange={onContactUrlChange}
              />
            ))}

            <InsertBar
              blockIndex={null}
              method={"push"}
              onBlockTypeChange={onBlockTypeChange}
            />

            <div
              style={{
                textAlign: "center"
              }}
            >
              <div
                className="articleSubmitBtn"
                onClick={() => onNavContentSubmit(this.props.match.params.id)}
              >
                {!this.props.match.params.id ? "儲存導航內容" : "更新導航內容"}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Navigator;
