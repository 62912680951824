import React, { Component } from "react";
import Community from "./mode/community";
import Navigation from "./mode/navigation";
import Market from "./mode/market";
import News from "./mode/news";
import Account from "./mode/account";
import Footer from "./components/footer";
import "./css/leftZone.css";

class LeftZone extends Component {
  state = {
    sticky: -200,
    navTabs: [
      { label: "社區", position: 1, navPage: 1 },
      { label: "行情", position: 2, navPage: 2 },
      { label: "消息", position: 3, navPage: 3 },
      { label: "導航", position: 4, navPage: 4 },
      { label: "帳號", position: 5, navPage: 5 }
    ]
  };

  componentDidMount() {
    window.addEventListener("scroll", this.throttle);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttle);
  }

  time = Date.now();

  throttle = () => {
    if (this.time + 250 - Date.now() < 0) {
      const height = this.leftContainer.clientHeight;
      const windowHeight = window.innerHeight;
      const sticky = windowHeight - height - 50;
      this.setState({ sticky });
      this.props.onCalcComponentHeight(height, "leftHeight");
      this.time = Date.now();
    }
  };

  render() {
    const {
      panel,
      account,
      register,
      login,
      message,
      myArticles,
      chatCollection,
      currentLogMode,
      market,
      prevMarket,
      eosMarket,
      prevEosMarket,
      forkMarket,
      prevForkMarket,
      fiatMarket,
      prevFiatMarket,
      navPanel,
      infoList,
      noticeList,
      meetupList,
      eoscityStatistic,
      keepMeLoggedIn,
      onKeepMeLoggedIn,
      onNavIconSelected,
      onArticleSelected,
      onArticleDelete,
      onSendMessage,
      onMessageChange,
      onLogin,
      onInputChange,
      onLoggedOut,
      onRegistrationSubmit,
      onLoginPanelChange,
      errors
    } = this.props;

    const { sticky, navTabs } = this.state;
    return (
      <div id="leftZone">
        <div
          className="nav1"
          style={{
            position: "sticky",
            top: sticky
          }}
        >
          <div
            className="rightContainer"
            ref={leftContainer => {
              this.leftContainer = leftContainer;
            }}
          >
            <div className="upperpart">
              <Market
                leftPanelActive={panel.active}
                market={market}
                prevMarket={prevMarket}
                eosMarket={eosMarket}
                prevEosMarket={prevEosMarket}
                forkMarket={forkMarket}
                prevForkMarket={prevForkMarket}
                fiatMarket={fiatMarket}
                prevFiatMarket={prevFiatMarket}
              />

              <Navigation
                leftPanelActive={panel.active}
                navPanel={navPanel}
                onNavIconSelected={onNavIconSelected}
              />

              <Community
                leftPanelActive={panel.active}
                login={login}
                account={account}
                message={message}
                chatCollection={chatCollection}
                currentLogMode={currentLogMode}
                keepMeLoggedIn={keepMeLoggedIn}
                onSendMessage={onSendMessage}
                onMessageChange={onMessageChange}
                onLogin={onLogin}
                onInputChange={onInputChange}
                onKeepMeLoggedIn={onKeepMeLoggedIn}
                onConfirmationResend={this.props.onConfirmationResend}
                errors={errors}
              />

              <News
                leftPanelActive={panel.active}
                noticeList={noticeList}
                infoList={infoList}
                meetupList={meetupList}
              />

              <Account
                panel={panel}
                login={login}
                account={account}
                register={register}
                myArticles={myArticles}
                keepMeLoggedIn={keepMeLoggedIn}
                currentLogMode={currentLogMode}
                eoscityStatistic={eoscityStatistic}
                onArticleSelected={onArticleSelected}
                onArticleDelete={onArticleDelete}
                onLogin={onLogin}
                onLoggedOut={onLoggedOut}
                onRegistrationSubmit={onRegistrationSubmit}
                onInputChange={onInputChange}
                onLoginPanelChange={onLoginPanelChange}
                onKeepMeLoggedIn={onKeepMeLoggedIn}
                onResetArticleEditorBlock={this.props.onResetArticleEditorBlock}
                onPanelExpand={this.props.onPanelExpand}
                onConfirmationResend={this.props.onConfirmationResend}
                errors={errors}
              />
            </div>

            <Footer />
          </div>

          <div className="leftContainer">
            {navTabs.map((tab, index) => (
              <div
                key={index}
                className={
                  panel.active === tab.label
                    ? "leftNav button active"
                    : "leftNav button"
                }
                onClick={() => {
                  this.props.onPanelSelect(tab.label, "left");
                }}
              >
                <span>{tab.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default LeftZone;
