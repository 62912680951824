import React from 'react';
import { Link } from 'react-router-dom';

const NavIcon = ({ button, onNavIconSelected }) => {
  return (
    <Link
      to={`/nav/${button.contentRef}`}
      onClick={() => onNavIconSelected(button, null)}
    >
      <div className='nav2-iconContainer'>
        <img
          className='nav2-iconContainer-img'
          src={`/img/buttons/navPanel/${button._id}.svg`}
          alt={button.title}
        />
        <div className='nav2-iconContainer-text'>{button.title}</div>
      </div>
    </Link>
  );
};

export default NavIcon;
