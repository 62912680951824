import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BannersAndThumbnails extends Component {
  render() {
    let {
      account,
      coveredBanners,
      currentBanner,
      onThumbHover,
      onCoveredBannerInputChange,
      onCoveredBannerSubmit,
      version
    } = this.props;

    return (
      <div className='middleZone-upper'>
        <div className='middleZone-upper-bannerContainer'>
          {coveredBanners.map((banner, bannerIndex) =>
            banner.linkType === 'internal' ? (
              <Link
                key={bannerIndex}
                className={
                  bannerIndex === currentBanner
                    ? 'middleZone-upper-banner active'
                    : 'middleZone-upper-banner'
                }
                to={banner.link}
              >
                <img src={`/img/covers/${banner._id}.jpg`} alt={bannerIndex} />
              </Link>
            ) : (
              <a
                key={bannerIndex}
                className={
                  bannerIndex === currentBanner
                    ? 'middleZone-upper-banner active'
                    : 'middleZone-upper-banner'
                }
                href={banner.link}
              >
                <img src={`/img/covers/${banner._id}.jpg`} alt={bannerIndex} />
              </a>
            )
          )}
        </div>

        <div className='middleZone-upper-thumbsContainer'>
          {coveredBanners.map((thumb, thumbIndex) => (
            <div key={thumbIndex}>
              {!account.admin && thumb.linkType === 'internal' ? (
                <Link
                  className='middleZone-upper-thumbContainer'
                  to={thumb.link}
                  onMouseEnter={() => onThumbHover(thumbIndex)}
                >
                  <div className='middleZone-upper-thumbnail-imgContainer'>
                    <img
                      className='middleZone-upper-thumbnail-img'
                      src={`/img/covers/${thumb._id}.jpg`}
                      alt={thumbIndex}
                    />
                  </div>
                  <div
                    className={
                      thumbIndex === currentBanner
                        ? 'middleZone-upper-thumbnail-textContainer active'
                        : 'middleZone-upper-thumbnail-textContainer'
                    }
                  >
                    {thumb.content}
                  </div>
                  <div
                    className={
                      thumbIndex === currentBanner
                        ? 'middleZone-upper-thumbnail-highlight active'
                        : 'middleZone-upper-thumbnail-highlight'
                    }
                  ></div>
                </Link>
              ) : !account.admin && thumb.linkType === 'external' ? (
                <a
                  className='middleZone-upper-thumbContainer'
                  href={thumb.link}
                  onMouseEnter={() => onThumbHover(thumbIndex)}
                >
                  <div className='middleZone-upper-thumbnail-imgContainer'>
                    <img
                      className='middleZone-upper-thumbnail-img'
                      src={`/img/covers/${thumb._id}.jpg`}
                      alt={thumbIndex}
                    />
                  </div>
                  <div
                    className={
                      thumbIndex === currentBanner
                        ? 'middleZone-upper-thumbnail-textContainer active'
                        : 'middleZone-upper-thumbnail-textContainer'
                    }
                  >
                    {thumb.content}
                  </div>
                  <div
                    className={
                      thumbIndex === currentBanner
                        ? 'middleZone-upper-thumbnail-highlight active'
                        : 'middleZone-upper-thumbnail-highlight'
                    }
                  ></div>
                </a>
              ) : account.admin ? (
                <div className='middleZone-upper-edit-thumbContainer'>
                  <div className='middleZone-upper-thumbnail-imgContainer'>
                    <img
                      className='middleZone-upper-thumbnail-img'
                      src={
                        thumb.file ? thumb.temp : `/img/covers/${thumb._id}.jpg`
                      }
                      alt={thumbIndex}
                    />
                  </div>
                  <div>
                    <textarea
                      type='text'
                      className='middleZone-upper-thumbnail-edit-textarea'
                      onChange={e =>
                        onCoveredBannerInputChange(e, thumb, 'text', thumbIndex)
                      }
                      value={thumb.content}
                    />
                  </div>
                  <div className='middleZone-upper-thumbnail-edit-url-input-container'>
                    <label className='middleZone-upper-thumbnail-url-input-label'>
                      Link
                    </label>
                    <input
                      type='text'
                      className='middleZone-upper-thumbnail-url-input'
                      onChange={e =>
                        onCoveredBannerInputChange(e, thumb, 'link', thumbIndex)
                      }
                      value={thumb.link}
                    />
                  </div>
                  <div className='middleZone-upper-thumbnail-edit-btns-container'>
                    <label
                      className='middleZone-upper-thumbnail-edit-btn-container'
                      htmlFor={`cover-banner-${thumbIndex}`}
                    >
                      <i className='fa fa-upload' /> 上載
                    </label>
                    <input
                      type='file'
                      name='coverBanner'
                      className='upload-icon'
                      id={`cover-banner-${thumbIndex}`}
                      onChange={e =>
                        onCoveredBannerInputChange(e, thumb, 'img', thumbIndex)
                      }
                    />
                    <div
                      className='middleZone-upper-thumbnail-edit-btn-container'
                      onClick={() =>
                        onCoveredBannerSubmit(thumb, version, thumbIndex)
                      }
                    >
                      <i className='fa fa-save' /> 儲存
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default BannersAndThumbnails;
