import React, { Component } from 'react';

class ContentSubBlock extends Component {
  render() {
    const { content } = this.props;
    return (
      <div className='middle-navigator-recommendSnippet-right-content'>
        {content}
      </div>
    );
  }
}

export default ContentSubBlock;
