import React from 'react';

const Ads = ({ aHref, imgSrc, label }) => {
  return (
    <div className='nav1_nav-zone'>
      <a href={aHref} rel='noopener noreferrer'>
        <img className='nav1_nav-zone-ads' src={imgSrc} alt={label} />
      </a>
    </div>
  );
};

export default Ads;
