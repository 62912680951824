import React, { Component } from 'react';

class InsertSubBlockBar extends Component {
  state = {
    buttons: [
      { subBlockType: 'title', label: '標題' },
      { subBlockType: 'intro', label: '簡介' },
      { subBlockType: 'content', label: '內容' },
      { subBlockType: 'link', label: '連結' }
    ]
  };

  render() {
    const { buttons } = this.state;
    const {
      blockIndex,
      subBlockIndex,
      method,
      onSubBlockTypeChange,
      onInsertBarSwitch
    } = this.props;

    return (
      <div className='middle-navigator-edit-snippet-content-insertBar-container'>
        <div className='middle-navigator-edit-snippet-content-insertBar-label'>
          <i className='fa fa-arrow-up arrow' /> &nbsp; 選擇插入內容類型
        </div>
        <div className='middle-navigator-edit-snippet-content-insertBar'>
          {buttons.map((btn, index) => (
            <div
              key={index}
              className='btn'
              onClick={() =>
                onSubBlockTypeChange(
                  blockIndex,
                  subBlockIndex,
                  method,
                  btn.subBlockType,
                  onInsertBarSwitch
                )
              }
            >
              {btn.label}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default InsertSubBlockBar;
