import React, { Component } from "react";
import { Link } from "react-router-dom";
import Snippet from "./snippet";

class SnippetList extends Component {
  render() {
    const { snippetList } = this.props;
    return (
      <div id="articleSnippetZone">
        {snippetList.map((snippet, index) => (
          <Link key={index} to={`/article/${snippet._id}`}>
            <Snippet snippet={snippet} index={index} />
          </Link>
        ))}
      </div>
    );
  }
}

export default SnippetList;
