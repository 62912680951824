import React from 'react';
import { Link } from 'react-router-dom';

import '../css/eyebrow.css';

const Eyebrow = ({ fiatMarket, prevFiatMarket }) => {
  return (
    <div id='eyeBrow' className='eyeBrow'>
      {fiatMarket ? (
        <div className='eyeBrow-content'>
          <div className='eyeBrow-leftZone'>
            <Link
              id='eyebrowLogo'
              className='eyeBrow-logo'
              to='/'
              onClick={() => window.scrollTo(0, 0)}
            >
              <img
                className='eyeBrow-logo-img'
                src='/img/eyebrow/eosCity_logo_alt3.svg'
                alt='eosCity_logo'
              />
            </Link>

            <Link
              id='eyebrowLogo'
              className='eyeBrow-logo community'
              to='/community'
              onClick={() => window.scrollTo(0, 0)}
            >
              <img
                className='eyeBrow-logo-img'
                src='/img/header/eosCity_community_logo.svg'
                alt='eosCity_logo'
              />
            </Link>
          </div>

          <div className='eyeBrow-rightZone'>
            <div className='eyeBrow-rightZone-price'>
              <a
                className={
                  fiatMarket[0].last > prevFiatMarket[0].last
                    ? 'text up'
                    : fiatMarket[0].last < prevFiatMarket[0].last
                    ? 'text down'
                    : 'text'
                }
                href={fiatMarket[0].url}
              >
                <div>
                  <img
                    className='eyeBrow-right-logo'
                    src='/img/eyebrow/eosio.svg'
                    alt='eosio'
                  />
                </div>
                <div className='price'>
                  $ {fiatMarket[0].last}{' '}
                  {fiatMarket[0].last > prevFiatMarket[0].last ? (
                    <span>&#9650;</span>
                  ) : fiatMarket[0].last < prevFiatMarket[0].last ? (
                    <span>&#9660;</span>
                  ) : null}
                </div>
              </a>
            </div>

            <Link
              className={
                window.location.pathname === '/'
                  ? 'eyeBrow-rightZone-shortcut-label active'
                  : 'eyeBrow-rightZone-shortcut-label'
              }
              to='/'
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <div>
                <img
                  className='eyeBrow-right-logo'
                  src='/img/eyebrow/home.svg'
                  alt='back2Top'
                />
              </div>
              <div>官方</div>
            </Link>

            <Link
              className={
                window.location.pathname === '/community'
                  ? 'eyeBrow-rightZone-shortcut-label active'
                  : 'eyeBrow-rightZone-shortcut-label'
              }
              to='/community'
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <div>
                <img
                  className='eyeBrow-right-logo'
                  src='/img/eyebrow/community.svg'
                  alt='back2Top'
                />
              </div>
              <div>社區</div>
            </Link>
            <div
              className={'eyeBrow-rightZone-shortcut-label'}
              onClick={() => window.scrollTo(0, 0)}
            >
              <div>
                <img
                  className='eyeBrow-right-logo'
                  src='/img/eyebrow/back2Top.svg'
                  alt='back2Top'
                />
              </div>
              <div>頂層</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Eyebrow;
