import React from 'react';
import { Link } from 'react-router-dom';

const NavIcon = ({ button, onNavIconSelected }) => {
  return (
    <Link
      to={`/nav/${button.contentRef}`}
      onClick={() => onNavIconSelected(button, null)}
    >
      <div className='nav1_nav-zone-container-btnContainer'>
        <img
          className='nav1_nav-zone-container-btnContainer-img'
          src={`/img/buttons/navPanel/${button._id}.svg`}
          alt={button.title}
        />
        <div className='nav1_nav-zone-container-btnContainer-text'>
          {button.title}
        </div>
      </div>
    </Link>
  );
};

export default NavIcon;
