import React, { Component } from 'react';

class AccountRegister extends Component {
  render() {
    const {
      register,
      currentLogMode,
      onRegistrationSubmit,
      onInputChange,
      errors
    } = this.props;
    return (
      <div>
        <div className='left-title'>註册會員</div>
        <div className='left-community-login-container'>
          <div className='left-control-panel-slogan'>
            歡迎登記註册成為柚子市會員
          </div>
          <div className='left-community-login-tag'>暱稱*</div>
          <div>
            <input
              type='text'
              name='name'
              className='left-community-login-input'
              style={{ backgroundColor: errors.name ? '#ffe6e6' : '' }}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  onRegistrationSubmit();
                }
              }}
              onChange={e => onInputChange(e, currentLogMode)}
              value={register.name}
            />
            <div className='error'> {errors.name} </div>
          </div>

          <div className='left-community-login-tag'>電郵地址*</div>
          <div>
            <input
              type='text'
              name='email'
              className='left-community-login-input'
              style={{ backgroundColor: errors.email ? '#ffe6e6' : '' }}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                }
              }}
              onChange={e => onInputChange(e, currentLogMode)}
              value={register.email}
            />
            <div className='error'> {errors.email} </div>
          </div>

          <div className='left-community-login-tag'>登入密碼*</div>
          <div>
            <input
              type='password'
              name='password'
              className='left-community-login-input'
              style={{ backgroundColor: errors.password ? '#ffe6e6' : '' }}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  onRegistrationSubmit();
                }
              }}
              onChange={e => onInputChange(e, currentLogMode)}
              value={register.password}
            />
            <div className='error'> {errors.password} </div>
          </div>

          <div className='left-community-login-tag'>再次輸入密碼*</div>
          <div>
            <input
              type='password'
              name='confirmedPassword'
              className='left-community-login-input'
              style={{
                backgroundColor: errors.confirmedPassword ? '#ffe6e6' : ''
              }}
              onKeyDown={e => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault();
                  onRegistrationSubmit();
                }
              }}
              onChange={e => onInputChange(e, currentLogMode)}
              value={register.confirmedPassword}
            />
            <div className='error'> {errors.confirmedPassword} </div>
          </div>

          <div className='left-control-panel-slogan bottom'>
            *所有資料必需填寫
          </div>

          <div className='left-account-admin-container'>
            <div
              className='left-account-admin-btn'
              onClick={() => {
                onRegistrationSubmit();
              }}
            >
              遞交註册
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountRegister;
