import React, { Component } from "react";
import autosize from "autosize";

class ChatInput extends Component {
  componentDidMount() {
    autosize(this.textarea);
  }
  componentDidUpdate() {
    autosize(this.textarea);
  }

  scrollToBottom = () => {
    console.log("to bottom");
  };

  render() {
    const { account, message, onSendMessage, onMessageChange } = this.props;

    return (
      <div>
        <div className="left-chat-input-container">
          <div className="left-chat-input-avatar-container">
            <img
              className="left-chat-input-avatar"
              src={`/img/accounts/avatars/custom/${account.userId}.png`}
              alt=""
            />
          </div>
          <textarea
            ref={c => (this.textarea = c)}
            type="text"
            className="left-chat-input"
            onKeyDown={e => {
              if (e.keyCode === 13 && e.shiftKey === false) {
                e.preventDefault();
                onSendMessage();
                this.scrollToBottom();
                autosize(this.textarea);
              }
            }}
            onChange={e => {
              onMessageChange(e);
            }}
            value={message}
          ></textarea>
        </div>
        <div className="left-chat-send-btn-container">
          <div className="left-chat-send-btn" onClick={() => onSendMessage()}>
            發送
          </div>
        </div>
      </div>
    );
  }
}

export default ChatInput;
