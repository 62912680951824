import React, { Component } from 'react';

class Insert extends Component {
  state = {
    insertTypes: [
      { type: 'subtitle1', label: '副標題1' },
      { type: 'subtitle2', label: '副標題2' },
      { type: 'subtitle3', label: '副標題3' },
      { type: 'content', label: '內容' },
      { type: 'link', label: '連結' },
      { type: 'youtube', label: 'Youtube' },
      { type: 'picture', label: '圖片' },
      { type: 'code', label: '代碼' }
    ]
  };
  render() {
    const { onInsertTypeSelected, position } = this.props;
    const { insertTypes } = this.state;
    return (
      <div className='articleEdit-editor-insert'>
        <div className='articleEdit-editor-insert-btnContainer'>
          {insertTypes.map(insertType => (
            <div
              key={insertType.type}
              style={{ flex: '77px', paddingBottom: '6px' }}
            >
              <div
                className='articleEdit-editor-insert-btn'
                onClick={() => onInsertTypeSelected(insertType.type, position)}
              >
                {insertType.label}
              </div>
            </div>
          ))}
        </div>
        <div className='articleEdit-editor-insert-title'>
          選擇插入內容的類型: &nbsp; &nbsp;
          <i style={{ color: '#ffd837' }} className='fa fa-arrow-down'></i>
        </div>
      </div>
    );
  }
}

export default Insert;
