import React, { Component } from "react";
import AccountLogin from "./../components/accountLogin";
import AccountRegister from "./../components/accountRegister";
import AccountLoggedIn from "../components/accountLoggedIn";
import Ads from "../components/ads";

import "../css/dynamicPanel.css";

class Account extends Component {
  render() {
    const {
      panel,
      account,
      login,
      register,
      myArticles,
      currentLogMode,
      eoscityStatistic,
      onArticleSelected,
      onArticleDelete,
      onLoggedOut,
      onRegistrationSubmit,
      onInputChange,
      onLogin,
      onLoginPanelChange,
      keepMeLoggedIn,
      onKeepMeLoggedIn,
      errors
    } = this.props;

    return (
      <div className={panel.active === "帳號" ? "content active" : "content"}>
        {currentLogMode === "login" || currentLogMode === "register" ? (
          <div className="left-account-switch-btns-container">
            <div
              className={
                currentLogMode === "register"
                  ? "left-account-admin-btn switch active"
                  : "left-account-admin-btn switch"
              }
              onClick={() => {
                onLoginPanelChange("register");
              }}
            >
              立即註册
            </div>
            <div
              className={
                currentLogMode === "login"
                  ? "left-account-admin-btn switch active"
                  : "left-account-admin-btn switch"
              }
              onClick={() => {
                onLoginPanelChange("login");
              }}
            >
              帳號登入
            </div>
          </div>
        ) : null}
        {currentLogMode === "login" ? (
          <AccountLogin
            currentLogMode={currentLogMode}
            login={login}
            onLogin={onLogin}
            onInputChange={onInputChange}
            keepMeLoggedIn={keepMeLoggedIn}
            onKeepMeLoggedIn={onKeepMeLoggedIn}
            errors={errors}
          />
        ) : currentLogMode === "register" ? (
          <AccountRegister
            login={login}
            register={register}
            currentLogMode={currentLogMode}
            onRegistrationSubmit={onRegistrationSubmit}
            onInputChange={onInputChange}
            errors={errors}
          />
        ) : currentLogMode === "loggedIn" ? (
          <AccountLoggedIn
            panel={panel}
            account={account}
            myArticles={myArticles}
            onArticleSelected={onArticleSelected}
            onArticleDelete={onArticleDelete}
            onLoggedOut={onLoggedOut}
            onResetArticleEditorBlock={this.props.onResetArticleEditorBlock}
            onPanelExpand={this.props.onPanelExpand}
            onConfirmationResend={this.props.onConfirmationResend}
          />
        ) : null}

        <br />
        <br />

        {currentLogMode === "login" || currentLogMode === "register" ? (
          <div>
            <div className="left-title">本站統計</div>
            <div className="left-eoscity-statistic-table">
              <div className="left-eoscity-statistic-row">
                <div>會員總數</div>
                <div>{eoscityStatistic.accountNumber}</div>
              </div>

              <div className="left-eoscity-statistic-row">
                <div>文章數量</div>
                <div>{eoscityStatistic.articleNumber}</div>
              </div>

              <div className="left-eoscity-statistic-row">
                <div>EOS 帳號</div>
                <div>{eoscityStatistic.eosAccount}</div>
              </div>

              <div className="left-eoscity-statistic-row">
                <div>Telos 帳號</div>
                <div>{eoscityStatistic.telosAccount}</div>
              </div>

              <div className="left-eoscity-statistic-row">
                <div>BOS 帳號</div>
                <div>{eoscityStatistic.bosAccount}</div>
              </div>

              <div className="left-eoscity-statistic-row">
                <div>Worbli 帳號</div>
                <div>{eoscityStatistic.worbliAccount}</div>
              </div>

              <div className="left-eoscity-statistic-row">
                <div>WAX 帳號</div>
                <div>{eoscityStatistic.waxAccount}</div>
              </div>

              <div className="left-eoscity-statistic-row">
                <div>MeetOne 帳號</div>
                <div>{eoscityStatistic.meetOneAccount}</div>
              </div>

              <div className="left-eoscity-statistic-row">
                <div>Lynx 帳號</div>
                <div>{eoscityStatistic.lynxAccount}</div>
              </div>

              <div className="left-eoscity-statistic-row">
                <div>本站版本</div>
                <div>2.1</div>
              </div>

              <div className="left-eoscity-statistic-row">
                <div>建立日期</div>
                <div>2018年9月3日 (一)</div>
              </div>
            </div>
          </div>
        ) : null}
        <br />
        <Ads
          aHref="https://www.facebook.com/groups/eoscity/"
          imgSrc="/img/nav1_ads/leftAds8.png"
          label="柚子市臉書"
        />
        <br />
      </div>
    );
  }
}

export default Account;
