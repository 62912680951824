import React from 'react';

const Picture = ({ content }) => {
  return (
    <div className='articleRender-container pictureBlock'>
      {!content.src && content.path ? (
        <img src={`/img/articles/${content.path}`} alt='' />
      ) : content.src ? (
        <img src={content.src} alt={content.path} />
      ) : null}
    </div>
  );
};

export default Picture;
